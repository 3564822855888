import { Button, Image, Row, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBranches, deleteBranch } from "../../../api/Branch";
import ColTitle from "../../../components/Table/ColTitle.component";

const { Title } = Typography;

export default function BranchesPage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const columns = [
    {
      title: <ColTitle>ID</ColTitle>,
      dataIndex: "id",
      key: "id",
    },
    {
      title: <ColTitle>Name</ColTitle>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <ColTitle>Institute Type</ColTitle>,
      dataIndex: "institute_type",
      key: "institute_type",
    },
    {
      title: <ColTitle>Branch Code</ColTitle>,
      dataIndex: "branch_code",
      key: "branch_code",
    },
    {
      title: <ColTitle>Address</ColTitle>,
      dataIndex: "address",
      key: "address",
    },
    {
      title: <ColTitle>Email</ColTitle>,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <ColTitle>Mobile Number</ColTitle>,
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: <ColTitle>Telephone Number</ColTitle>,
      dataIndex: "telephone_number",
      key: "telephone_number",
    },
    {
      title: <ColTitle>GSTIN</ColTitle>,
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: <ColTitle>Pan Number</ColTitle>,
      dataIndex: "pan_number",
      key: "pan_number",
    },
    {
      title: <ColTitle>Country</ColTitle>,
      dataIndex: "country_id",
      key: "country_id",
    },
    {
      title: <ColTitle>State</ColTitle>,
      dataIndex: "state_id",
      key: "state_id",
    },
    {
      title: <ColTitle>City</ColTitle>,
      dataIndex: "city_id",
      key: "city_id",
    },
    {
      title: <ColTitle>Currency</ColTitle>,
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: <ColTitle>Area Geolocation</ColTitle>,
      dataIndex: "area_geolocation",
      key: "area_geolocation",
    },
    {
      title: <ColTitle>Latitude</ColTitle>,
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: <ColTitle>Longitude</ColTitle>,
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: <ColTitle>PE Id</ColTitle>,
      dataIndex: "pe_id",
      key: "pe_id",
    },
    {
      title: <ColTitle>Default Sender ID</ColTitle>,
      dataIndex: "default_senderid",
      key: "default_senderid",
    },
    {
      title: "Action",
      key: "Action",
      render: (_) => (
        <Button danger onClick={() => deleteBranch(_.id)}>
          Delete
        </Button>
      ),
    },
  ];

  const loadBranches = (params = {}) => {
    getBranches(params).then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    loadBranches();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 20 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Branches</Title>
        <Button onClick={() => navigate("/settings/branches/add")}>
          Add Branch
        </Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        scroll={{
          x: true,
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadBranches({
              page,
            });
          },
        }}
      />
    </LayoutContainer>
  );
}
