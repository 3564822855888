import { Button, Row, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useNavigate } from "react-router-dom";
import {
  getCounsellers,
  getCounsellorsByBranchId,
} from "../../../api/Counsellor";
import { useEffect, useState } from "react";
import useID from "../../../hooks/useID.hook";

const { Title } = Typography;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Branch",
    key: "branch",
    render: (_) => {
      return <span>{_?.other_info?.name ?? "N/A"}</span>;
    },
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => new Date(text).toLocaleDateString("en-GB"),
  },
];

export default function CounsellorsPage() {
  const id = useID();
  const navigate = useNavigate();
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [data, setData] = useState([]);

  const loadEmployee = (params = {}) => {
    setLoadingEmployee(true);
    getCounsellorsByBranchId(params, id.branch_id)
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingEmployee(false));
  };

  useEffect(() => {
    loadEmployee();
  }, [id.branch_id]);
  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between">
        <Title style={{ marginTop: 0 }}>Counsellors</Title>
        <Button onClick={() => navigate("/masters/counsellors/add")}>
          Add Counsellor
        </Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        loading={loadingEmployee}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadEmployee({
              page,
            });
          },
        }}
      />
    </LayoutContainer>
  );
}
