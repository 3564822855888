import axios from "axios";
import { BASE_URL } from "../../constants";

export function getCounsellers(params) {
  return axios.get(`${BASE_URL}/counselor`, {
    params,
  });
}

export function getCounsellorsByBranchId(params, id) {
  return axios.get(`${BASE_URL}/branch/${id}/counselor`, {
    params,
  });
}

export function createCounseller(values) {
  return axios.post(`${BASE_URL}/counselor`, values);
}

export function getCounsellerById(id) {
  return axios.get(`${BASE_URL}/counselor/${id}`);
}

export function updateCounseller(id, values) {
  return axios.put(`${BASE_URL}/counselor/${id}`, values);
}

export function deleteCounseller(id) {
  return axios.delete(`${BASE_URL}/counselor/${id}`);
}
