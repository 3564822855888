import {
  Button,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import {
  getParentInfo,
  createParentInfo,
  deleteParentInfo,
} from "../../../api/ParentInfo";
import ColTitle from "../../../components/Table/ColTitle.component";
import { useBranches } from "../../../hooks/useBranches.hook";
import { getCounsellorsByBranchId } from "../../../api/Counsellor";
import ColValue from "../../../components/Table/ColValue.component";

const { Title } = Typography;

const columns = [
  {
    title: <ColTitle>ID</ColTitle>,
    dataIndex: "id",
    key: "id",
  },
  {
    title: <ColTitle>Father Name</ColTitle>,
    key: "fathersName",
    render: (_) => <ColValue>{_?.fathersName}</ColValue>,
  },
  {
    key: "mothersName",
    title: <ColTitle>Mother Name</ColTitle>,
    render: (_) => <ColValue>{_?.mothersName}</ColValue>,
  },
  {
    key: "primary_phone",
    title: <ColTitle>Primary Phone</ColTitle>,
    render: (_) => <ColValue>{_?.primary_phone}</ColValue>,
  },
  {
    key: "secondary_phone",
    title: <ColTitle>Secondary Phone</ColTitle>,
    render: (_) => <ColValue>{_?.secondary_phone}</ColValue>,
  },
  {
    key: "father_uid",
    title: <ColTitle>Father UID</ColTitle>,
    render: (_) => <ColValue>{_?.father_uid}</ColValue>,
  },
  {
    title: <ColTitle>Mother UID</ColTitle>,
    key: "mother_uid",
    render: (_) => <ColValue>{_?.mother_uid}</ColValue>,
  },
  {
    key: "zipcode",
    title: <ColTitle>ZIP Code</ColTitle>,
    render: (_) => <ColValue>{_?.zipcode}</ColValue>,
  },
  {
    key: "address",
    title: <ColTitle>Address</ColTitle>,
    render: (_) => <span>{_?.address}</span>,
  },
  {
    key: "country",
    title: <ColTitle>Country</ColTitle>,
    render: (_) => <ColValue>{_?.country}</ColValue>,
  },
  {
    key: "state",
    title: <ColTitle>State</ColTitle>,
    render: (_) => <ColValue>{_?.state}</ColValue>,
  },
  {
    key: "occupation",
    title: <ColTitle>Occupation</ColTitle>,
    render: (_) => <ColValue>{_?.occupation}</ColValue>,
  },
  {
    key: "email",
    title: <ColTitle>Email</ColTitle>,
    render: (_) => <ColValue>{_?.email}</ColValue>,
  },
  {
    title: <ColTitle>Created At</ColTitle>,
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => new Date(text).toLocaleDateString("en-GB"),
  },
  {
    title: <ColTitle>Action</ColTitle>,
    key: "action",
    render: (_, record) => {
      return (
        <Space size="small">
          <Button danger onClick={() => deleteParentInfo(_.id)}>
            Delete
          </Button>
        </Space>
      );
    },
  },
];

export default function ParentInfoPage() {
  const [form] = Form.useForm();

  const { branches, selectedBranch, loadingBranches } = useBranches(
    (state) => state
  );
  const [loadingParentInfo, setLoadingParentInfo] = useState(false);
  const [data, setData] = useState([]);
  // const [formSelectedBranch, setFormSelectedBranch] = useState(selectedBranch.selectedBranch); // to keep the track of the branch

  // const loadCounsellers = (counsellor_id) => {
  //   getCounsellorsByBranchId({ pagination: false }, counsellor_id)
  //     .then((res) => setCounsellers(res.data.data))
  //     .catch((err) => console.log(err));
  // };

  const loadParentInfo = (params = {}) => {
    setLoadingParentInfo(true);
    getParentInfo(params, selectedBranch?.selectedBranch ?? "all", "all")
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoadingParentInfo(false));
  };

  useEffect(() => {
    loadParentInfo();
    form.setFieldValue(
      "branch_id",
      selectedBranch?.selectedBranch === "all"
        ? null
        : selectedBranch?.selectedBranch
    );

    // if (selectedBranch?.selectedBranch !== "all") {
    //   loadCounsellers(selectedBranch?.selectedBranch);
    // }

    form.setFieldValue("counselor_id", null);
  }, [selectedBranch]);

  return (
    <LayoutContainer>
      <Row justify="space-between" align="middle" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Parent Info</Title>

        {/* <Button onClick={() => setDrawerOpen(true)}>Add New</Button> */}
      </Row>

      <div style={{ width: "100%", backgroundColor: "white" }}>
        <Table
          columns={columns}
          dataSource={data.data}
          loading={loadingParentInfo}
          pagination={{
            position: ["bottomCenter"],
            pageSize: data.per_page,
            total: data.total,
            onChange: (page, pageSize) => {
              loadParentInfo({
                page,
              });
            },
          }}
          scroll={{
            x: true,
          }}
        />
      </div>
    </LayoutContainer>
  );
}
