import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import LayoutContainer from "../../components/layout/LayoutContainer";
import useAuth from "../../hooks/useAuth.hook";

export default function DashboardPage() {
  const { user } = useAuth();
  return (
    <LayoutContainer>
      <Card
        title="Basic Information"
        bordered={false}
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="Total Leads"
                value={11.28}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                //   prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="Total Leads"
                value={11.28}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                //   prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="Active"
                value={11.28}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                //   prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </LayoutContainer>
  );
}
