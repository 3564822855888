import dayjs from "dayjs";
import { isNil, omitBy, reduce, replace, split, upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { deleteReceipt, getReceipt } from "../../../api/Receipt";
import ColTitle from "../../../components/Table/ColTitle.component";
import useID from "../../../hooks/useID.hook";
import ColValue from "../../../components/Table/ColValue.component";
import useAuth from "../../../hooks/useAuth.hook";
import daysInMonth from "../../../utils/getCurrentMonthDays";

export default function ReceiptPage() {
  const id = useID();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [receipt, setReceipt] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }

    const days = daysInMonth();

    const tooLate = dates[0] && current.diff(dates[0], "days") >= days;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= days;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const loadReceipt = (params = {}) => {
    setLoadingReceipt(true);
    getReceipt(params, id.branch_id, id.counsellor_id)
      .then((res) => setReceipt(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingReceipt(false));
  };

  const handleDeleteReceipt = async (id) => {
    if (id) {
      try {
        await deleteReceipt(id);
        loadReceipt({
          ...Object.fromEntries([...searchParams]),
          pagination: true,
          page,
        });
        message.success({
          content: "Receipt deleted successfully",
        });
      } catch (error) {
        message.error({
          content: "Something went wrong! Please try again later",
        });
        console.log("Something went wrong! Please try again later", error);
      }
    }

    throw new Error("Something went wrong");
  };

  function filterValue(key, value) {
    switch (key) {
      case "from_date":
        return dayjs(value).format("YYYY-MM-DD");
      case "to_date":
        return dayjs(value).format("YYYY-MM-DD");
      default:
        return value;
    }
  }

  useEffect(() => {
    loadReceipt({
      ...Object.fromEntries([...searchParams]),
      pagination: true,
      page: 1,
    });
  }, [id.branch_id]);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between">
        <Typography.Title style={{ marginTop: 0 }}>Receipt</Typography.Title>

        <Space>
          <Button onClick={showDrawer}>Export CSV</Button>
          <Button onClick={() => setIsFilterModalOpen(true)}>Filter</Button>
          <Button onClick={() => navigate("/receipt/add")}>Add Receipt</Button>
        </Space>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Form.Item>
          <Input.Search
            allowClear
            onSearch={(v) => {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                search: v,
              });
              loadReceipt({
                search: v,
              });
            }}
            size="large"
            placeholder="Search by Name or Mobile No"
            style={{ maxWidth: 300 }}
          />
        </Form.Item>
      </div>

      <Row
        style={{
          marginBottom: 16,
        }}
      >
        {Object.keys(Object.fromEntries([...searchParams])).map(
          (value, index) => (
            <Tag
              closable
              key={value}
              style={{ padding: 8 }}
              onClose={() => {
                const params = Object.fromEntries([...searchParams]);
                const values = omitBy(params, (v, k) => {
                  form.setFieldValue(k, null);
                  return k === value;
                });
                loadReceipt({ ...values, pagination: true, page });
                setSearchParams(values);
              }}
            >
              {replace(
                split(
                  upperFirst(
                    Object.keys(Object.fromEntries([...searchParams]))[index]
                  ),
                  "_"
                ).join(" "),
                "_",
                " "
              )}
              :{" "}
              {filterValue(
                Object.keys(Object.fromEntries([...searchParams]))[index],
                upperFirst(Object.fromEntries([...searchParams])[value])
              )}
            </Tag>
          )
        )}
      </Row>

      <Table
        loading={loadingReceipt}
        columns={[
          {
            key: "id",
            dataIndex: "id",
            title: <ColTitle>ID</ColTitle>,
          },
          {
            key: "student_id",
            title: <ColTitle>Student</ColTitle>,
            render: (_) => <ColValue>{_?.student_info?.name}</ColValue>,
          },
          {
            key: "mobile_no",
            title: <ColTitle>Mobile No</ColTitle>,
            render: (_) => (
              <ColValue>{_?.student_info?.contact?.mobile}</ColValue>
            ),
          },
          // {
          //   key: "invoice_id",
          //   dataIndex: "invoice_id",
          //   title: <ColTitle>Invoice</ColTitle>,
          // },
          {
            key: "receipt_date",
            dataIndex: "receipt_date",
            title: <ColTitle>Receipt Date</ColTitle>,
            render: (_) => <span>{dayjs(_).format("DD-MM-YYYY")}</span>,
          },
          {
            key: "receipt_no",
            title: <ColTitle>Receipt No</ColTitle>,
            render: (_) => <ColValue>{_?.receipt_number}</ColValue>,
          },
          // {
          //   key: "student_fee_status",
          //   dataIndex: "student_fee_status",
          //   title: <ColTitle>Student Fee Status</ColTitle>,
          // },
          {
            key: "received_amount",
            title: <ColTitle>Received Amount</ColTitle>,
            render: (_) => {
              return (
                <p>
                  {reduce(
                    _.payment_detail,
                    (acc, curr) => {
                      return acc + curr.paid_amount;
                    },
                    0
                  )}
                </p>
              );
            },
          },
          {
            key: "payment_mode",
            dataIndex: "payment_mode",
            title: <ColTitle>Payment Mode</ColTitle>,
          },
          {
            key: "transaction_status",
            dataIndex: "transaction_status",
            title: <ColTitle>Transaction Status</ColTitle>,
          },
          // {
          //   key: "transaction_date",
          //   dataIndex: "transaction_date",
          //   title: <ColTitle>Transaction Date</ColTitle>,
          //   render: (_) => <span>{dayjs(_).format("DD-MM-YYYY")}</span>,
          // },
          // {
          //   key: "deposit_to",
          //   dataIndex: "deposit_to",
          //   title: <ColTitle>Deposit To</ColTitle>,
          // },
          // {
          //   key: "narration",
          //   dataIndex: "narration",
          //   title: <ColTitle>Narration</ColTitle>,
          // },
          {
            key: "received_by",
            dataIndex: "received_by",
            title: <ColTitle>Received By</ColTitle>,
          },
          {
            key: "receipt_remark",
            title: <ColTitle>Receipt Remarks</ColTitle>,
            render: (_) => <ColValue>{_?.receipt_remarks}</ColValue>,
          },
          {
            key: "action",
            ...(user.user_type === "admin" && {
              title: <ColTitle>Action</ColTitle>,
            }),
            render: (_) => (
              <>
                {user.user_type === "admin" && (
                  <Space>
                    <Popconfirm
                      title="Delete Receipt?"
                      description="Are you sure to delete this receipt?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => {
                        handleDeleteReceipt(_.id);
                      }}
                    >
                      <Button danger>Delete</Button>
                    </Popconfirm>
                  </Space>
                )}
              </>
            ),
          },
        ]}
        dataSource={receipt?.data}
        scroll={{
          x: true,
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: receipt.per_page,
          total: receipt.total,
          current: receipt.current_page,
          defaultCurrent: receipt.current_page,
          onChange: (page, pageSize) => {
            setPage(page);
            loadReceipt({
              ...Object.fromEntries([...searchParams]),
              pagination: true,
              page,
            });
          },
        }}
        summary={(pageData) => {
          let totalReceivedAmount = 0;

          pageData.forEach(({ payment_detail }) => {
            payment_detail.forEach(({ paid_amount }) => {
              totalReceivedAmount += paid_amount;
            });
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={5} align="right">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <span style={{ fontWeight: 600 }}>{totalReceivedAmount}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <Modal
        okText="Filter"
        title="Filter Receipt"
        open={isFilterModalOpen}
        onOk={() => form.submit()}
        onCancel={() => setIsFilterModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            loadReceipt(values);
            setSearchParams(omitBy(values, isNil));
            setIsFilterModalOpen(false);
          }}
        >
          <Form.Item name="from_date" label="From Date">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="to_date" label="To Date">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>

      <Drawer destroyOnClose title="Export CSV" onClose={onClose} open={open}>
        <DatePicker.RangePicker
          size="large"
          value={dates || value}
          disabledDate={disabledDate}
          onCalendarChange={(val) => {
            setDates(val);
          }}
          onChange={(val) => {
            setValue(val);
          }}
          onOpenChange={onOpenChange}
          changeOnBlur
        />

        <div style={{ marginTop: 24 }}>
          <a
            style={{
              color: "#fff",
              backgroundColor: "#990100",
              boxShadow: "0 2px 0 rgba(97, 22, 5, 0.24)",
              fontSize: 14,
              height: 32,
              padding: "8px 15px",
              borderRadius: 6,
              ...(!value && {
                pointerEvents: "none",
                cursor: "default",
                backgroundColor: "#a19d9d",
              }),
            }}
            href={`https://erp-api.flyingfeathers.com/public/index.php/export_branch_payment?branch_id=${
              id.branch_id
            }&startdate=${dayjs(value?.[0]).format(
              "YYYY-MM-DD"
            )}&enddate=${dayjs(value?.[1]).format("YYYY-MM-DD")}`}
          >
            <span>Export</span>
          </a>
        </div>
      </Drawer>
    </LayoutContainer>
  );
}
