import { Button, Form, Input, Modal, Row, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import {
  createPermission,
  deletePermission,
  getPermission,
} from "../../../api/Permissions";

export default function PermissionsPage() {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const loadPermissions = (params = {}) => {
    setLoadingPermissions(true);
    getPermission(params)
      .then((res) => setPermissions(res.data.data))
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingPermissions(false));
  };

  const handleAddPermission = (values) => {
    createPermission(values)
      .then((res) => {
        loadPermissions();
        setOpen(false);
        form.resetFields(["name"]);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
    },
    {
      key: "guard_name",
      dataIndex: "guard_name",
      title: "Guard Name",
    },
    {
      key: "action",
      title: "Action",
      render: (_) => {
        return (
          <Button
            danger
            onClick={() =>
              deletePermission(_.id)
                .then((res) => loadPermissions())
                .catch((err) => console.log(err))
            }
          >
            Delete
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    loadPermissions();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Permissions
        </Typography.Title>
        <Button onClick={() => setOpen(true)}>Add Permission</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={permissions?.data}
        loading={loadingPermissions}
        pagination={{
          position: ["bottomCenter"],
          pageSize: permissions.per_page,
          total: permissions.total,
          onChange: (page, pageSize) => {
            loadPermissions({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        okText="Save"
        closable={false}
        title="Add Permission"
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
      >
        <Form form={form} layout="vertical" onFinish={handleAddPermission}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
