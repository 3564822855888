import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  Select,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import {
  getLeadSources,
  deleteLeadSource,
  createLeadSource,
} from "../../../api/LeadSource";
import useID from "../../../hooks/useID.hook";
import { getBranchById, getBranches } from "../../../api/Branch";

const { Title } = Typography;

export default function LeadSourcePage() {
  const id = useID();
  const [data, setData] = useState([]);
  const [loadingLeadSource, setLoadingLeadSource] = useState(false);
  const [addLeadSourceModal, setAddLeadSourceModal] = useState(false);
  const [branches, setBranches] = useState([]);

  const loadBranches = () => {
    getBranches({ pagination: false }).then((res) => {
      setBranches(res.data.data);
    });
  };

  const loadLeadSources = (params = {}) => {
    setLoadingLeadSource(true);
    getLeadSources(params)
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoadingLeadSource(false));
  };

  const handleAddLeadSource = (values) => {
    createLeadSource(values).then((res) => {
      loadLeadSources();
      setAddLeadSourceModal(false);
    });
  };

  useEffect(() => {
    loadBranches();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString("en-GB"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Button
              danger
              onClick={() => {
                deleteLeadSource(_.id);
                loadLeadSources();
              }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    loadLeadSources();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Lead Source</Title>

        <Button onClick={() => setAddLeadSourceModal(true)}>Add New</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        loading={loadingLeadSource}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadLeadSources({
              page,
            });
          },
        }}
      />

      <Modal
        footer={false}
        open={addLeadSourceModal}
        onCancel={() => setAddLeadSourceModal(false)}
        title="Add Lead Source"
      >
        <Form layout="vertical" onFinish={handleAddLeadSource}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter lead source name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="branch_id"
            label="Branch"
            initialValue={id.branch_id === "all" ? null : id.branch_id}
            rules={[
              {
                required: true,
                message: "Please select Branch",
              },
            ]}
          >
            <Select
              showSearch
              // loading={loadingBranches}
              optionFilterProp="children"
              placeholder="Please select Branch"
              style={{ width: "100%" }}
            >
              {branches.map((branch) => (
                <Select.Option value={branch.id}> {branch.name} </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
