import { useState } from "react";
import { Card, Col, Divider, Form, Row, Steps, Typography } from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import StepOne from "./edit-form-steps/StepOne";
import StepTwo from "./edit-form-steps/StepTwo";
import StepThree from "./edit-form-steps/StepThree";
import StepFour from "./edit-form-steps/StepFour";
// import { getAdmissionById } from "../../api/Admission";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { updateAdmission } from "../../api/Admission";

function CurrentStep({
  step,
  form,
  formValues,
  state = "create",
  setCurrentFormStep,
}) {
  switch (step) {
    case 0:
      return (
        <StepOne
          form={form}
          state={state}
          formValues={formValues}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 1:
      return (
        <StepTwo
          form={form}
          state={state}
          formValues={formValues}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 2:
      return (
        <StepThree
          form={form}
          state={state}
          formValues={formValues}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 3:
      return (
        <StepFour
          form={form}
          state={state}
          formValues={formValues}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    // case 4:
    //   return (
    //     <StepFive
    //       form={form}
    //       state={state}
    //       formValues={formValues}
    //       currentFormStep={step}
    //       setCurrentFormStep={setCurrentFormStep}
    //     />
    //   );
    // case 5:
    //   return (
    //     <StepSeven
    //       form={form}
    //       state={state}
    //       formValues={formValues}
    //       currentFormStep={step}
    //       setCurrentFormStep={setCurrentFormStep}
    //     />
    //   );
    default:
      return (
        <StepOne
          form={form}
          state={state}
          formValues={formValues}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
  }
}

export default function EditAdmissionForm() {
  const navigate = useNavigate();
  const params = useParams();
  const formValues = useLoaderData();
  const [form] = Form.useForm();
  const [currentFormStep, setCurrentFormStep] = useState(0);
  // const [formValues, setFormValues] = useState(loaderData);

  // const loadAdmission = () => {
  //   getAdmissionById(params.id)
  //     .then((res) => setFormValues(res.data.data))
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   loadAdmission();
  // }, []);

  if (formValues === null) {
    return "Loading";
  }

  return (
    <LayoutContainer>
      <Typography.Title style={{ marginTop: 0 }}>
        Edit Admission
      </Typography.Title>

      {/* <pre>{JSON.stringify(location.state.admission, null, 3)}</pre> */}
      <Card>
        <Row>
          <Col span={5}>
            <Steps
              size="small"
              direction="vertical"
              current={currentFormStep}
              items={[
                {
                  title: "Student Details",
                },
                {
                  title: "Standard Details",
                },
                {
                  title: "Admission Details",
                },
                {
                  title: "Admission Seatings",
                },
                // {
                //   title: "Fee Details",
                // },
                // {
                //   title: "Billing Details",
                // },
              ]}
            />
          </Col>

          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>

          <Col span={17}>
            <Form
              form={form}
              layout="vertical"
              // initialValues={formValues}
              onFinish={() => {
                updateAdmission(params.id, {
                  ...form.getFieldsValue(true),
                  branch_id: formValues.branch_id,
                  student_id: formValues.student_id,
                  counselor_id: formValues.counselor_id,
                })
                  .then(() => navigate("/admission"))
                  .catch((err) => console.log(err));
              }}
            >
              <CurrentStep
                form={form}
                step={currentFormStep}
                formValues={formValues}
                setCurrentFormStep={setCurrentFormStep}
              />

              {/* <Row align="end" style={{ gap: 10 }}>
                <Button
                  type="dashed"
                  disabled={currentFormStep === 0}
                  onClick={() => setCurrentFormStep(currentFormStep - 1)}
                >
                  Prev
                </Button>

                {currentFormStep === 5 ? (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    disabled={currentFormStep === 5}
                    onClick={() => setCurrentFormStep(currentFormStep + 1)}
                  >
                    Next
                  </Button>
                )}
              </Row> */}
            </Form>
          </Col>
        </Row>
      </Card>
    </LayoutContainer>
  );
}
