import axios from "axios";
import { BASE_URL } from "../../constants";

export function getParentInfo(params, branchId, counselorId) { 
  return axios.get(
    `${BASE_URL}/branch/${branchId}/counselor/${counselorId}/parent_info`,
    {
      params,
    } 
  );
}

export function createParentInfo(values) {
  return axios.post(`${BASE_URL}/parent_info`, values);
}

export function getParentInfoById(id) {
  return axios.get(`${BASE_URL}/parent_info/${id}`);
}

export function updateParentInfo(id, values) {
  return axios.put(`${BASE_URL}/parent_info/${id}`, values);
}

export function deleteParentInfo(id) {
  return axios.delete(`${BASE_URL}/parent_info/${id}`);
}
