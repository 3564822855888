import axios from "axios";
import { BASE_URL } from "../../constants";

export function getReceipt(params, branch_id, counselor_id) {
  return axios.get(`${BASE_URL}/receipt`, {
    params: {
      ...params,
      branch_id,
      counselor_id,
    },
  });
}

export function getInvoiceByStudentId(studentId, params) {
  return axios.get(`${BASE_URL}/invoice/student/${studentId}`, {
    params,
  });
}

export function getPendingInvoicesByStudentId(studentId, params) {
  return axios.get(`${BASE_URL}/invoice_history/student/${studentId}`, {
    params,
  });
}

export function createReceipt(values) {
  return axios.post(`${BASE_URL}/receipt`, values);
}

export function getReceiptById(id) {
  return axios.get(`${BASE_URL}/receipt/${id}`);
}

export function getReceiptByAdmissionId(admissionId) {
  return axios.get(`${BASE_URL}/receipt`, {
    params: {
      admission_id: admissionId,
    },
  });
}

export function updateReceipt(id, values) {
  return axios.put(`${BASE_URL}/receipt/${id}`, values);
}

export function deleteReceipt(id) {
  return axios.delete(`${BASE_URL}/receipt/${id}`);
}
