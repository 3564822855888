import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../../components/layout/LayoutContainer";
import { getFeeGroupById } from "../../../../api/FeeGroup";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { getFee } from "../../../../api/Fee";
import {
  createFeePackage,
  getFeePackagesByGroupId,
} from "../../../../api/FeePackage";

export default function FeeGroupById() {
  const params = useParams();
  const [form] = Form.useForm();
  const [fees, setFees] = useState([]);
  const [open, setOpen] = useState(false);
  const [feeGroup, setFeeGroup] = useState();
  const [feePackages, setFeePackages] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const loadFeeGroupById = (id) => {
    getFeeGroupById(id).then((res) => setFeeGroup(res.data.data));
  };

  const loadFee = () => {
    getFee({ pagination: false })
      .then((res) => setFees(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadFeePackageByGroupID = (id, params = {}) => {
    getFeePackagesByGroupId(id, params)
      .then((res) => setFeePackages(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleDelete = () => {
    const ids = [];
    selectedRows?.map((row) => ids.push(row.id));
  };

  const handleAddFeePackage = (values) => {
    const formData = new FormData();
    formData.append("feeGroup", params?.id);

    values?.fees?.forEach((value, index) => {
      formData.append(`fee[${index}]`, value.fee);
      formData.append(`amount[${index}]`, value.amount);
    });

    createFeePackage(formData)
      .then((res) => {
        loadFeePackageByGroupID(params?.id);
        setOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
    },
    {
      key: "fee",
      title: "Fee",
      render: (_) => {
        return <span>{_?.subject ?? "N/A"}</span>;
      },
    },
    {
      key: "fee_group",
      title: "Fee Group",
      render: (_) => {
        return <span>{_?.grade ?? "N/A"}</span>;
      },
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Amount",
    },
  ];

  useEffect(() => {
    loadFeeGroupById(params?.id);
    loadFeePackageByGroupID(params?.id);
    loadFee();
  }, [params]);

  return (
    <LayoutContainer>
      <Row justify="space-between" align="middle">
        <Typography.Title style={{ marginTop: 0 }}>
          {feeGroup?.name}
        </Typography.Title>

        <Space>
          <Button
            disabled={selectedRows.length === 0 ? true : false}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button onClick={() => setOpen(true)}>Add</Button>
        </Space>
      </Row>

      <Table
        rowSelection={{
          type: "checkbox",
          onChange: (key, rows) => {
            setSelectedRows(rows);
          },
        }}
        columns={columns}
        dataSource={feePackages.admission_seating}
        pagination={{
          position: ["bottomCenter"],
          pageSize: feePackages.per_page,
          total: feePackages.total,
          onChange: (page, pageSize) => {
            loadFeePackageByGroupID(params?.id, {
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        title="Add Fee Package"
        okText="Save"
        onOk={() => form.submit()}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddFeePackage}>
          <Form.Item name="feeGroup" label="Fee Group">
            <Input disabled readOnly defaultValue={feeGroup?.name} />
          </Form.Item>

          <Form.List name="fees">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Fee"
                        name={[field.name, "fee"]}
                        rules={[
                          {
                            required: true,
                            message: "Fee is required",
                          },
                        ]}
                      >
                        <Select placeholder="Please select fee">
                          {fees?.map((fee) => (
                            <Select.Option key={fee.id} value={fee.id}>
                              {fee.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Space>
                        <Form.Item
                          label="Amount"
                          name={[field.name, "amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Amount is required",
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            placeholder="Please enter amount"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>

                        <Button
                          size="small"
                          icon={<MinusOutlined />}
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button
                    block
                    type="dashed"
                    onClick={add}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
