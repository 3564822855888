import axios from "axios";
import { BASE_URL } from "../../constants";

export function getCities(params) {
  return axios.get(`${BASE_URL}/cities`, {
    params,
  });
}

export function getCitiesByState(params, state_id) {
  return axios.get(`${BASE_URL}/citybystate/${state_id}`, {
    params,
  });
}
