import axios from "axios";
import { BASE_URL } from "../../constants";

export function getEnquiryFollowup(params) {
  return axios.get(`${BASE_URL}/enquiry_followups`, {
    params,
  });
}

export function createEnquiryFollowup(values) {
  return axios.post(`${BASE_URL}/enquiry_followups`, values);
}

export function getEnquiryFollowupById(id) {
  return axios.get(`${BASE_URL}/enquiry_followups/${id}`);
}

export function updateEnquiryFollowup(id, values) {
  return axios.put(`${BASE_URL}/enquiry_followups/${id}`, values);
}

export function deleteEnquiryFollowup(id) {
  return axios.delete(`${BASE_URL}/enquiry_followups/${id}`);
}

export function getTodayFeeFollowup(params) {
  return axios.get(`${BASE_URL}/fee_followup`, {
    params,
  });
}

export function getPendingFeeFollowup(params) {
  return axios.get(`${BASE_URL}/fee_followup`, {
    params,
  });
}

export function getUpcomingFeeFollowup(params) {
  return axios.get(`${BASE_URL}/fee_followup`, {
    params,
  });
}
