import useAuth from "./useAuth.hook";
import { useBranches } from "./useBranches.hook";

export default function useID() {
  const { user } = useAuth();
  const { selectedBranch } = useBranches((state) => state);

  if (user.user_type === "admin") {
    return {
      counsellor_id: "all",
      branch_id: selectedBranch.selectedBranch ?? "all",
    };
  }

  if (user.user_type === "branch") {
    return {
      counsellor_id: "all",
      branch_id: user.branch_id,
    };
  }

  if (user.user_type === "counselor") {
    return {
      counsellor_id: user.id,
      branch_id: user.branch_id,
    };
  }
}
