import axios from "axios";
import { BASE_URL } from "../../constants";

export function getStream(params) {
  return axios.get(`${BASE_URL}/stream`, {
    params,
  });
}

export function createStream(values) {
  return axios.post(`${BASE_URL}/stream`, values);
}

export function getStreamsById(id) {
  return axios.get(`${BASE_URL}/stream/${id}`);
}

export function updateStreams(id, values) {
  return axios.put(`${BASE_URL}/stream/${id}`, values);
}

export function deleteStream(id) {
  return axios.delete(`${BASE_URL}/stream/${id}`);
}
