import axios from "axios";
import { BASE_URL } from "../../constants";

export function getSubjects(params) {
  return axios.get(`${BASE_URL}/subject`, {
    params,
  });
}

export function createSubject(values) {
  return axios.post(`${BASE_URL}/subject`, values);
}

export function getSubjectByFeeGroupId(id, params) {
  return axios.get(`${BASE_URL}/subject_by_fee_group`, {
    params: { ...params, fee_group_id: id },
  });
}

export function subjectAssign(id, values, params) {
  return axios.post(`${BASE_URL}/subject_assigning`, values, {
    params,
  });
}

export function getSubjectById(id) {
  return axios.get(`${BASE_URL}/subject/${id}`);
}

export function updateSubject(id, values) {
  return axios.put(`${BASE_URL}/subject/${id}`, values);
}

export function deleteSubject(id) {
  return axios.delete(`${BASE_URL}/subject/${id}`);
}
