import axios from "axios";
import { BASE_URL } from "../../constants";

export function getFee(params) {
  return axios.get(`${BASE_URL}/fee`, {
    params,
  });
}

export function createFee(values) {
  return axios.post(`${BASE_URL}/fee`, values);
}

export function getFeeById(id) {
  return axios.get(`${BASE_URL}/fee/${id}`);
}

export function updateFee(id, values) {
  return axios.put(`${BASE_URL}/fee/${id}`, values);
}

export function deleteFee(id) {
  return axios.delete(`${BASE_URL}/fee/${id}`);
}
