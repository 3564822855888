import axios from "axios";
import { BASE_URL } from "../../constants";

export function getLeadTypes(params) {
  return axios.get(`${BASE_URL}/lead_type`, {
    params,
  });
}

export function createLeadType(values) {
  return axios.post(`${BASE_URL}/lead_type`, values);
}

export function getLeadTypeById(id) {
  return axios.get(`${BASE_URL}/lead_type/${id}`);
}

export function updateLeadType(id, values) {
  return axios.put(`${BASE_URL}/lead_type/${id}`, values);
}

export function deleteLeadType(id) {
  return axios.delete(`${BASE_URL}/lead_type/${id}`);
}
