import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Steps,
  Typography,
} from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import StepFive from "./steps/StepFive";
import StepSeven from "./steps/StepSeven";
// import StepEight from "./steps/StepEight";
import { createAdmission, getIdCardNumber } from "../../api/Admission";
import { useNavigate } from "react-router-dom";
import useID from "../../hooks/useID.hook";
import { getBranches } from "../../api/Branch";
import { getCounsellorsByBranchId } from "../../api/Counsellor";
import { getNonAdmittedStudents } from "../../api/Leads";
import { find } from "lodash";
import { getSubjectByFeeGroupId } from "../../api/Subjects";
import { getFeeDetailsByGroup } from "../../api/FeeGroup";

function CurrentStep({ step, setCurrentFormStep, form, formValues }) {
  switch (step) {
    case 0:
      return (
        <StepOne
          form={form}
          formValues={formValues}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 1:
      return (
        <StepTwo
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 2:
      return (
        <StepThree
          form={form}
          currentFormStep={step}
          formValues={formValues}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 3:
      return (
        <StepFour
          form={form}
          currentFormStep={step}
          formValues={formValues}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 4:
      return (
        <StepFive
          form={form}
          currentFormStep={step}
          formValues={formValues}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 5:
      return (
        <StepSeven
          form={form}
          currentFormStep={step}
          formValues={formValues}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    // case 6:
    //   return (
    //     <StepEight
    //       form={form}
    //       currentFormStep={step}
    //       formValues={formValues}
    //       setCurrentFormStep={setCurrentFormStep}
    //     />
    //   );
    default:
      return (
        <StepOne
          form={form}
          currentFormStep={step}
          formValues={formValues}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
  }
}

export default function CreateAdmissionForm() {
  const id = useID();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const formValues = Form.useWatch([], form);
  const [students, setStudents] = useState([]);
  const [branches, setBranches] = useState([]);
  const [counsellors, setCounsellor] = useState([]);
  const [currentFormStep, setCurrentFormStep] = useState(0);

  const handleCreateAdmission = async (values) => {
    try {
      await createAdmission(values).then((res) => console.log(res));
      navigate("/admission");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadFeeDetails = (feeGroupId) => {
    getFeeDetailsByGroup({}, feeGroupId).then((res) => {
      form.setFieldValue("feeDetails", res.data.data);
    });
  };

  const handleLoadAdmissionSeatingAndFeeDetails = (feeGroupId) => {
    getSubjectByFeeGroupId(feeGroupId, {}).then((res) => {
      form.setFieldValue("admission_seating", res.data.data);

      // form.setFieldValue("feeDetails", res.data.data.fee_details);

      console.log(res);
    });
  };

  const loadStudents = () => {
    getNonAdmittedStudents(
      { pagination: false },
      form.getFieldValue("branch_id"),
      form.getFieldValue("counselor_id")
    ).then((res) => {
      setStudents(res.data.data);
    });
  };

  const loadBranches = () => {
    getBranches({ pagination: false })
      .then((res) => {
        setBranches(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadCounselors = (branchId) => {
    getCounsellorsByBranchId(
      { pagination: false },
      branchId ?? id.branch_id
    ).then((res) => setCounsellor(res.data.data));
  };

  const loadIdCardNumber = (branchId) => {
    getIdCardNumber(branchId).then((res) => {
      form.setFieldValue("idcard", res.data.data.idcard);
    });
  };

  useEffect(() => {
    if (id.branch_id !== "all") {
      form.setFieldValue("branch_id", id.branch_id);
      loadCounselors(id.branch_id);
      loadIdCardNumber(id.branch_id);
    } else {
      form.setFieldValue("branch_id", null);
      form.setFieldValue("counselor_id", null);
      setCounsellor([]);
    }
  }, [id.branch_id]);

  useEffect(() => {
    loadBranches();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify={"space-between"}>
        <Typography.Title style={{ marginTop: 0 }}>
          Add Admission
        </Typography.Title>
      </Row>

      <Card style={{ marginBottom: 16 }}>
        <Form form={form} layout="vertical">
          {id.branch_id === "all" && (
            <Form.Item name="branch_id" label="Branch">
              <Select
                onChange={(value) => {
                  form.setFieldValue("branch_id", value);
                  form.setFieldValue("counselor_id", null);
                  loadIdCardNumber(value);
                  loadCounselors(value);
                }}
                placeholder="Please select branch"
              >
                {branches.map((branch) => (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="counselor_id"
            label="Counselor"
            initialValue={form.getFieldValue("counselor_id")}
            rules={[
              {
                required: true,
                message: "Please select counselor",
              },
            ]}
          >
            <Select
              defaultValue={form.getFieldValue("counselor_id")}
              onChange={(value) => {
                form.setFieldValue("counselor_id", value);
                loadStudents();
              }}
              placeholder="Please select counselor"
            >
              {counsellors.map((counselor) => (
                <Select.Option value={counselor.id}>
                  {counselor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="student_id"
            label="Select Student"
            initialValue={formValues?.student_id}
            rules={[
              {
                required: true,
                message: "Please select student",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select student"
              onChange={(value) => {
                const student = find(students, { id: value });
                form.setFieldValue("fee_package_id", student.fee_group_id);
                handleLoadAdmissionSeatingAndFeeDetails(student.fee_group_id);
                handleLoadFeeDetails(student.fee_group_id);
              }}
            >
              {students?.map((student) => (
                <Select.Option key={student.id} value={student.id}>
                  {student.name} | {student.mobile}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Card>

      <Card>
        <Row>
          <Col span={5}>
            <Steps
              size="small"
              direction="vertical"
              current={currentFormStep}
              items={[
                {
                  title: "Student Details",
                  // onClick: () => setCurrentFormStep(0),
                },
                {
                  title: "Standard Details",
                  // onClick: () => setCurrentFormStep(1),
                },
                {
                  title: "Admission Details",
                  // onClick: () => setCurrentFormStep(2),
                },
                {
                  title: "Admission Seatings",
                  // onClick: () => setCurrentFormStep(3),
                },
                {
                  title: "Fee Details",
                  // onClick: () => setCurrentFormStep(4),
                },
                {
                  title: "Billing Details",
                  // onClick: () => setCurrentFormStep(5),
                },
              ]}
            />
          </Col>

          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>

          <Col span={17}>
            <Form
              form={form}
              layout="vertical"
              onFinish={() => handleCreateAdmission(form.getFieldsValue(true))}
            >
              <CurrentStep
                form={form}
                step={currentFormStep}
                formValues={form.getFieldsValue(true)}
                setCurrentFormStep={setCurrentFormStep}
              />
            </Form>
          </Col>
        </Row>
      </Card>
    </LayoutContainer>
  );
}
