import { Button, Form, Input, Modal, Row, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import { createMedium, getMedium } from "../../../api/Medium";

const { Title } = Typography;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => new Date(text).toLocaleDateString("en-GB"),
  },
];

export default function MediumPage() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loadingMedium, setLoadingMedium] = useState(false);
  const [open, setOpen] = useState(false);

  const loadMedium = (params = {}) => {
    setLoadingMedium(true);
    getMedium(params)
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoadingMedium(false));
  };

  const handleAddMedium = (values) => {
    createMedium(values)
      .then((res) => {
        loadMedium();
        setOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadMedium();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Medium</Title>

        <Button onClick={() => setOpen(true)}>Add New</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        loading={loadingMedium}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadMedium({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        okText="Save"
        title="Add Medium"
        onOk={() => form.submit()}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddMedium}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Please enter your name" />
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
