import axios from "axios";
import { BASE_URL } from "../../constants";

export function getDivision(params) {
  return axios.get(`${BASE_URL}/division`, {
    params,
  });
}

export function createDivision(values) {
  return axios.post(`${BASE_URL}/division`, values);
}

export function getDivisionById(id) {
  return axios.get(`${BASE_URL}/division/${id}`);
}

export function updateDivision(id, values) {
  return axios.put(`${BASE_URL}/division/${id}`, values);
}

export function deleteDivision(id) {
  return axios.delete(`${BASE_URL}/division/${id}`);
}
