import { Button, Row, Space, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { getCounsellorsByBranchId } from "../../../api/Counsellor";
import { useBranches } from "../../../hooks/useBranches.hook";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function CounsellorsPage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { selectedBranch } = useBranches((state) => state);
  const [loadingCounsellors, setLoadingCounsellors] = useState(false);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const loadCounsellors = (params = {}) => {
    setLoadingCounsellors(true);
    getCounsellorsByBranchId(params, selectedBranch?.selectedBranch ?? "all")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingCounsellors(false));
  };

  useEffect(() => {
    loadCounsellors();
  }, [selectedBranch]);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Counsellers
        </Typography.Title>

        <Space>
          <Button onClick={() => navigate("/masters/counsellors/add")}>
            Add Counseller
          </Button>
        </Space>
      </Row>

      <Table
        columns={columns}
        dataSource={data?.data?.data}
        loading={loadingCounsellors}
      />
    </LayoutContainer>
  );
}
