import axios from "axios";
import { BASE_URL } from "../../constants";

export function getFeeFollowup(params) {
  return axios.get(`${BASE_URL}/feefollowup`, {
    params,
  });
}

export function createFeeFollowup(values) {
  return axios.post(`${BASE_URL}/feefollowup`, values);
}

export function getFeeFollowupById(id) {
  return axios.get(`${BASE_URL}/fee_followup/history/${id}`);
}
