import { Button, DatePicker, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { getMedium } from "../../../api/Medium";
import { getAdmissionStatus } from "../../../api/AdmissionStatus";
import { getDivision } from "../../../api/Division";

export default function StepOne({
  form,
  state,
  formValues,
  currentFormStep,
  setCurrentFormStep,
}) {
  const _formValues = Form.useWatch([], form);
  const [mediums, setMediums] = useState([]);
  const [division, setDivision] = useState([]);
  const [admissionStatus, setAdmissionStatus] = useState([]);

  const loadMedium = () => {
    getMedium({ pagination: false }).then((res) => {
      setMediums(res.data.data);
    });
  };

  const loadDivision = () => {
    getDivision({ pagination: false, branch_id: formValues?.branch_id }).then(
      (res) => {
        setDivision(res.data.data);
      }
    );
  };

  const loadAdmissionStatus = () => {
    getAdmissionStatus({ pagination: false }).then((res) => {
      setAdmissionStatus(res.data.data);
    });
  };

  // useEffect(() => {
  //   loadStudents({ pagination: false }, id.branch_id, id.counsellor_id);
  // }, [selectedBranch]);

  useEffect(() => {
    // loadStudents();
    loadMedium();
    loadAdmissionStatus();
    loadDivision();
  }, []);

  return (
    <>
      <Form.Item
        name="division"
        label="Division"
        initialValue={formValues?.division?.id}
        rules={[
          {
            required: true,
            message: "Please select division",
          },
        ]}
      >
        <Select placeholder="Please select division">
          {division.map((v) => (
            <Select.Option value={v.id}>{v.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="medium"
        label="Medium"
        initialValue={formValues?.medium?.id}
        rules={[
          {
            required: true,
            message: "Please select medium",
          },
        ]}
      >
        <Select placeholder="Please select medium">
          {mediums.map((v) => (
            <Select.Option value={v.id}>{v.name} </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="admission_status"
        label="Admission Status"
        initialValue={formValues?.admission_status?.id}
        rules={[
          {
            required: true,
            message: "Please select admission status",
          },
        ]}
      >
        <Select placeholder="Please select admission status">
          {admissionStatus.map((status) => (
            <Select.Option value={status.id}>{status.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {_formValues?.admission_status === 2 && (
        <Form.Item
          name="hold_till_date"
          label="Hold till date"
          initialValue={formValues?.hold_till_date}
          rules={[
            {
              required: true,
              message: "Please select admission status",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      )}

      <Form.Item
        name="admission_remarks"
        label="Admission Remarks"
        initialValue={formValues?.admission_remarks}
      >
        <Input.TextArea />
      </Form.Item>

      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          disabled={currentFormStep === 6}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </>
  );
}
