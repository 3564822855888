export const BASE_URL =
  "https://erp-api.flyingfeathers.com/public/index.php/api/V1";

export const PRIMARY_COLOR = "#990100";

export const ACADEMIC_YEAR = [
  "2010-2011",
  "2011-2012",
  "2012-2013",
  "2013-2014",
  "2014-2015",
  "2015-2016",
  "2016-2017",
  "2017-2018",
  "2018-2019",
  "2019-2020",
  "2020-2021",
  "2021-2022",
  "2022-2023",
  "2023-2024",
];
