import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  Select,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import useID from "../../../hooks/useID.hook";
import { getBranches } from "../../../api/Branch";
import {
  createSubject,
  deleteSubject,
  getSubjects,
} from "../../../api/Subjects";

const { Title } = Typography;

export default function SubjectPage() {
  const id = useID();
  const [data, setData] = useState([]);
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [addSubjectModal, setAddSubjectModal] = useState(false);

  const loadSubjects = (params = {}) => {
    setLoadingSubject(true);
    getSubjects(params)
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoadingSubject(false));
  };

  const handleAddSubject = (values) => {
    createSubject(values).then((res) => {
      loadSubjects();
      setAddSubjectModal(false);
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString("en-GB"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Button
              danger
              onClick={() => {
                deleteSubject(_.id);
                loadSubjects();
              }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    loadSubjects();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Subject</Title>

        <Button onClick={() => setAddSubjectModal(true)}>Add New</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        loading={loadingSubject}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadSubjects({
              page,
            });
          },
        }}
      />

      <Modal
        footer={false}
        open={addSubjectModal}
        onCancel={() => setAddSubjectModal(false)}
        title="Add Subject"
      >
        <Form layout="vertical" onFinish={handleAddSubject}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter subject name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
