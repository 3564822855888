import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../constants";
import { getCountries } from "../../../api/Countries";
import { useEffect, useState } from "react";
import { getCitiesByState } from "../../../api/Cities";
import { getStatesByCountryId } from "../../../api/States";
import { createBranch, getBranches } from "../../../api/Branch";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export default function CreateBranchPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loadingBranchInfo, setLoadingBranchInfo] = useState(false);
  const [creatingBranchInfo, setCreatingBranchInfo] = useState(false);

  const loadCountries = () => {
    getCountries({ pagination: false }).then((res) =>
      setCountries(res.data.data)
    );
  };

  const loadStates = (country_id) => {
    getStatesByCountryId({ pagination: false }, country_id).then((res) => {
      setStates(res.data.data?.states ?? []);
    });
  };

  const loadCities = (state_id) => {
    getCitiesByState({ pagination: false }, state_id).then((res) =>
      setCities(res.data?.data ?? [])
    );
  };

  useEffect(() => {
    loadCountries();
    loadBranchInfo();
  }, []);

  const loadBranchInfo = (params = {}) => {
    setLoadingBranchInfo(true);
    getBranches(params)
      .then((res) => {
        // setData(res.data.data);
      })
      .finally(() => setLoadingBranchInfo(false));
  };

  // useEffect(() => {
  //   loadBranchInfo();
  // }, []);

  const handleBranchInfo = (values) => {
    setCreatingBranchInfo(true);
    createBranch(values)
      .then((res) => {
        setData([...data, res.data]);
        form.resetFields([]);
        navigate("/settings/branches");
      })
      .catch((err) => message.error("Something went wrong"))
      .finally(() => {
        setCreatingBranchInfo(false);
      });
  };

  return (
    <LayoutContainer>
      <Title style={{ marginTop: 0 }}>Add Branch</Title>

      <Card title="Branch Information">
        {/* <pre>{JSON.stringify(formValues, null, 4)}</pre> */}

        <Form
          scrollToFirstError
          form={form}
          layout="vertical"
          loading={loadingBranchInfo}
          onFinish={(values) => handleBranchInfo(values)}
        >
          {/* <Form.Item name="signatureLogo" label="Signature Logo">
            <Upload
              showUploadList={false}
              listType="picture-card"
              className="avatar-uploader"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            >
              <div>
                <PlusOutlined />
                {loading ? <Loading/Outlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item> */}

          <Form.Item
            name="name"
            label="Branch Name"
            rules={[
              {
                required: true,
                message: "Branch name is required",
              },
            ]}
          >
            <Input placeholder="Please enter branch name" />
          </Form.Item>

          <Form.Item
            name="institute_type"
            label="Institute Type"
            rules={[
              {
                required: true,
                message: "Institute type is required",
              },
            ]}
          >
            <Select placeholder="Please select institute type">
              <Select.Option value="dfavo">Dfavo</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="branch_code"
            label="Branch Code"
            rules={[
              {
                required: true,
                message: "Branch code is required",
              },
            ]}
          >
            <Input placeholder="Please enter branch code" />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Address is required",
              },
            ]}
          >
            <Input placeholder="Please enter address" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email address is required",
              },
              {
                type: "email",
                message: "Email address is not valid",
              },
            ]}
          >
            <Input placeholder="Please enter email" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input.Password placeholder="*******" />
          </Form.Item>

          <Form.Item
            name="mobile_number"
            label="Mobile No"
            rules={[
              {
                required: true,
                message: "Mobile no is required",
              },
            ]}
          >
            <Input
              type="number"
              inputMode="numeric"
              placeholder="Please enter mobile no"
            />
          </Form.Item>

          <Form.Item name="telephone_number" label="Telephone No">
            <Input
              type="number"
              inputMode="numeric"
              placeholder="Please enter telephone no"
            />
          </Form.Item>

          <Form.Item name="gstin" label="GSTIN">
            <Input placeholder="Please enter GSTIN" />
          </Form.Item>

          <Form.Item name="pan_number" label="PAN Number">
            <Input placeholder="Please enter PAN Number" />
          </Form.Item>

          <Form.Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: "Please select country",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                form.setFieldValue("state_id", "");
                form.setFieldValue("city_id", "");
                loadStates(value);
              }}
              placeholder="Please select country"
            >
              {countries?.map((country) => (
                <Select.Option value={country.id}>{country.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            showSearch
            label="State"
            name="state_id"
            rules={[
              {
                required: true,
                message: "Please select state",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select state"
              onChange={(value) => loadCities(value)}
            >
              {states?.map((state) => (
                <Select.Option value={state.id}> {state.name} </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            showSearch
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: "Please select city",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select city"
            >
              {cities.map((city) => (
                <Select.Option value={city.id}>{city.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="area_geolocation" label="Area Geolocation">
            <Input placeholder="Please enter area geolocation" />
          </Form.Item>

          <Form.Item name="default_senderid" label="Default Sender ID">
            <Input placeholder="Please enter default sender id" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={creatingBranchInfo}>
            Save
          </Button>
        </Form>
      </Card>
    </LayoutContainer>
  );
}
