import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignInPage from "../pages/authentication/signin.page";
import DashboardPage from "../pages/dashboard/Index";
import LeadsPage from "../pages/leads";
import MastersPage from "../pages/masters";
import ParentInfoPage from "../pages/masters/parent-info";
import EductionPage from "../pages/masters/education";
import AreaPage from "../pages/masters/area";
import CounsellorsPage from "../pages/masters/counsellors";
import LeadSourcePage from "../pages/masters/lead-source";
import LeadStagePage from "../pages/masters/lead-stage";
import FollowupPage from "../pages/followup";
import SettingsPage from "../pages/settings";
import CreateBranchPage from "../pages/settings/branch/create-branch.page";
import BranchesPage from "../pages/settings/branch";
import CreateEmployeePage from "../pages/masters/counsellors/create-employee.page";
import MediumPage from "../pages/masters/medium";
import CreateLeadPage from "../pages/leads/create-lead.page";
import IsAuthenticated from "../middleware/IsAuthenticated.component";
import FeesPage from "../pages/masters/fees";
import FeesGroupPage from "../pages/masters/fees/fees-group";
import FeesPackagePage from "../pages/masters/fees/fee-package";
import AdminPage from "../pages/settings/admin";
import UserCounsellorsPage from "../pages/settings/counsellors";
import PermissionsPage from "../pages/masters/permissions";
import RolesPage from "../pages/masters/roles";
import FeeFollowupPage from "../pages/followup/feeFollowup.page";
import FeeGroupById from "../pages/masters/fees/fees-group/[ID]";
import AdmissionPage from "../pages/admission";
import ReceiptPage from "../pages/transaction/receipt";
import StreamPage from "../pages/masters/stream/index";
import AddReceiptPage from "../pages/transaction/receipt/add-receipt";
import AdmissionByIDPage from "../pages/admission/[id]";
import EditAdmissionForm from "../pages/admission/edit-admission.page";
import LeadsByIDPage from "../pages/leads/[id]";
import CreateAdmissionForm from "../pages/admission/create-admission.page";
import { getAdmissionById } from "../api/Admission";
import { message } from "antd";
import { getLeadById, getLeads } from "../api/Leads";
import { useBranches } from "../hooks/useBranches.hook";
import EditLeadPage from "../pages/leads/edit-lead.page";
import InvoicesPage from "../pages/invoice";
import SubjectsByFeeGroupId from "../pages/masters/fees/subjects/[ID]";
import SubjectPage from "../pages/masters/subject";
import DivisionsPage from "../pages/masters/divisions";

export default function Router() {
  const router = createBrowserRouter([
    {
      path: "/signin",
      element: (
        //   <IsNotAuthenticated>
        <SignInPage />
        //   </IsNotAuthenticated>
      ),
    },
    {
      path: "/",
      element: (
        <IsAuthenticated>
          <DashboardPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters",
      element: (
        <IsAuthenticated role={["admin"]}>
          <MastersPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/invoice",
      element: (
        <IsAuthenticated>
          <InvoicesPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/parent-info",
      element: (
        <IsAuthenticated role={["admin"]}>
          <ParentInfoPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/education",
      element: (
        <IsAuthenticated role={["admin"]}>
          <EductionPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/stream",
      element: (
        <IsAuthenticated role={["admin"]}>
          <StreamPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/area",
      element: (
        <IsAuthenticated role={["admin"]}>
          <AreaPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/counsellors",
      element: (
        <IsAuthenticated role={["admin"]}>
          <CounsellorsPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/counsellors/add",
      element: (
        <IsAuthenticated role={["admin"]}>
          <CreateEmployeePage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/lead-source",
      element: (
        <IsAuthenticated role={["admin"]}>
          <LeadSourcePage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/lead-stage",
      element: (
        <IsAuthenticated role={["admin"]}>
          <LeadStagePage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/medium",
      element: (
        <IsAuthenticated role={["admin"]}>
          <MediumPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/fees",
      element: (
        <IsAuthenticated role={["admin"]}>
          <FeesPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/fees/fees-group",
      element: (
        <IsAuthenticated role={["admin"]}>
          <FeesGroupPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/fees/fees-group/:id",
      element: (
        <IsAuthenticated role={["admin"]}>
          <FeeGroupById />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/fees/fees-group/:id/subjects",
      element: (
        <IsAuthenticated role={["admin"]}>
          <SubjectsByFeeGroupId />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/fees/fees-package",
      element: (
        <IsAuthenticated role={["admin"]}>
          <FeesPackagePage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/permissions",
      element: (
        <IsAuthenticated role={["admin"]}>
          <PermissionsPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/roles",
      element: (
        <IsAuthenticated role={["admin"]}>
          <RolesPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/subject",
      element: (
        <IsAuthenticated role={["admin"]}>
          <SubjectPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/masters/divisions",
      element: (
        <IsAuthenticated role={["admin"]}>
          <DivisionsPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/settings",
      element: (
        <IsAuthenticated role={["admin"]}>
          <SettingsPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/settings/branches",
      element: (
        <IsAuthenticated role={["admin"]}>
          <BranchesPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/settings/branches/add",
      element: (
        <IsAuthenticated role={["admin"]}>
          <CreateBranchPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/settings/admin",
      element: (
        <IsAuthenticated role={["admin"]}>
          <AdminPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/settings/counsellers",
      element: (
        <IsAuthenticated role={["admin"]}>
          <UserCounsellorsPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/leads",
      element: (
        <IsAuthenticated>
          <LeadsPage />
        </IsAuthenticated>
      ),
      // loader: async ({ params }) => {
      //   try {
      //     const res = await getLeads(
      //       params
      //       // selectedBranch?.selectedBranch ?? "all"
      //     );

      //     return res.data.data;
      //   } catch (error) {
      //     message.error("Failed to fetch leads");
      //     return [];
      //   }
      // },
    },
    {
      path: "/leads/add",
      element: (
        <IsAuthenticated>
          <CreateLeadPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/leads/:id",
      element: (
        <IsAuthenticated>
          <LeadsByIDPage />
        </IsAuthenticated>
      ),
      loader: async ({ params }) => {
        try {
          const res = await getLeadById(params.id);
          return res.data.data;
        } catch (error) {
          message.error("Failed to fetch lead");
          return {};
        }
      },
    },
    {
      path: "/leads/edit/:id",
      element: (
        <IsAuthenticated>
          <EditLeadPage />
        </IsAuthenticated>
      ),
      loader: async ({ params }) => {
        try {
          const res = await getLeadById(params.id);
          return res.data.data;
        } catch (error) {
          return {};
        }
      },
    },
    {
      path: "/followup",
      element: (
        <IsAuthenticated>
          <FollowupPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/admission",
      element: (
        <IsAuthenticated>
          <AdmissionPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/create-admission",
      element: (
        <IsAuthenticated>
          <CreateAdmissionForm />
        </IsAuthenticated>
      ),
    },
    {
      path: "/admission/:id",
      element: (
        <IsAuthenticated>
          <AdmissionByIDPage />
        </IsAuthenticated>
      ),
      loader: async ({ params }) => {
        try {
          const res = await getAdmissionById(params.id);
          return res.data.data;
        } catch (error) {
          return {};
        }
      },
    },
    {
      path: "/admission/edit/:id",
      element: (
        <IsAuthenticated>
          <EditAdmissionForm />
        </IsAuthenticated>
      ),
      loader: async ({ params }) => {
        try {
          const res = await getAdmissionById(params.id);
          const data = res.data.data;
          return data;
        } catch (error) {
          // message.error("Something went wrong");
          return {};
        }
      },
    },
    {
      path: "/receipt",
      element: (
        <IsAuthenticated>
          <ReceiptPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/receipt/add",
      element: (
        <IsAuthenticated>
          <AddReceiptPage />
        </IsAuthenticated>
      ),
    },
    {
      path: "/followup/fee-followup",
      element: (
        <IsAuthenticated>
          <FeeFollowupPage />
        </IsAuthenticated>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
