import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { MdOutlineVisibility } from "react-icons/md";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table as PDFTable,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import { useLoaderData, useParams } from "react-router-dom";
import { reduce, sumBy } from "lodash";
import "./style.css";
import { useEffect } from "react";
import { getReceiptByAdmissionId } from "../../api/Receipt";
import { useState } from "react";
import ColTitle from "../../components/Table/ColTitle.component";
import ColValue from "../../components/Table/ColValue.component";
import { getInvoices } from "../../api/Invoice";
import dayjs from "dayjs";

const { Title } = Typography;

// Overview Tab Content
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Open Sans",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: "100%",
    height: window.innerHeight,
  },
});

const tableHeaderStyle = {
  fontSize: "9px",
  fontWeight: "600",
  backgroundColor: "#F0F0F0",
  borderRight: "1px solid #ffffff",
  padding: "8px 4px",
  fontFamily: "Open Sans",
};

const dataTableCellStyle = {
  fontSize: "8px",
  padding: "8px 4px",
  borderRight: "1px solid #ffffff",
  borderBottom: "1px solid #eeeeee",
};

const InvoiceHeaderFontSize = 10;
const TableCellFontSize = 9;

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});

const OverviewTab = () => {
  const loaderData = useLoaderData();
  return (
    <Card>
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.page}>
            <View wrap style={styles.section} fixed>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 20,
                      fontWeight: "extrabold",
                    }}
                  >
                    INVOICE
                  </Text>
                  <Text
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: InvoiceHeaderFontSize,
                    }}
                  >
                    {loaderData?.invoice_no ?? ""}
                  </Text>

                  <Text
                    style={{
                      margin: 0,
                      paddingVertical: 10,
                      fontSize: InvoiceHeaderFontSize,
                    }}
                  >
                    Flyingfeathers-Barnala
                  </Text>
                </View>

                <View>
                  <Image
                    fixed
                    style={{
                      alignSelf: "flex-end",
                      width: 80,
                    }}
                    source={{
                      uri: "/assets/images/logo-1.png",
                    }}
                  />
                  <Text
                    style={{
                      paddingTop: 10,
                      alignSelf: "flex-end",
                      fontSize: InvoiceHeaderFontSize,
                    }}
                  >
                    Invoice Date: 12-Feb-2023
                  </Text>
                  <Text
                    style={{
                      fontSize: InvoiceHeaderFontSize,
                      paddingTop: 3,
                      alignSelf: "flex-end",
                    }}
                  >
                    Place Of Supply: Punjab
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginHorizontal: 10,
                paddingHorizontal: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: 20,
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: InvoiceHeaderFontSize,
                      paddingVertical: 1,
                    }}
                  >
                    To: {loaderData?.student_ids?.name ?? "N/A"}
                  </Text>
                </View>
              </View>

              <View style={{ paddingVertical: 20 }}>
                <PDFTable
                  style={{ borderColor: "white", width: "100%" }}
                  data={loaderData?.invoice_details}
                >
                  <TableHeader
                    includeRightBorder={false}
                    includeLeftBorder={false}
                    includeTopBorder={false}
                    includeBottomBorder={false}
                  >
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      weighting={0.3}
                      style={tableHeaderStyle}
                    >
                      #
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      weighting={1}
                      style={tableHeaderStyle}
                    >
                      Fees Type
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      weighting={0.3}
                      style={tableHeaderStyle}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      weighting={0.7}
                      style={tableHeaderStyle}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      weighting={0.4}
                      textAlign="right"
                      style={{ ...tableHeaderStyle, textAlign: "right" }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      weighting={0.4}
                      textAlign="right"
                      style={{ ...tableHeaderStyle, textAlign: "right" }}
                    >
                      Total
                    </TableCell>
                  </TableHeader>
                  <TableBody
                    includeRightBorder={false}
                    includeTopBorder={false}
                    includeLeftBorder={false}
                    includeBottomBorder={false}
                  >
                    <DataTableCell
                      includeLeftBorder={false}
                      includeRightBorder={false}
                      style={dataTableCellStyle}
                      weighting={0.3}
                      getContent={(r) => {
                        return (
                          <Text style={{ fontSize: TableCellFontSize }}>
                            {r.id}
                          </Text>
                        );
                      }}
                    />
                    <DataTableCell
                      weighting={1}
                      includeRightBorder={false}
                      includeTopBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder={false}
                      style={dataTableCellStyle}
                      getContent={(r) => {
                        return (
                          <Text style={{ fontSize: TableCellFontSize }}>
                            {r?.fee_type}
                          </Text>
                        );
                      }}
                    />
                    <DataTableCell
                      weighting={0.3}
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      style={dataTableCellStyle}
                      getContent={(r) => {
                        return (
                          <Text style={{ fontSize: TableCellFontSize }}>
                            {r.quantity}
                          </Text>
                        );
                      }}
                    />
                    <DataTableCell
                      weighting={0.7}
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      style={dataTableCellStyle}
                      getContent={(r) => (
                        <Text style={{ fontSize: TableCellFontSize }}>
                          {r.amount}
                        </Text>
                      )}
                    />
                    <DataTableCell
                      weighting={0.4}
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      style={dataTableCellStyle}
                      textAlign="right"
                      getContent={(r) => (
                        <Text
                          style={{
                            fontSize: TableCellFontSize,
                            textAlign: "right",
                          }}
                        >
                          0
                        </Text>
                      )}
                    />
                    <DataTableCell
                      weighting={0.4}
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="right"
                      style={dataTableCellStyle}
                      getContent={(r) => (
                        <Text
                          style={{
                            fontSize: TableCellFontSize,
                            textAlign: "right",
                          }}
                        >
                          {r.amount * r.quantity}
                        </Text>
                      )}
                    />
                  </TableBody>
                </PDFTable>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    paddingHorizontal: 6,
                    paddingVertical: 8,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "space-between",
                      width: "100%",
                      maxWidth: "160px",
                      padding: "6px 4px",
                      borderBottom: "0.8px solid #E8e8e8",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: TableCellFontSize,
                        fontWeight: "semibold",
                      }}
                    >
                      Total:
                    </Text>
                    <Text
                      style={{
                        fontSize: TableCellFontSize,
                      }}
                    >
                      {reduce(
                        loaderData?.invoice_details,
                        (amount, n) => {
                          return amount + Number(n.amount);
                        },
                        0
                      )}
                    </Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                      maxWidth: "160px",
                      padding: "6px 4px",
                      borderBottom: "0.8px solid #E8e8e8",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: TableCellFontSize,
                        fontWeight: "semibold",
                      }}
                    >
                      Payments:
                    </Text>
                    <Text
                      style={{
                        fontSize: TableCellFontSize,
                      }}
                    >
                      (-) 14000.00 (static)
                    </Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                      maxWidth: "160px",
                      padding: "6px 4px",
                      borderBottom: "0.8px solid #E8e8e8",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: TableCellFontSize,
                        fontWeight: "semibold",
                      }}
                    >
                      Balance Due:
                    </Text>
                    <Text
                      style={{
                        fontSize: TableCellFontSize,
                      }}
                    >
                      14000.00 (static)
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <Text
                  style={{
                    fontSize: 8,
                    fontWeight: "500",
                    display: "flex",
                    padding: 10,
                    backgroundColor: "#E9e9e9",
                  }}
                >
                  Terms & Condition :
                </Text>

                <View
                  style={{
                    display: "flex",
                    padding: 10,
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      marginBottom: 4,
                      fontWeight: "demibold",
                    }}
                  >
                    TERMS & CONDITIONS:
                  </Text>

                  <View
                    style={{
                      marginBottom: 6,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 7.25,
                        textDecoration: "underline",
                      }}
                    >
                      RULES AND REGULATION
                    </Text>

                    <Text
                      style={{
                        fontSize: 7.25,
                        marginBottom: 2,
                      }}
                    >
                      All students of the institute must follow the following
                      rules and regulations.
                    </Text>
                  </View>

                  <View
                    style={{
                      marginBottom: 6,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 7.25,
                        textDecoration: "underline",
                      }}
                    >
                      RECEIPT OF YOUR PAYMENT
                    </Text>

                    <Text
                      style={{
                        fontSize: 7.25,
                        marginBottom: 2,
                      }}
                    >
                      Please demand a receipt of all payments made. Zenoxit
                      Preschool and Kindergarten is not responsible if any
                      payment is made without a proper receipt issued by the
                      office.
                    </Text>
                  </View>

                  <View
                    style={{
                      marginBottom: 6,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 7.25,
                        textDecoration: "underline",
                      }}
                    >
                      COURSE MATERIALS
                    </Text>

                    <Text
                      style={{
                        fontSize: 7.25,
                        marginBottom: 2,
                      }}
                    >
                      Please collect Course materials & lab manuals for the
                      respective course joined.
                    </Text>
                  </View>

                  <Text
                    style={{
                      fontSize: 9,
                      fontWeight: "demibold",
                    }}
                  >
                    If students are failed to pay the balance fee within the due
                    date, 10% late payment charges apply.
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Card>
  );
};

// End Overview Tab Content

const AdmissionDetailsTab = () => {
  const loaderData = useLoaderData();

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          title="Student Details"
          headStyle={{
            padding: "0 16px",
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          {/* <pre>{JSON.stringify(loaderData, null, 4)}</pre> */}
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Student Name">
              {loaderData?.student_detail?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Birth Date">
              {loaderData?.student_detail?.dob ?? "N/A"}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Gender">Male</Descriptions.Item> */}
            <Descriptions.Item label="ID Card No.">
              {loaderData?.idcard ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Division">
              {loaderData?.division?.name ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Medium">
              {loaderData?.medium?.name ?? "N/A"}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Mobile No.">
              Static 7347010131
            </Descriptions.Item>
            <Descriptions.Item label="Email ID">Static Sd</Descriptions.Item> */}
            <Descriptions.Item label="Admission Taken By">
              {loaderData?.counselor?.name}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={12}>
        <Card
          title="Fee Package Details"
          headStyle={{
            padding: "0 16px",
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Batch Start Date">
              {dayjs(loaderData?.start_date, "YYYY-MM-DD hh:mm:ss").format(
                "YYYY-MM-DD"
              ) ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Batch End Date">
              {dayjs(
                loaderData?.expected_end_date,
                "YYYY-MM-DD hh:mm:ss"
              ).format("YYYY-MM-DD") ?? "N/A"}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={12}>
        <Card
          title="Parents Details"
          headStyle={{
            padding: "0 16px",
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Father Name">
              {loaderData?.get_parent?.parent_details?.fathersName ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Mother Name">
              {loaderData?.get_parent?.parent_details?.mothersName ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Primary Mobile No.">
              {loaderData?.get_parent?.parent_details?.primary_phone ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Secondary Mobile No.">
              {loaderData?.get_parent?.parent_details?.secondary_phone ?? "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Email ID">
              {loaderData?.get_parent?.parent_details?.email ?? "N/A"}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={12}>
        {/* <pre>
          {JSON.stringify(
            groupBy(loaderData.admission_seating, (item) => {
              return item.fee_group;
            }),
            null,
            3
          )}
        </pre> */}
        <Card
          title="Standard Details"
          headStyle={{
            padding: "0 16px",
          }}
          bodyStyle={{
            padding: "0",
          }}
        >
          <Row>
            <Col
              span={12}
              style={{
                padding: "8px 16px",
              }}
            >
              <Typography.Text strong style={{ fontSize: 14 }}>
                Fee Group
              </Typography.Text>
            </Col>
            <Col
              span={12}
              style={{
                padding: "8px 16px",
              }}
            >
              <Typography.Text strong style={{ fontSize: 14 }}>
                Subject Name
              </Typography.Text>
            </Col>
          </Row>

          {loaderData.admission_seating.map((value) => (
            <Descriptions
              bordered
              size="small"
              column={1}
              prefixCls="auxow"
              labelStyle={{
                background: "transparent",
              }}
            >
              <Descriptions.Item label={value.fee_group}>
                {value.name}
              </Descriptions.Item>
            </Descriptions>
          ))}
        </Card>
      </Col>

      <Col span={12}>
        <Card
          title="Education Details"
          headStyle={{
            padding: "0 16px",
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <Table
            columns={[
              {
                key: "education_id",
                title: "Education ID",
                render: (_) => <Text>{_?.education_id}</Text>,
              },
              {
                key: "name",
                title: "Name",
                render: (_) => <Text>{_?.name.name}</Text>,
              },
              {
                key: "year",
                title: "Academic Year",
                render: (_) => <Text>{_?.year}</Text>,
              },
            ]}
            dataSource={loaderData?.student_info?.education}
          />
        </Card>
      </Col>
    </Row>
  );
};

const ReceiptTab = () => {
  const params = useParams();
  const [receipts, setReceipts] = useState([]);

  const loadReceipt = () => {
    getReceiptByAdmissionId(params.id).then((res) => {
      setReceipts(res.data.data);
    });
  };

  useEffect(() => {
    loadReceipt();
  }, []);
  return (
    <>
      <Table
        columns={[
          {
            key: "receipt_date",
            dataIndex: "receipt_date",
            title: "Receipt Date",
          },
          {
            key: "receipt_number",
            dataIndex: "receipt_number",
            title: "Receipt No",
          },
          {
            key: "student_name",
            title: "Student Name",
            render: (_) => (
              <Typography.Text>{_.student_info.name}</Typography.Text>
            ),
          },
          {
            key: "payment_mode",
            dataIndex: "payment_mode",
            title: "Payment Mode",
          },
          {
            key: "amount",
            title: "Amount",
            render: (_) => {
              return (
                <Typography.Text>
                  {reduce(
                    _.payment_detail,
                    (amount, n) => {
                      return amount + Number(n.paid_amount);
                    },
                    0
                  )}
                </Typography.Text>
              );
            },
          },
          {
            key: "received_by",
            dataIndex: "received_by",
            title: "Received By",
          },
          {
            key: "action",
            title: "Action",
            render: (_, record) => {
              return (
                <Space>
                  <Tooltip title="View">
                    <Button icon={<MdOutlineVisibility />} />
                  </Tooltip>
                </Space>
              );
            },
          },
        ]}
        dataSource={receipts.data}
        summary={(pageData) => {
          const totalAmount = reduce(
            pageData,
            (total, paymentObj) => {
              return total + sumBy(paymentObj.payment_detail, "paid_amount");
            },
            0
          );

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>{totalAmount}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} />
                <Table.Summary.Cell index={6} />
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

const InvoiceTab = () => {
  const params = useParams();
  const [invoices, setInvoices] = useState([]);

  const handleGetInvoices = () => {
    getInvoices({ admission_id: params.id })
      .then((res) => {
        setInvoices(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleGetInvoices();
  }, []);

  return (
    <>
      <Table
        scroll={{
          x: true,
        }}
        columns={[
          {
            key: "invoice_no",
            title: <ColTitle>Invoice No</ColTitle>,
            render: (_) => <ColValue>{_?.id}</ColValue>,
          },
          {
            key: "invoice_number",
            title: <ColTitle>Invoice Number</ColTitle>,
            render: (_) => <ColValue>{_?.invoice_number}</ColValue>,
          },
          {
            key: "invoice_due_date",
            title: <ColTitle>Invoice Due Date</ColTitle>,
            dataIndex: "due_date",
          },
          {
            key: "total_amount",
            title: "Total Amount",
            dataIndex: "total_amount",
          },
          {
            key: "received_amount",
            title: "Received Amount",
            dataIndex: "received_amount",
          },
          {
            key: "receivable_amount",
            title: "Due Amount",
            dataIndex: "receivable_amount",
          },
          {
            key: "admission_remarks",
            title: <ColTitle>Admission Remarks</ColTitle>,
            render: (_) => <ColValue>{_?.admission_remarks}</ColValue>,
          },
        ]}
        dataSource={invoices.data}
      />
    </>
  );
};

export default function AdmissionByIDPage({ id }) {
  const loaderData = useLoaderData();

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Admission</Title>
      </Row>

      <Card title={loaderData?.student_ids?.name}>
        <Tabs
          items={[
            {
              key: "overview",
              label: "Overview",
              children: <OverviewTab />,
              disabled: loaderData?.invoice_details?.length > 0 ? false : true,
            },
            {
              key: "admission_details",
              label: "Admission Details",
              children: <AdmissionDetailsTab />,
            },
            {
              key: "receipt",
              label: "Receipt",
              children: <ReceiptTab />,
            },
            {
              key: "invoice",
              label: "Invoice",
              children: <InvoiceTab />,
            },
            // {
            //   key: "sms_sent_report",
            //   label: "SMS Sent Report",
            //   children: <SentSMSReportTab />,
            // },
            // {
            //   key: "email_sent_report",
            //   label: "Email Sent Report",
            //   children: <EmailSentReportTab />,
            // },
            // {
            //   key: "progress_report_card",
            //   label: "Progress Report Card",
            //   children: <ProgressReportCardTab />,
            // },
            // {
            //   key: "student_attendance",
            //   label: "Student Attendance",
            //   children: <StudentAttendanceTab />,
            // },
            // {
            //   key: "time_table",
            //   label: "Time Table",
            //   disabled: true,
            // },
            // {
            //   key: "accounting_journal",
            //   label: "Accounting Journal",
            //   children: <AccountingJournalTab />,
            // },
            // {
            //   key: "recent_activities",
            //   label: "Recent Activites",
            //   children: <RecentActivitiesTab />,
            // },
            // {
            //   key: "other_certificates",
            //   label: "Other Ceritificates",
            //   children: <OtherCertificatesTab />,
            // },
          ]}
          defaultActiveKey={
            loaderData?.invoice_details?.length > 0
              ? "overview"
              : "admission_details"
          }
        />
      </Card>
    </LayoutContainer>
  );
}
