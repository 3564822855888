import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import {
  createReceipt,
  getPendingInvoicesByStudentId,
} from "../../../api/Receipt";
import { reduce } from "lodash";
import { getBranches } from "../../../api/Branch";
import useID from "../../../hooks/useID.hook";
import { useNavigate } from "react-router-dom";
import { getCounsellorsByBranchId } from "../../../api/Counsellor";
import { studentByAdmission } from "../../../api/Admission";

export default function AddReceiptPage() {
  const id = useID();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const formValues = Form.useWatch([], form);
  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [branches, setBranches] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [counselors, setCounselors] = useState([]);

  const calculateTotalAmount = (invoices) => {
    const totalAmount = reduce(
      invoices,
      (invoice, n) => {
        if (n.due_amount === null) {
          return Number(n.amount) + invoice;
        }
        return Number(n.due_amount) + invoice;
      },
      0
    );

    setTotalAmount(totalAmount);
  };

  // const loadStudents = () => {
  //   getLeads({ pagination: false }, id.branch_id, id.counsellor_id)
  //     .then((res) => setStudents(res.data.data))
  //     .catch((err) => console.log(err));
  // };

  const loadInvoiceByStudentID = (studentId) => {
    getPendingInvoicesByStudentId(studentId, { pagination: false })
      .then((res) => {
        setInvoices(res.data.data);
        calculateTotalAmount(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadBranches = () => {
    getBranches({ pagination: false })
      .then((res) => setBranches(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleReceiveAmount = (value) => {
    let amount = value;

    for (let invoice of invoices) {
      if (invoice.due_amount === null) {
        if (invoice.amount >= amount) {
          form.setFieldValue(`payment[${invoice.id}]`, amount > 0 ? amount : 0);
          amount = amount - invoice.amount;
        } else {
          amount = amount - invoice.amount;
          form.setFieldValue(`payment[${invoice.id}]`, invoice.amount);
        }
      } else {
        if (invoice.due_amount >= amount) {
          form.setFieldValue(`payment[${invoice.id}]`, amount > 0 ? amount : 0);
          amount = amount - invoice.due_amount;
        } else {
          amount = amount - invoice.due_amount;
          form.setFieldValue(`payment[${invoice.id}]`, invoice.due_amount);
        }
      }
    }
  };

  const loadCounselors = () => {
    getCounsellorsByBranchId({ pagination: false }, id.branch_id)
      .then((res) => setCounselors(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadStudentByAdmission = (branchId, counselorId) => {
    studentByAdmission(
      {},
      branchId ?? id.branch_id,
      counselorId ?? formValues?.counselor_id
    )
      .then((res) => {
        setStudents(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const transformPayload = (oldPayload) => {
    const newPayload = { ...oldPayload };
    const paymentObj = {};

    for (const key in oldPayload) {
      if (key.startsWith("payment[") && key.endsWith("]")) {
        const id = parseInt(key.match(/\[(\d+)\]/)[1]);
        paymentObj[id] = oldPayload[key];
        delete newPayload[key];
      }
    }

    newPayload.payment = Object.entries(paymentObj).map(([id, amount]) => ({
      id: parseInt(id),
      amount: amount,
    }));

    return newPayload;
  };

  const handleCreateReceipt = (values) => {
    const newPayload = transformPayload(values);
    createReceipt({
      branch_id: values.branch_id ?? id.branch_id,
      ...newPayload,
    }).then((res) => navigate("/receipt"));
  };

  useEffect(() => {
    if (id.branch_id !== "all") {
      form.setFieldValue("branch_id", id.branch_id);
      loadCounselors(id.branch_id);
    } else {
      form.setFieldValue("branch_id", null);
      form.setFieldValue("counselor_id", null);
      setCounselors([]);
    }
  }, [id.branch_id]);

  useEffect(() => {
    // loadStudents();
    loadBranches();
  }, []);

  return (
    <LayoutContainer>
      <Row>
        <Typography.Title style={{ marginTop: 0 }}>
          Add Receipt
        </Typography.Title>
      </Row>

      <Card>
        <Form form={form} layout="vertical">
          {id.branch_id === "all" && (
            <Form.Item name="branch_id" label="Branch">
              <Select
                onChange={(value) => {
                  form.setFieldValue("branch_id", value);
                  form.setFieldValue("counselor_id", null);
                  loadCounselors(value);
                }}
                placeholder="Please select branch"
              >
                {branches.map((branch) => (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="counselor_id"
            label="Counselor"
            rules={[
              {
                required: true,
                message: "Please select counselor",
              },
            ]}
          >
            <Select
              onChange={(value) => {
                loadStudentByAdmission(id.branch_id, value);
                form.setFieldValue("counselor_id", value);
              }}
              placeholder="Please select counselor"
            >
              {counselors.map((counselor) => (
                <Select.Option value={counselor.id}>
                  {counselor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="admission_id"
            label="Student"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: "Please select student",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select student"
              onChange={(id) => {
                setStudentId(id);
                loadInvoiceByStudentID(id);
                form.setFieldValue("received_amount", null);
              }}
              style={{ marginBottom: 0 }}
            >
              {students?.map((item) => (
                <Select.Option value={item.admission_id}>
                  {item.student} | {item.mobile}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Card>

      {studentId && (
        <Card>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateReceipt}
            scrollToFirstError
          >
            {/* <Form.Item
            label="Student"
            name="student_id"
            rules={[
              {
                required: true,
                message: "Please select student",
              },
            ]}
          >
            <Select
              placeholder="Please select student"
              onChange={(v) => {
                loadInvoiceByStudentID(v);
                setStudentId(v);
              }}
            >
              {students?.map((student) => (
                <Select.Option value={student.enquiry_id}>
                  {student.name} | {student.enquiry_id} | {student?.mobile}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

            <Form.Item required name="receipt_date" label="Receipt Date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Student Fees Status"
              style={{ paddingBottom: 0, marginBottom: 10 }}
            >
              <Typography.Text strong style={{ fontSize: 16, color: "red" }}>
                {totalAmount}
              </Typography.Text>
            </Form.Item>

            <Form.Item
              name="received_amount"
              label="Received Amount"
              rules={[
                {
                  required: true,
                  message: "Please enter received amount",
                },
                {
                  type: "number",
                  max: reduce(
                    invoices,
                    (invoice, n) => {
                      if (n.due_amount === null) {
                        return Number(n.amount) + invoice;
                      }
                      return Number(n.due_amount) + invoice;
                    },
                    0
                  ),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                onChange={handleReceiveAmount}
                placeholder="Please enter received amount"
              />
            </Form.Item>

            <Form.Item
              name="payment_mode"
              label="Payment Mode"
              rules={[
                {
                  required: true,
                  message: "Please select payment mode",
                },
              ]}
            >
              <Select placeholder="Please select received in">
                <Select.Option value="cash">Cash</Select.Option>
                <Select.Option value="cheque">Cheque</Select.Option>
                <Select.Option value="bank_transfer">
                  Bank Transfer
                </Select.Option>
                <Select.Option value="NEFT/RTGS/IMPS/NB">
                  NEFT/RTGS/IMPS/NB
                </Select.Option>
                <Select.Option value="2206">2206</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="transaction_status"
              label="Transaction/Cheque Status"
              rules={[
                {
                  required: true,
                  message: "Please select transaction/cheque status",
                },
              ]}
            >
              <Select placeholder="Select transaction/cheque status">
                <Select.Option value="cleared">Cleared</Select.Option>
                <Select.Option value="processing">Processing</Select.Option>
                <Select.Option value="deposited">Deposited</Select.Option>
                <Select.Option value="pdc">PDC</Select.Option>
              </Select>
            </Form.Item>

            {/* <Form.Item
              label="Transaction Date"
              name="transaction_date"
              rules={[
                {
                  required: true,
                  message: "Please enter transaction date",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Please select transaction date"
              />
            </Form.Item> */}

            {/* <Form.Item
              name="deposit_to"
              label="Deposit To"
              rules={[
                {
                  required: true,
                  message: "Please select deposit to",
                },
              ]}
            >
              <Select placeholder="Select account name">
                <Select.OptGroup label="Bank">
                  <Select.Option value="ac-231">AC-231</Select.Option>
                </Select.OptGroup>
                <Select.OptGroup label="Cash">
                  <Select.Option value="petty_cash">Petty Cash</Select.Option>
                  <Select.Option value="undeposited_fund">
                    Undeposited Funds
                  </Select.Option>
                </Select.OptGroup>
              </Select>
            </Form.Item> */}

            {/* <Form.Item
              label="Transaction Number"
              name="transaction_number"
              rules={[
                {
                  required: true,
                  message: "Please enter transaction number",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Enter transaction number"
              />
            </Form.Item> */}

            {id.branch_id === "all" && (
              <Form.Item
                name="branch_id"
                label="Branch"
                rules={[
                  {
                    required: true,
                    message: "Please select branch",
                  },
                ]}
              >
                <Select placeholder="Please select branch">
                  {branches.map((branch) => (
                    <Select.Option value={branch.id}>
                      {branch.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {/* <Form.Item
              name="received_by"
              label="Received By"
              rules={[
                {
                  required: true,
                  message: "Please select counselor",
                },
              ]}
            >
              <Select placeholder="Please select counselor">
                {counselors.map((counselor) => (
                  <Select.Option value={counselor.id}>
                    {counselor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

            <Form.Item
              name="receipt_remarks"
              label="Receipt Remarks"
              rules={[
                {
                  required: true,
                  message: "Please enter receipt remarks",
                },
              ]}
            >
              <Input.TextArea placeholder="Please enter receipt remarks" />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              Save
            </Button>

            <Form.List initialValue={invoices}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {/* {fields.map((field) => ( */}
                    <Table
                      style={{ marginTop: 20 }}
                      columns={[
                        // {
                        //   title: "#",
                        //   render: (_) => {
                        //     return (
                        //       <Form.Item
                        //         name={`is_checked[${_.id}]`}
                        //         valuePropName="checked"
                        //         style={{ marginBottom: 0 }}
                        //       >
                        //         <Checkbox
                        //           onChange={(v) =>
                        //             form.setFieldValue(
                        //               `is_checked[${_.id}]`,
                        //               v.target.checked
                        //             )
                        //           }
                        //         />
                        //       </Form.Item>
                        //     );
                        //   },
                        // },
                        {
                          title: "Id.",
                          dataIndex: "id",
                          key: "id",
                        },
                        {
                          title: "Invoice",
                          dataIndex: "invoice_number",
                          key: "invoice",
                        },
                        {
                          title: "Invoice Amount",
                          key: "invoice_amount",
                          dataIndex: "amount",
                        },
                        {
                          title: "Due Date",
                          dataIndex: "due_date",
                          key: "due_date",
                        },
                        {
                          title: "Due Amount",
                          dataIndex: "due_amount",
                          key: "due_amount",
                        },
                        {
                          title: "Payment",
                          key: "payment",
                          render: (_) => {
                            return (
                              <Form.Item
                                name={`payment[${_.id}]`}
                                initialValue={0}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  placeholder="Please enter payment"
                                />
                              </Form.Item>
                            );
                          },
                        },
                      ]}
                      dataSource={invoices}
                    />
                    {/* ))} */}
                  </div>
                );
              }}
            </Form.List>
          </Form>

          {/* <pre>{JSON.stringify(formValues, null, 3)}</pre> */}
          {/* <pre>{JSON.stringify(invoices, null, 3)}</pre> */}

          {/* {studentId && (
          <Form form={form} onFinish={(values) => console.log(values)}>
            <Table
              style={{ marginTop: 20 }}
              columns={[
                {
                  title: "Id.",
                  dataIndex: "id",
                  key: "id",
                },
                {
                  title: "Invoice",
                  dataIndex: "invoice_number",
                  key: "invoice",
                },
                {
                  title: "Details",
                  dataIndex: "details",
                  key: "details",
                },
                {
                  title: "Invoice Amount",
                  key: "invoice_amount",
                  dataIndex: "amount",
                },
                {
                  title: "Due Date",
                  dataIndex: "due_date",
                  key: "due_date",
                },
                {
                  title: "Due Amount",
                  dataIndex: "due_amount",
                  key: "due_amount",
                },
                {
                  title: "Payment",
                  key: "payment",
                  render: (_) => {
                    return (
                      <Form.Item
                        name={`payment_${_.id}`}
                        style={{ marginBottom: 0 }}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Please enter payment"
                        />
                      </Form.Item>
                    );
                  },
                },
              ]}
              dataSource={invoices}
            />
          </Form>
        )} */}
        </Card>
      )}
    </LayoutContainer>
  );
}
