import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider, message } from "antd";
import Router from "./routes/Router";
import { BASE_URL, PRIMARY_COLOR } from "./constants";
import axios from "axios";
import "./index.css";

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("t")}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // TODO: Handle 401 Error when token expire

    if (error.response.status === 401) {
      localStorage.clear();

      message.error(error.response.data.message);

      if (window.location.pathname !== "/signin")
        window.location.href = "/signin";
    }

    if (error.status.response.status) {
      axios.post(`${BASE_URL}/refresh`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("t")}`,
        },
      });
    }

    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: PRIMARY_COLOR,
      },
    }}
  >
    <Router />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
