import { Button, DatePicker, Form, Input, Row, Select, Transfer } from "antd";
import { getLeadSources } from "../../../api/LeadSource";
import { useEffect, useState } from "react";
import { getLeadStage } from "../../../api/LeadStage";
import { getCounsellorsByBranchId } from "../../../api/Counsellor";
import { getLeadTypes } from "../../../api/LeadType";
import dayjs from "dayjs";
import { getFeePackages } from "../../../api/FeePackage";
import { useBranches } from "../../../hooks/useBranches.hook";
import useAuth from "../../../hooks/useAuth.hook";
import useID from "../../../hooks/useID.hook";
import { getBranchById, getBranches } from "../../../api/Branch";

export default function StepThree({
  form,
  currentFormStep,
  setCurrentFormStep,
}) {
  const id = useID();
  const formValues = Form.useWatch([], form);
  const [feePackages, setFeePackages] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leadStages, setLeadStages] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadTypes, setLeadTypes] = useState([]);
  const [branches, setBranches] = useState([]);

  const loadBranches = () => {
    getBranches({ pagination: false }).then((res) => {
      setBranches(res.data.data);
    });
  };

  const { user } = useAuth();
  // console.log(user);

  const loadFeePackages = () => {
    getFeePackages({ pagination: false }).then((res) => {
      setFeePackages(res.data.data);
    });
  };

  const loadLeadSources = () => {
    getLeadSources({ pagination: false }).then((res) =>
      setLeadSources(res.data.data)
    );
  };

  const loadLeadStages = () => {
    getLeadStage({ pagination: false }).then((res) =>
      setLeadStages(res.data.data)
    );
  };

  const loadLeadTypes = () => {
    getLeadTypes({ pagination: false }).then((res) =>
      setLeadTypes(res.data.data)
    );
  };

  const loadCounsellors = () => {
    getCounsellorsByBranchId({ pagination: false }, id.branch_id).then((res) =>
      setEmployees(res.data.data)
    );
  };

  useEffect(() => {
    loadCounsellors();

    // form.
  }, [id.branch_id]);

  useEffect(() => {
    loadBranches();
    loadFeePackages();
    loadLeadStages();
    loadLeadSources();
    loadLeadTypes();
  }, []);

  return (
    <>
      <Form.Item
        name="enquiry_date"
        label="Enquiry Date"
        rules={[
          {
            required: true,
            message: "Enquiry date is required",
          },
        ]}
        initialValue={dayjs()}
      >
        <DatePicker
          style={{ width: "100%" }}
          defaultValue={dayjs()}
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
        />
      </Form.Item>

      {user?.user_type === "admin" && id.branch_id === "all" && (
        <Form.Item
          name="branch_id"
          label="Branch"
          initialValue={id.branch_id === "all" ? null : id.branch_id}
          rules={[
            {
              required: true,
              message: "Please select Branch",
            },
          ]}
        >
          <Select
            showSearch
            // loading={loadingBranches}
            optionFilterProp="children"
            placeholder="Please select Branch"
            style={{ width: "100%" }}
            onChange={(v) => loadCounsellors(v)}
          >
            {branches.map((branch) => (
              <Select.Option value={branch.id}> {branch.name} </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="counselor_id"
        label="Enquiry Taken By"
        rules={[
          {
            required: true,
            message: "Please select counsellor",
          },
        ]}
      >
        <Select>
          {employees.map((employee) => (
            <Select.Option value={employee.id}>{employee.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="fee_group_id"
        label="Fee Package"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select>
          {feePackages?.map((feePackage) => (
            <Select.Option value={feePackage?.id}>
              {feePackage?.group_name?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="lead_source"
        label="Lead Source"
        rules={[
          {
            required: true,
            message: "Lead source is required",
          },
        ]}
      >
        <Select>
          {leadSources?.map((v) => (
            <Select.Option value={v.id}>{v.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="lead_stage"
        label="Lead Stage"
        rules={[
          {
            required: true,
            message: "Lead stage is required",
          },
        ]}
      >
        <Select>
          {leadStages?.map((v) => (
            <Select.Option value={v.id}>{v.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* <Form.Item
        name="lead_type"
        label="Lead Type"
        rules={[
          {
            required: true,
            message: "Lead type is required",
          },
        ]}
      >
        <Select>
          {leadTypes.map((leadType) => (
            <Select.Option value={leadType.id}>{leadType.name}</Select.Option>
          ))}
        </Select>
      </Form.Item> */}

      <Form.Item
        name="is_follow"
        label="Is FollowUp"
        rules={[
          {
            required: true,
            message: "Is follow is required",
          },
        ]}
      >
        <Select>
          <Select.Option value="1">Yes</Select.Option>
          <Select.Option value="0">No</Select.Option>
        </Select>
      </Form.Item>

      {formValues?.is_follow === "1" && (
        <Form.Item
          name="follow_date"
          label="Follow Date"
          rules={[
            {
              required: true,
              message: "Follow date is required",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      )}

      <Form.Item
        name="remarks"
        label="Remarks"
        rules={[
          {
            required: formValues?.is_follow === "1" ? true : false,
            message: "Remarks is required",
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          htmlType="button"
          disabled={currentFormStep === 4}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </>
  );
}
