import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import { getFeePackages } from "../../../../api/FeePackage";
import { getFeeGroup } from "../../../../api/FeeGroup";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { getFee } from "../../../../api/Fee";

export default function FeesPackagePage() {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [fees, setFees] = useState([]);
  const [loadingFeePackage, setLoadingFeePackage] = useState(false);
  const [feePackages, setFeePackages] = useState([]);
  const [feeGroup, setFeeGroup] = useState([]);

  const loadFeesPackages = (params = {}) => {
    setLoadingFeePackage(true);
    getFeePackages(params)
      .then((res) => setFeePackages(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingFeePackage(false));
  };

  const loadFeesGroup = () => {
    getFeeGroup({ pagination: false })
      .then((res) => setFeeGroup(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadFee = () => {
    getFee({ pagination: false })
      .then((res) => setFees(res.data.data))
      .catch((err) => console.log(err));
  };

  const deleteFeePackage = (id) => {
    deleteFeePackage(id)
      .then((res) => loadFeesPackages())
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (_) => {
        return _?.fee?.name ?? "N/A";
      },
    },
    {
      title: "Fee Group",
      key: "feeGroup",
      render: (_) => {
        return _?.group_name?.name ?? "N/A";
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (_, v) => {
        return (
          <Button danger onClick={() => deleteFeePackage(_.id)}>
            Delete
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    loadFeesPackages();
    loadFeesGroup();
    loadFee();
  }, []);
  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Fee Package
        </Typography.Title>

        <Space>
          <Button onClick={() => setOpen(true)}>Add Fee Package</Button>
        </Space>
      </Row>

      <Table
        columns={columns}
        dataSource={feePackages.data}
        loading={loadingFeePackage}
        pagination={{
          position: ["bottomCenter"],
          pageSize: feePackages.per_page,
          total: feePackages.total,
          onChange: (page, pageSize) => {
            loadFeesPackages({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        title="Add Fee Package"
        onCancel={() => setOpen(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="feeGroup"
            label="Fee Group"
            rules={[
              {
                required: true,
                message: "Please select fee group",
              },
            ]}
          >
            <Select placeholder="Please select fee group">
              {feeGroup.map((feeGroup) => (
                <Select.Option value={feeGroup.id}>
                  {feeGroup.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name="fees">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restfields }) => (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Fee"
                        name={[name, "fee"]}
                        rules={[
                          {
                            required: true,
                            message: "Fee is required",
                          },
                        ]}
                      >
                        <Select placeholder="Please select fee">
                          {fees?.map((fee) => (
                            <Select.Option key={fee.id} value={fee.id}>
                              {fee.name}
                            </Select.Option>
                          ))}
                        </Select>
                        {/* <InputNumber
                          placeholder="Please enter fee"
                          style={{ width: "100%" }}
                        /> */}
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Space>
                        <Form.Item
                          label="Amount"
                          name={[name, "amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Amount is required",
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Please enter amount"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>

                        <Button
                          size="small"
                          icon={<MinusOutlined />}
                          onClick={() => remove(name)}
                        />
                      </Space>
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button
                    block
                    type="dashed"
                    onClick={add}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
