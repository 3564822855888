import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  message,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { createCounseller } from "../../../api/Counsellor";
import { useNavigate } from "react-router-dom";
import useID from "../../../hooks/useID.hook";
import useAuth from "../../../hooks/useAuth.hook";
import { useEffect, useState } from "react";
import { getBranches } from "../../../api/Branch";

const { Title } = Typography;

export default function CreateEmployeePage() {
  const id = useID();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);

  const handleFormSubmit = async (values) => {
    try {
      const res = await createCounseller({
        branch_id: id.branch_id,
        "role[0]": "counsellor",
        ...values,
      })
        .then((res) => navigate("/settings/counsellers/"))
        .catch((error) => {
          console.log(error.response);
        });
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };

  const loadBranches = () => {
    getBranches({ pagination: false }).then((res) =>
      setBranches(res.data.data)
    );
  };

  useEffect(() => {
    loadBranches();
  }, []);

  return (
    <LayoutContainer>
      <Title style={{ marginTop: 0 }}>Add Counsellor</Title>
      <Card>
        <Form onFinish={handleFormSubmit} layout="vertical">
          <Form.Item
            name="name"
            label="Counsellor name"
            rules={[
              {
                required: true,
                message: "Please enter counsellor name",
              },
            ]}
          >
            <Input placeholder="Please enter counsellor name" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Mobile No"
            rules={[
              {
                required: true,
                message: "Please enter mobile number",
              },
            ]}
          >
            <Input type="number" inputMode="numeric" addonBefore="+91" />
          </Form.Item>

          {user.user_type === "admin" && (
            <Form.Item
              name="branch_id"
              label="Branch"
              rules={[
                {
                  required: true,
                  message: "Please select branch",
                },
              ]}
            >
              <Select placeholder="Please select branch">
                {branches.map((branch) => (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter email address",
              },
              {
                type: "email",
                message: "Please enter email address",
              },
            ]}
          >
            <Input placeholder="Please enter email address" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            placeholder="Please enter password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input.Password placeholder="Please enter password" />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            placeholder="Please enter password"
            rules={[
              {
                required: true,
                message: "Please enter confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The confirm password that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Please enter password" />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter address",
              },
            ]}
          >
            <Input placeholder="Please enter address" />
          </Form.Item>

          <Form.Item
            name="zipcode"
            label="Zip Code"
            rules={[
              {
                required: true,
                message: "Please enter zip code",
              },
            ]}
          >
            <InputNumber
              controls={false}
              placeholder="Please enter zip code"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Create
          </Button>
        </Form>
      </Card>
    </LayoutContainer>
  );
}
