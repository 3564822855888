import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.hook";

export default function IsAuthenticated({ children, role = [] }) {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  if (isLoggedIn) {
    if (role.length === 0) {
      return children;
    }

    if (role.length > 0) {
      if (role.includes(user.user_type)) {
        return children;
      } else {
        return <Navigate to="/" />;
      }
    }
  }

  return <Navigate to="/signin" />;
}
