import {
  Avatar,
  Button,
  Form,
  Image,
  Layout,
  Menu,
  Select,
  Space,
  Typography,
} from "antd";
import useAuth from "../../hooks/useAuth.hook";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBranches } from "../../api/Branch";
import { useBranches } from "../../hooks/useBranches.hook";
import { upperCase } from "lodash";

const { Content, Sider, Header } = Layout;
const { Text } = Typography;

export default function LayoutContainer({ children }) {
  let location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, user, signout } = useAuth();
  const [current, setCurrent] = useState(location.pathname);
  // const { setSiteConfig } = useSiteConfig((state) => state);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const { branches, setBranches, selectedBranch, setSelectedBranch } =
    useBranches((state) => state);

  const loadBranches = () => {
    setLoadingBranches(true);
    getBranches({ pagination: false })
      .then((res) => setBranches(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingBranches(false));
  };

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }

    if (isLoggedIn && branches >= 0) loadBranches();
  }, [location, current, isLoggedIn, branches]);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#FFFFFF",
          borderBottom: "1px solid #EEEEEE",
          alignItems: "center",
        }}
        className="header"
      >
        <div className="logo">
          <Image
            width={110}
            preview={false}
            src="https://flyingfeathers.com/wp-content/uploads/2021/07/cropped-215x80-1.png"
          />
        </div>

        {isLoggedIn ? (
          <Space align="center" size="large">
            {user.user_type === "admin" && (
              <Form>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  initialValue={selectedBranch?.selectedBranch ?? "all"}
                >
                  <Select
                    showSearch
                    loading={loadingBranches}
                    optionFilterProp="children"
                    placeholder="Please select branch"
                    style={{ width: 180 }}
                    defaultValue={selectedBranch?.selectedBranch ?? "all"}
                    onChange={(value) =>
                      setSelectedBranch({ selectedBranch: value })
                    }
                  >
                    <Select.Option value="all">All</Select.Option>
                    {branches.map((branch) => (
                      <Select.Option value={branch.id}>
                        {branch.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            )}

            <Space>
              <Avatar>{upperCase(user.name[0])}</Avatar>
              <Text>{user.name}</Text>
            </Space>

            <Button onClick={signout} type="default">
              Logout
            </Button>
          </Space>
        ) : (
          <Button>Sign In</Button>
        )}
      </Header>
      <Layout>
        {isLoggedIn && (
          <Sider width={200}>
            <Menu
              mode="inline"
              activeKey="lead"
              defaultSelectedKeys={[current]}
              style={{
                height: "100%",
                borderRight: 0,
              }}
              items={[
                {
                  label: "Your account",
                  title: "Your Account",
                  disabled: true,
                },
                {
                  key: "/",
                  label: "Dashboard",
                  onClick: () => navigate("/"),
                },

                {
                  key: "/leads",
                  label: "Leads",
                  onClick: () => navigate("/leads"),
                },
                {
                  key: "/followup",
                  label: "Followup",
                  onClick: () => navigate("/followup"),
                },
                {
                  key: "/admission",
                  label: "Admission",
                  onClick: () => navigate("/admission"),
                },
                // {
                //   key: "/examination",
                //   label: "Examination",
                //   disabled: true,
                // },
                // {
                //   key: "/payroll",
                //   label: "Payroll",
                //   disabled: true,
                // },
                // {
                //   key: "/report",
                //   label: "Report",
                //   disabled: true,
                // },
                {
                  key: "/invoice",
                  label: "Invoice",
                  onClick: () => navigate("/invoice"),
                },
                {
                  key: "/receipt",
                  label: "Receipt",
                  onClick: () => navigate("/receipt"),
                },
                user.user_type === "admin" && {
                  key: "/settings",
                  label: "Settings",
                  onClick: () => navigate("/settings"),
                },
                user.user_type === "admin" && {
                  key: "/masters",
                  label: "Master",
                  onClick: () => navigate("/masters"),
                },
              ]}
            />
          </Sider>
        )}
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              overflowY: "auto",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
