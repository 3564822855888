import axios from "axios";
import { BASE_URL } from "../../constants";

export function getAdmissionStatus(params) {
  return axios.get(`${BASE_URL}/admission_status`, {
    params,
  });
}

export function createAdmissionStatus(values) {
  return axios.post(`${BASE_URL}/admission_status`, values);
}

export function getAdmissionStatusById(id) {
  return axios.get(`${BASE_URL}/admission_status/${id}`);
}

export function updateAdmissionStatus(id, values) {
  return axios.put(`${BASE_URL}/admission_status/${id}`, values);
}

export function deleteAdmissionStatus(id) {
  return axios.delete(`${BASE_URL}/admission_status/${id}`);
}
