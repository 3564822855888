import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../../components/layout/LayoutContainer";
import { getFeeGroupById } from "../../../../api/FeeGroup";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFee } from "../../../../api/Fee";
import {
  getSubjectByFeeGroupId,
  getSubjects,
  subjectAssign,
} from "../../../../api/Subjects";

export default function SubjectsByFeeGroupId() {
  const params = useParams();
  const [form] = Form.useForm();
  const [fees, setFees] = useState([]);
  const [open, setOpen] = useState(false);
  const [feeGroup, setFeeGroup] = useState();
  const [feeGroupSubjects, setFeeGroupSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const loadSubjects = () => {
    getSubjects({ pagination: false })
      .then((res) => setSubjects(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadFeeGroupById = (id) => {
    getFeeGroupById(id).then((res) => setFeeGroup(res.data.data));
  };

  const loadFee = () => {
    getFee({ pagination: false })
      .then((res) => setFees(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadSubjectsByFeeGroupID = (id, params = {}) => {
    getSubjectByFeeGroupId(id, params)
      .then((res) => setFeeGroupSubjects(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleDelete = () => {
    const ids = [];
    selectedRows?.map((row) => ids.push(row.id));
  };

  const handleAddFeePackage = (values) => {
    const formData = new FormData();
    formData.append("fee_group_id", params?.id);
    formData.append(`name`, feeGroup?.name);
    values?.subjects?.forEach((value, index) => {
      formData.append(`subject_id[${index}]`, value);
    });

    subjectAssign(params?.id, formData)
      .then((res) => {
        loadSubjectsByFeeGroupID(params?.id);
        setOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      key: "id",
      dataIndex: "subject_id",
      title: "ID",
    },
    {
      key: "name",
      title: "Name",
      render: (_) => {
        return <span>{_?.name ?? "N/A"}</span>;
      },
    },
    {
      key: "fee_group",
      title: "Fee Group",
      render: (_) => {
        return <span>{_?.fee_group ?? "N/A"}</span>;
      },
    },
  ];

  useEffect(() => {
    loadSubjects();
  }, []);

  useEffect(() => {
    loadFeeGroupById(params?.id);
    loadSubjectsByFeeGroupID(params?.id);
    loadFee();
  }, [params]);

  return (
    <LayoutContainer>
      <Row justify="space-between" align="middle">
        <Typography.Title style={{ marginTop: 0 }}>
          Fee Group Subjects
        </Typography.Title>

        <Space>
          <Button
            disabled={selectedRows.length === 0 ? true : false}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button onClick={() => setOpen(true)}>Add</Button>
        </Space>
      </Row>

      <Table
        rowSelection={{
          type: "checkbox",
          onChange: (key, rows) => {
            setSelectedRows(rows);
          },
        }}
        columns={columns}
        dataSource={feeGroupSubjects}
        // pagination={{
        //   position: ["bottomCenter"],
        //   pageSize: feePackages.per_page,
        //   total: feePackages.total,
        //   onChange: (page, pageSize) => {
        //     loadFeePackageByGroupID(params?.id, {
        //       page,
        //     });
        //   },
        // }}
      />

      <Modal
        open={open}
        title="Add Subject"
        okText="Save"
        onOk={() => form.submit()}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddFeePackage}>
          <Form.Item name="feeGroup" label="Fee Group">
            <Input disabled readOnly defaultValue={feeGroup?.name} />
          </Form.Item>

          <Form.Item name="subjects" label="Subject">
            <Select mode="multiple" placeholder="">
              {subjects?.map((subject) => (
                <Select.Option value={subject.id}>{subject.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
