import { Button, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { getCountries } from "../../../api/Countries";
import { getStatesByCountryId } from "../../../api/States";
import { getCitiesByState } from "../../../api/Cities";

export default function StepTwo({ form, currentFormStep, setCurrentFormStep }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const loadCountries = () => {
    getCountries({ pagination: false }).then((res) =>
      setCountries(res.data.data)
    );
  };

  const loadStatesByCountry = (countryId) => {
    getStatesByCountryId({ pagination: false }, countryId).then((res) =>
      setStates(res.data.data.states)
    );
  };

  const loadCitiesByState = (stateId) => {
    getCitiesByState({ pagination: false }, stateId).then((res) =>
      setCities(res.data.data)
    );
  };

  useEffect(() => {
    loadCountries();

    form.getFieldValue("country_id") &&
      loadStatesByCountry(form.getFieldValue("country_id"));

    form.getFieldValue("state_id") &&
      loadCitiesByState(form.getFieldValue("state_id"));
  }, []);

  return (
    <>
      <Form.Item
        name="country_id"
        label="Country"
        rules={[
          {
            required: true,
            message: "Country is required",
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          onChange={(v) => {
            loadStatesByCountry(v);
            form.setFieldValue("state_id", null);
            form.setFieldValue("area", null);
          }}
        >
          {countries?.map((v) => (
            <Select.Option key={v.id} value={v.id}>
              {v.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="state_id"
        label="State"
        rules={[
          {
            required: true,
            message: "State is required",
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          onChange={(v) => {
            loadCitiesByState(v);
            form.setFieldValue("area", null);
          }}
        >
          {states.map((state) => (
            <Select.Option value={state.id}>{state.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="area"
        label="Area"
        rules={[
          {
            required: true,
            message: "Area is required",
          },
        ]}
      >
        <Select showSearch optionFilterProp="children">
          {cities?.map((v) => (
            <Select.Option value={v.id}>{v.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="address"
        label="Address"
        rules={[
          {
            required: true,
            message: "Address is required",
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        name="zipcode"
        label="ZIP Code"
        rules={[
          {
            required: false,
            message: "ZIP Code is required",
          },
        ]}
      >
        <Input type="number" inputMode="numeric" maxLength={9} />
      </Form.Item>

      <Form.Item
        name="mobile"
        label="Mobile"
        rules={[
          {
            required: true,
            message: "Mobile number is required",
          },
          {
            len: 10,
            message: "Mobile number is not valid",
          },
        ]}
      >
        <Input
          type="number"
          inputMode="numeric"
          addonBefore="+91"
          maxLength={10}
          minLength={10}
        />
      </Form.Item>

      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          htmlType="button"
          disabled={currentFormStep === 4}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </>
  );
}
