import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { getFee } from "../../../api/Fee";

const { Text } = Typography;

export default function StepFive({
  form,
  state,
  currentFormStep,
  setCurrentFormStep,
}) {
  const [fee, setFee] = useState([]);
  const formValues = Form.useWatch([], form);

  const loadFee = () => {
    getFee({ pagination: false })
      .then((res) => setFee(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadFee();
  }, []);

  return (
    <>
      <Row
        gutter={6}
        style={{
          paddingBottom: 10,
          borderBottom: "1px solid #EEEEEE",
          marginBottom: 14,
        }}
      >
        <Col span={6}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Fee Name
          </Text>
        </Col>
        <Col span={4}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Amount
          </Text>
        </Col>
        <Col span={4}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Quantity
          </Text>
        </Col>
        {/* <Col span={5}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Tax
          </Text>
        </Col> */}
        <Col span={5}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Total
          </Text>
        </Col>
      </Row>

      <Form.List name="feeDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <Row gutter={6}>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, "fee_id"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select fee type",
                        },
                      ]}
                    >
                      <Select placeholder="Please select fee type">
                        {fee.map((item) => (
                          <Select.Option value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, "amount"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter amount",
                        },
                      ]}
                    >
                      <Input type="number" placeholder="Amount" />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      initialValue={1}
                      rules={[
                        {
                          required: true,
                          message: "Please enter quantity",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        min={1}
                        type="number"
                        placeholder="Quantity"
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col span={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "tax"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select tax",
                      },
                    ]}
                  >
                    <Select placeholder="Please select tax">
                      <Select.Option></Select.Option>
                    </Select>
                  </Form.Item>
                </Col> */}

                  <Col span={4}>
                    <Input
                      disabled
                      type="number"
                      value={
                        Number(formValues?.feeDetails?.[name]?.amount) *
                        Number(formValues?.feeDetails?.[name]?.quantity)
                      }
                      placeholder="Total"
                    />
                  </Col>

                  <Col>
                    <DeleteOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              </>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add More
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        name="invoice_due_date"
        label="Invoice Due Date"
        rules={[
          {
            required: true,
            message: "Please select invoice due date",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      {/* {state === "create" && ( */}
      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          disabled={currentFormStep === 6}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
      {/* )} */}
    </>
  );
}
