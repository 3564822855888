import { Card, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";

const { Title } = Typography;

export default function MasterCard({ icon, title, link }) {
  const navigate = useNavigate();
  return (
    <Card onClick={() => navigate(link)} hoverable>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
        <Title level={5}>{title}</Title>
      </div>
    </Card>
  );
}
