import axios from "axios";
import { BASE_URL } from "../../constants";

export function getBranches(params) {
  return axios.get(`${BASE_URL}/branches`, {
    params,
  });
}

export function createBranch(values) {
  return axios.post(`${BASE_URL}/branches`, values);
}

export function getBranchById(id) {
  return axios.get(`${BASE_URL}/branches/${id}`);
}

export function updateBranch(id, values) {
  return axios.put(`${BASE_URL}/branches/${id}`, values);
}

export function deleteBranch(id) {
  return axios.delete(`${BASE_URL}/branches/${id}`);
}
