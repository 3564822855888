import { Button, Row, Space, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";

export default function AdminPage() {
  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Admin
        </Typography.Title>

        <Space>
          <Button>Add Admin</Button>
        </Space>
      </Row>

      <Table columns={[]} dataSource={[]} />
    </LayoutContainer>
  );
}
