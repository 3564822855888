import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import {
  createFeeGroup,
  deleteFeeGroup,
  getFeeGroup,
} from "../../../../api/FeeGroup";
import { useNavigate } from "react-router-dom";

export default function FeesGroupPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loadingFeeGroup, setLoadingFeesGroup] = useState(false);
  const [feesGroup, setFeesGroup] = useState([]);

  const loadFeeGroup = (params = {}) => {
    setLoadingFeesGroup(true);
    getFeeGroup(params)
      .then((res) => setFeesGroup(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingFeesGroup(false));
  };

  const handleAddFeeGroup = (values) => {
    createFeeGroup(values).then((res) => {
      setOpen(false);
      loadFeeGroup();
      form.resetFields(["name"]);
    });
  };

  const handleDeleteFeeGroup = (id) => {
    deleteFeeGroup(id)
      .then((res) => loadFeeGroup())
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_) => {
        return (
          <Space>
            <Button
              onClick={() =>
                navigate(`/masters/fees/fees-group/${_?.id}/subjects`)
              }
            >
              Subject Assign
            </Button>
            <Button
              onClick={() => navigate(`/masters/fees/fees-group/${_?.id}`)}
            >
              Fee Assign
            </Button>
            <Button danger onClick={() => handleDeleteFeeGroup(_.id)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    loadFeeGroup();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Fee Group
        </Typography.Title>

        <Space>
          <Button onClick={() => setOpen(true)}>Add Fee Group</Button>
        </Space>
      </Row>

      {/* <pre>{JSON.stringify(feesGroup, null, 5)}</pre> */}
      <Table
        columns={columns}
        dataSource={feesGroup?.data}
        loading={loadingFeeGroup}
        pagination={{
          position: ["bottomCenter"],
          pageSize: feesGroup.per_page,
          total: feesGroup.total,
          onChange: (page, pageSize) => {
            loadFeeGroup({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        title="Add Fee Group"
        onCancel={() => {
          setOpen(false);
          form.resetFields(["name"]);
        }}
        okText="Save"
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleAddFeeGroup}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Fee group is required",
              },
            ]}
          >
            <Input placeholder="Please enter fee group name" />
          </Form.Item>

          <Form.Item
            name="fee_type"
            label="Fee Type"
            rules={[
              {
                required: true,
                message: "Fee type is required",
              },
            ]}
          >
            <Select
              placeholder="Please select fee types"
              style={{ width: "100%" }}
            >
              <Select.Option value="other_fee">Other Fee</Select.Option>
              <Select.Option value="registration_fee">
                Registration Fee
              </Select.Option>
              <Select.Option value="spouse_visa">Spouse Fee</Select.Option>
              <Select.Option value="study_visa_processing_fee">
                Study Visa Processing
              </Select.Option>
              <Select.Option value="tourist_visa">
                Tourist/Visa Fee
              </Select.Option>
              <Select.Option value="tuition_fee">Tuition Fees</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
