import axios from "axios";
import { BASE_URL } from "../../constants";

export function getEducation(params) {
  return axios.get(`${BASE_URL}/education`, {
    params,
  });
}

export function createEducation(values) {
  return axios.post(`${BASE_URL}/education`, values);
}

export function getEducationById(id) {
  return axios.get(`${BASE_URL}/education/${id}`);
}

export function updateEducation(id, values) {
  return axios.put(`${BASE_URL}/education/${id}`, values);
}

export function deleteEducation(id) {
  return axios.delete(`${BASE_URL}/education/${id}`);
}
