import axios from "axios";
import { BASE_URL } from "../../constants";

export function getLeadSources(params) {
  return axios.get(`${BASE_URL}/lead_sources`, {
    params,
  });
}

export function createLeadSource(values) {
  return axios.post(`${BASE_URL}/lead_sources`, values);
}

export function getLeadSourceById(id) {
  return axios.get(`${BASE_URL}/lead_sources/${id}`);
}

export function updateLeadSource(id, values) {
  return axios.put(`${BASE_URL}/lead_sources/${id}`, values);
}

export function deleteLeadSource(id) {
  return axios.delete(`${BASE_URL}/lead_sources/${id}`);
}
