import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { createRole, deleteRole, getRoles } from "../../../api/Roles";
import { getPermission } from "../../../api/Permissions";

export default function RolesPage() {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const loadRoles = (params = {}) => {
    setLoadingPermissions(true);
    getRoles(params)
      .then((res) => setRoles(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingPermissions(false));
  };

  const loadPermissions = () => {
    getPermission({ pagination: false })
      .then((res) => setPermissions(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleAddRole = (values) => {
    createRole(values).then((res) => {
      loadRoles();
      setOpen(false);
      form.resetFields(["name"]);
    });
  };

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
    },
    {
      key: "guard_name",
      dataIndex: "guard_name",
      title: "Guard Name",
    },
    {
      key: "action",
      title: "Action",
      render: (_) => {
        return (
          <Button
            danger
            onClick={() =>
              deleteRole(_.id)
                .then((res) => loadRoles())
                .catch((err) => console.log(err))
            }
          >
            Delete
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    loadRoles();
    loadPermissions();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Roles
        </Typography.Title>
        <Button onClick={() => setOpen(true)}>Add Role</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={roles.data}
        loading={loadingPermissions}
        pagination={{
          position: ["bottomCenter"],
          pageSize: roles.per_page,
          total: roles.total,
          onChange: (page, pageSize) => {
            loadRoles({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        okText="Save"
        title="Add Role"
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
      >
        <Form form={form} layout="vertical" onFinish={handleAddRole}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>

          <Form.Item
            label="Permissions"
            name="permissions"
            rules={[
              {
                required: true,
                message: "Please select permission(s)",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Please select permission">
              {permissions?.map((permission) => {
                return (
                  <Select.Option value={permission.name}>
                    {permission.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
