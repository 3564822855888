import { Col, Row, Typography } from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { MdSupervisorAccount } from "react-icons/md";
import MasterCard from "../masters/components/Card";

export default function SettingsPage() {
  const { Title } = Typography;

  const colSpan = 4;
  return (
    <LayoutContainer>
      <Title style={{ marginTop: 0 }}>Settings</Title>

      <Row gutter={[16, 16]}>
        <Col span={colSpan}>
          <MasterCard
            link="/settings/branches"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Branches"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/settings/admin"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Admin"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/settings/counsellers"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Counsellers"
          />
        </Col>
      </Row>
    </LayoutContainer>
  );
}
