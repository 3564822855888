import { Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";

const { Title } = Typography;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Activity",
    dataIndex: "activity",
    key: "activity",
  },
];

const data = [
  {
    id: 1,
    name: "Test User",
    created_at: "25-03-2023",
    activity: "Test",
  },
];

export default function AreaPage() {
  return (
    <LayoutContainer>
      <Title style={{ marginTop: 0 }}>Area</Title>

      <Table columns={columns} dataSource={data} />
    </LayoutContainer>
  );
}
