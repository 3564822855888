import { useState } from "react";
import { Card, Divider, Form, Steps, Typography } from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import { createLead } from "../../api/Leads";
import { useNavigate } from "react-router-dom";
import StepFive from "./steps/StepFive";
import useAuth from "../../hooks/useAuth.hook";
import useID from "../../hooks/useID.hook";
import dayjs from "dayjs";

function CurrentStep({ step, form, setCurrentFormStep }) {
  switch (step) {
    case 0:
      return (
        <StepOne
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 1:
      return (
        <StepTwo
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 2:
      return (
        <StepThree
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 3:
      return (
        <StepFour
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    case 4:
      return (
        <StepFive
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
    default:
      return (
        <StepOne
          form={form}
          currentFormStep={step}
          setCurrentFormStep={setCurrentFormStep}
        />
      );
  }
}

export default function CreateLeadPage() {
  const { user } = useAuth();
  const id = useID();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState(0);

  const handleCreateLeads = (values) => {
    createLead({
      ...values,
      ...(id.branch_id !== "all" && { branch_id: id.branch_id }),
      dob: dayjs(values.dob).format("YYYY-MM-DD"),
      user_id: user.branch_id,
    })
      .then(() => navigate("/leads"))
      .catch((err) => console.log(err));
  };

  return (
    <LayoutContainer>
      <Typography.Title style={{ marginTop: 0 }}>Add Lead</Typography.Title>

      <Card>
        <Steps
          size="small"
          current={currentFormStep}
          items={[
            {
              title: "Student Details",
            },
            {
              title: "Student Contact Details",
            },
            {
              title: "Enquiry Details",
            },
            {
              title: "Student Bank Details",
            },
            {
              title: "Education",
            },
          ]}
        />

        <Divider />

        {/* <pre>D: {JSON.stringify(form.getFieldsValue(true), null, 4)}</pre> */}

        <Form
          form={form}
          layout="vertical"
          onFinish={() => {
            const values = form.getFieldsValue(true);
            handleCreateLeads(values);
          }}
        >
          <CurrentStep
            form={form}
            step={currentFormStep}
            setCurrentFormStep={setCurrentFormStep}
          />
        </Form>
      </Card>
    </LayoutContainer>
  );
}
