import axios from "axios";
import { BASE_URL } from "../../constants";

export function getRoles(params) {
  return axios.get(`${BASE_URL}/roles`, {
    params,
  });
}

export function createRole(values) {
  return axios.post(`${BASE_URL}/roles`, values);
}

export function getRoleById(id) {
  return axios.get(`${BASE_URL}/roles/${id}`);
}

export function updateRole(id, values) {
  return axios.put(`${BASE_URL}/roles/${id}`, values);
}

export function deleteRole(id) {
  return axios.delete(`${BASE_URL}/roles/${id}`);
}
