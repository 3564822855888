import {
  Typography,
  Table,
  Space,
  Button,
  Tooltip,
  Row,
  message,
  Modal,
  Form,
  Input,
  Select,
  Tag,
  Popover,
} from "antd";
import { MdDelete, MdEdit, MdRemoveRedEye } from "react-icons/md";
import LayoutContainer from "../../components/layout/LayoutContainer";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useState } from "react";
import { find, isNil, omitBy, replace, split, upperFirst } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLeads, deleteLead } from "../../api/Leads";
import { getCounsellorsByBranchId } from "../../api/Counsellor";
import { getLeadStage } from "../../api/LeadStage";
import { getLeadSources } from "../../api/LeadSource";
import ColTitle from "../../components/Table/ColTitle.component";
import useID from "../../hooks/useID.hook";
import { getEducation } from "../../api/Education";
import { getFeeGroup } from "../../api/FeeGroup";
import ColValue from "../../components/Table/ColValue.component";
import useAuth from "../../hooks/useAuth.hook";

const { Title, Text } = Typography;

export default function LeadsPage() {
  const id = useID();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);

  const [counsellors, setCounsellors] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadStages, setLeadStages] = useState([]);
  const [feeGroup, setFeeGroup] = useState([]);
  const [education, setEducation] = useState([]);

  const loadLeads = (params = { pagination: true, page: page }) => {
    setLoadingData(true);
    getLeads(params, id.branch_id, id.counsellor_id)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  // FILTER FUNCTIONS

  const loadEmployee = () => {
    getCounsellorsByBranchId({ pagination: false }, id.branch_id).then(
      (res) => {
        setCounsellors(res.data.data);
      }
    );
  };

  const loadLeadSource = () => {
    getLeadSources({ pagination: false }).then((res) => {
      setLeadSources(res.data.data);
    });
  };

  const loadLeadStage = () => {
    getLeadStage({ pagination: false }).then((res) => {
      setLeadStages(res.data.data);
    });
  };

  const loadEducation = () => {
    getEducation({ pagination: false }).then((res) => {
      setEducation(res.data.data);
    });
  };

  const loadFeeGroup = () => {
    getFeeGroup({ pagination: false }).then((res) => {
      setFeeGroup(res.data.data);
    });
  };
  // END

  const handleDelete = async (id) => {
    if (id) {
      try {
        await deleteLead(id);
        loadLeads({
          ...Object.fromEntries([...searchParams]),
          pagination: true,
          page: page,
        });
        message.success({
          content: "Lead deleted successfully",
        });
      } catch (error) {
        message.error({
          content: "Something went wrong",
        });
        console.log("Something went wrong", error);
      }
    }

    throw new Error("Something went wrong");
  };

  const columns = [
    {
      key: "enquiry_date",
      title: <ColTitle>Enquiry Date</ColTitle>,
      render: (_) => {
        return (
          <ColValue>
            {dayjs(_?.enquiry?.enquiry_date).format("DD-MM-YYYY") ?? "N/A"}
          </ColValue>
        );
      },
    },
    {
      key: "enquiry_id",
      title: <ColTitle>Enquiry ID</ColTitle>,
      render: (_) => <ColValue>{_?.enquiry_id ?? "N/A"}</ColValue>,
    },
    {
      key: "name",
      title: <ColTitle>Student Name</ColTitle>,
      render: (_) => {
        return <ColValue>{_?.name}</ColValue>;
      },
    },
    {
      key: "mobile",
      title: <ColTitle>Mobile</ColTitle>,
      className: "max-width-150",
      render: (_) => <ColValue>{_?.mobile ?? "N/A"}</ColValue>,
    },
    {
      key: "enquiryLocation",
      title: <ColTitle>Enquiry/Location</ColTitle>,
      render: (_) => <ColValue>{_?.area?.name ?? "N/A"}</ColValue>,
    },
    {
      key: "enquiryTakenBy",
      title: <ColTitle>Enquiry Taken By</ColTitle>,
      render: (_) => (
        <ColValue>{_?.enquiry?.counsellor?.name ?? "N/A"}</ColValue>
      ),
    },
    {
      key: "leadStage",
      title: <ColTitle>Lead Stage</ColTitle>,
      render: (_) => (
        <ColValue>{_?.enquiry?.lead_stage?.name ?? "N/A"}</ColValue>
      ),
    },
    {
      key: "leadSource",
      title: <ColTitle>Lead Source</ColTitle>,
      render: (_) => (
        <ColValue>{_?.enquiry?.lead_source?.name ?? "N/A"}</ColValue>
      ),
    },
    {
      key: "status",
      title: <ColTitle>Status</ColTitle>,
      render: (_) => <ColValue>{_?.status ?? "N/A"}</ColValue>,
    },
    {
      key: "action",
      title: <ColTitle>Action</ColTitle>,
      render: (_, record) => {
        return (
          <Space size="small">
            <Tooltip title="View">
              <Button
                onClick={() => navigate(`/leads/${_?.enquiry_id}`)}
                icon={<MdRemoveRedEye />}
              />
            </Tooltip>

            <Tooltip title="Edit">
              <Button
                onClick={() => navigate(`/leads/edit/${_?.enquiry_id}`)}
                icon={<MdEdit />}
              />
            </Tooltip>

            {user.user_type === "admin" && _?.status !== "Admitted" && (
              <Tooltip title="Delete">
                <Popover
                  content={
                    <Space direction="vertical" align="end">
                      <Text>Are you sure to delete?</Text>

                      <Row>
                        <Button
                          danger
                          onClick={() => {
                            handleDelete(_?.enquiry_id);
                          }}
                        >
                          Delete
                        </Button>
                      </Row>
                    </Space>
                  }
                  title="Title"
                  trigger="click"
                >
                  <Button danger icon={<MdDelete />} />
                </Popover>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  function filterValue(key, value) {
    switch (key) {
      case "counselor_id":
        const counsellor = find(counsellors, { id: Number(value) });
        return counsellor?.name;
      case "lead_source":
        const leadSource = find(leadSources, { id: Number(value) });
        return leadSource?.name;
      case "lead_stage":
        const leadStage = find(leadStages, { id: Number(value) });
        return leadStage?.name;
      case "fee_group_id":
        const _feeGroup = find(feeGroup, { id: Number(value) });
        return _feeGroup?.name;
      case "education_id":
        const _education = find(education, { id: Number(value) });
        return _education?.name;
      default:
        return value;
    }
  }

  useEffect(() => {
    loadLeads(
      {
        ...Object.fromEntries([...searchParams]),
        pagination: true,
        page: 1,
      },
      id.branch_id,
      id.counsellor_id
    );
  }, [searchParams, id.branch_id, id.counsellor_id]);

  useLayoutEffect(() => {
    loadEmployee();
    loadLeadSource();
    loadLeadStage();
    loadEducation();
    loadFeeGroup();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Leads</Title>

        <Space>
          <Button onClick={() => setIsFilterModalOpen(true)}>Filter</Button>
          <Button onClick={() => navigate("/leads/add")}>Add New</Button>
        </Space>
      </Row>

      <Row
        gutter={[0, 16]}
        style={{
          marginBottom: 16,
        }}
      >
        {Object.keys(Object.fromEntries([...searchParams])).map(
          (value, index) => (
            <Tag
              closable
              key={value}
              style={{ padding: 8 }}
              onClose={() => {
                const params = Object.fromEntries([...searchParams]);
                const values = omitBy(params, (v, k) => {
                  form.setFieldValue(k, null);
                  return k === value;
                });
                loadLeads(values);
                setSearchParams(values);
              }}
            >
              {replace(
                split(
                  upperFirst(
                    Object.keys(Object.fromEntries([...searchParams]))[index]
                  ),
                  "_"
                ).join(" "),
                "_",
                " "
              )}
              :{" "}
              {filterValue(
                Object.keys(Object.fromEntries([...searchParams]))[index],
                upperFirst(Object.fromEntries([...searchParams])[value])
              )}
            </Tag>
          )
        )}
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Form.Item>
          <Input.Search
            allowClear
            onSearch={(v) => {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                search: v,
              });
              loadLeads(
                {
                  ...Object.fromEntries([...searchParams]),
                  pagination: true,
                  page: page,
                  search: v,
                },
                id.branch_id,
                id.counsellor_id
              );
            }}
            size="large"
            placeholder="Search by Name or Mobile No"
            style={{ maxWidth: 300 }}
          />
        </Form.Item>
      </div>

      <Table
        loading={loadingData}
        columns={columns}
        // rowSelection={{
        //   type: "checkbox",
        //   onChange: (key, rows) => {
        //     setSelectedRows(rows);
        //   },
        // }}
        dataSource={data?.data}
        scroll={{ x: true }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data?.pagination?.per_page,
          current: data?.pagination?.current_page,
          defaultCurrent: data?.pagination?.current_page,
          total: data?.pagination?.total,
          onChange: (page, pageSize) => {
            setPage(page);
            loadLeads({
              ...Object.fromEntries([...searchParams]),
              pagination: true,
              page,
            });
          },
        }}
      />

      <Modal
        title="Filter"
        okText="Filter"
        onOk={() => form.submit()}
        open={isFilterModalOpen}
        onCancel={() => setIsFilterModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={Object.fromEntries([...searchParams])}
          onFinish={(values) => {
            loadLeads(values);
            setSearchParams(omitBy(values, isNil));
            setIsFilterModalOpen(false);
          }}
        >
          <Form.Item name="counselor_id" label="Counsellor">
            <Select placeholder="Please select counsellor">
              {counsellors?.map((employee) => (
                <Select.Option value={employee.id}>
                  {employee.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="lead_source" label="Lead Source">
            <Select placeholder="Please select lead source">
              {leadSources.map((leadSource) => (
                <Select.Option value={leadSource.id}>
                  {leadSource.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="lead_stage" label="Lead Stage">
            <Select placeholder="Please select lead stage">
              {leadStages.map((leadStage) => (
                <Select.Option value={leadStage.id}>
                  {leadStage.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="fee_group_id" label="Fee Package">
            <Select placeholder="Please select fee package">
              {feeGroup.map((feeGroup) => (
                <Select.Option value={feeGroup.id}>
                  {feeGroup.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="gender" label="Gender">
            <Select placeholder="Please select gender">
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="education_id" label="Education">
            <Select placeholder="Please select education">
              {education.map((education) => (
                <Select.Option value={education.id}>
                  {education.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="area" label="Area">
            <Select placeholder="Please select area">
              <Select.Option></Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="is_follow_up_required" label="Is Followup Required?">
            <Select placeholder="Is followup required?">
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
              <Select.Option value="all">All</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="is_admitted" label="Is Admitted?">
            <Select placeholder="Is admitted">
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
              <Select.Option value="all">All</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
