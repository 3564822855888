import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { createFee, deleteFee, getFee } from "../../../api/Fee";

export default function FeesPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loadingFees, setLoadingFees] = useState(false);
  const [fees, setFees] = useState([]);
  const [open, setOpen] = useState(false);

  const loadFees = (params = {}) => {
    setLoadingFees(true);
    getFee(params)
      .then((res) => setFees(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingFees(false));
  };

  const handleAddFee = (values) => {
    createFee(values)
      .then((res) => {
        loadFees();
        setOpen(false);
        form.resetFields(["name"]);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteFee = (feeID) => {
    deleteFee(feeID)
      .then((res) => loadFees())
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_) => {
        return (
          <Button danger onClick={() => handleDeleteFee(_.id)}>
            Delete
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    loadFees();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Typography.Title style={{ marginTop: 0, marginBottom: 0 }}>
          Fee
        </Typography.Title>

        <Space>
          <Button onClick={() => navigate("/masters/fees/fees-group")}>
            Fee Group
          </Button>
          {/* <Button onClick={() => navigate("/masters/fees/fees-package")}>
            Fee Package
          </Button> */}
          <Button type="primary" onClick={() => setOpen(true)}>
            Add Fee
          </Button>
        </Space>
      </Row>

      <Table
        columns={columns}
        dataSource={fees?.data}
        loading={loadingFees}
        pagination={{
          position: ["bottomCenter"],
          pageSize: fees.per_page,
          total: fees.total,
          onChange: (page, pageSize) => {
            loadFees({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        okText="Save"
        closable={false}
        title="Add Fee"
        onOk={() => form.submit()}
        onCancel={() => {
          setOpen(false);
          form.resetFields(["name"]);
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddFee}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter fee name",
              },
            ]}
          >
            <Input placeholder="Please enter fee name" />
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
