import { Button, Form, Input, Row } from "antd";
import { reduce } from "lodash";
import { useEffect } from "react";

export default function StepSeven({
  form,
  state,
  formValues,
  currentFormStep,
  setCurrentFormStep,
}) {
  const _formValues = Form.useWatch([], form);

  function roundGrandTotal(grandTotal) {
    // if (_formValues?.adjustments < 0) {

    //   return 0.0;
    // } else {
    // adjustments
    const roundedValue = Math.round(grandTotal);
    const numberToAdd = roundedValue - grandTotal;

    return numberToAdd;
    // }
  }

  useEffect(() => {
    const grandTotal = reduce(
      formValues.feeDetails,
      function (sum, n) {
        const _totalAmount = n.amount * n.quantity;
        return sum + _totalAmount;
      },
      0
    );

    form.setFieldValue("invoice_net_total", grandTotal);
  }, [formValues, form]);

  return (
    <>
      <Form.Item
        initialValue={0.0}
        name="discount_before_tax"
        label="Discount Before Tax"
      >
        <Input readOnly disabled addonAfter={"%"} />
      </Form.Item>
      <Form.Item name="invoice_net_total" label="Net Total">
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item initialValue={0.0} name="taxes" label="Taxes">
        <Input disabled readOnly />
      </Form.Item>
      <Form.Item
        name="adjustments"
        label="Adjustments"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          style={{ width: "100%" }}
          onChange={(e) => {
            form.setFieldValue(
              "grand_total",
              Number(_formValues?.invoice_net_total) + Number(e.target.value)
            );
          }}
          addonAfter={
            <Button
              type="text"
              onClick={() => {
                if (Number(_formValues?.adjustments) > 0) {
                  form.setFieldValue("adjustments", 0.0);

                  form.setFieldValue(
                    "grand_total",
                    Number(_formValues?.invoice_net_total) - 0.0
                  );
                } else {
                  form.setFieldValue(
                    "adjustments",
                    roundGrandTotal(_formValues?.invoice_net_total).toFixed(2)
                  );

                  form.setFieldValue(
                    "grand_total",
                    Number(_formValues?.invoice_net_total) -
                      roundGrandTotal(_formValues?.invoice_net_total).toFixed(2)
                  );
                }
              }}
            >
              Round
            </Button>
          }
        />
      </Form.Item>
      <Form.Item
        label="Grand Total"
        name="grand_total"
        initialValue={
          Number(_formValues?.invoice_net_total) -
            Number(_formValues?.adjustments) ?? 0.0
        }
        rules={[
          {
            required: true,
            message: "Please enter grand total",
          },
        ]}
      >
        <Input readOnly disabled type="number" />
      </Form.Item>
      <Form.Item name="invoice_admission_remarks" label="Admission Remarks">
        <Input.TextArea />
      </Form.Item>

      {/* {state === "create" && ( */}
      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Row>
      {/* )} */}
    </>
  );
}
