import { Button, Form, Input, Row } from "antd";
import "../style.css";

export default function StepFour({
  form,
  currentFormStep,
  setCurrentFormStep,
}) {
  return (
    <>
      <Form.Item
        name="bank"
        label="Bank Name"
        // rules={[
        //   {
        //     required: true,
        //     message: "Bank name is required",
        //   },
        // ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bank_account"
        label="Bank Account No."
        // rules={[
        //   {
        //     required: true,
        //     message: "Bank account no. is required",
        //   },
        // ]}
      >
        <Input type="number" inputMode="numeric" />
      </Form.Item>

      <Form.Item
        name="bank_ifsc_code"
        label="Bank IFSC Code"
        // rules={[
        //   {
        //     required: true,
        //     message: "Bank IFSC Code is required",
        //   },
        // ]}
      >
        <Input placeholder="Please enter bank IFSC code" />
      </Form.Item>

      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          htmlType="button"
          disabled={currentFormStep === 4}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </>
  );
}
