import { Button, Form, Input, Modal, Row, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import { createLeadStage, getLeadStage } from "../../../api/LeadStage";

const { Title } = Typography;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => new Date(text).toLocaleDateString("en-GB"),
  },
];

export default function LeadStagePage() {
  const [form] = Form.useForm();
  const [loadingLeadStage, setLoadingLeadStage] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const loadLeadStage = (params = {}) => {
    setLoadingLeadStage(true);
    getLeadStage(params)
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoadingLeadStage(false));
  };

  const handleAddLeadStage = (values) => {
    createLeadStage(values).then((res) => {
      loadLeadStage();
      setOpen(false);
      form.resetFields();
    });
  };

  useEffect(() => {
    loadLeadStage();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Lead Stage</Title>

        <Button onClick={() => setOpen(true)}>Add New</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        loading={loadingLeadStage}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadLeadStage({
              page,
            });
          },
        }}
      />

      <Modal
        open={open}
        okText="Save"
        title="Add Lead Stage"
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields(["name"]);
          setOpen(false);
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddLeadStage}>
          <Form.Item label="Name" name="name">
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
