import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { getEducation } from "../../../api/Education";
import { ACADEMIC_YEAR } from "../../../constants";

const { Text } = Typography;

export default function StepFive({
  form,
  currentFormStep,
  setCurrentFormStep,
}) {
  const [educations, setEducations] = useState([]);

  const loadEducations = () => {
    getEducation({ pagination: false })
      .then((res) => setEducations(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadEducations();
  }, []);

  return (
    <>
      <Row
        gutter={6}
        style={{
          borderBottom: "1px solid #EEEEEE",
          paddingBottom: 10,
          marginBottom: 14,
        }}
      >
        <Col span={6}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Education <span style={{ color: "red" }}>*</span>
          </Text>
        </Col>
        <Col span={6}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Academic Year <span style={{ color: "red" }}>*</span>
          </Text>
        </Col>
      </Row>

      <Form.List
        name="education"
        initialValue={[{}]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={[12, 0]}>
                <Col span={6}>
                  <Form.Item
                    {...restField}
                    name={[name, "education"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select education",
                      },
                    ]}
                  >
                    <Select placeholder="Please select education">
                      {educations.map((education) => (
                        <Select.Option value={education.id}>
                          {education.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    {...restField}
                    name={[name, "year"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select academic_year",
                      },
                    ]}
                  >
                    <Select placeholder="Please select academic year">
                      {ACADEMIC_YEAR.map((year) => (
                        <Select.Option value={year}>{year}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {name !== 0 && (
                  <Col>
                    <DeleteOutlined onClick={() => remove(name)} />
                  </Col>
                )}
              </Row>
            ))}
            <Form.Item>
              <Button
                block
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add More
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        {/* onClick={() => form.submit()} */}
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Row>
    </>
  );
}
