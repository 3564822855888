import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";

const { Text } = Typography;

export default function StepFour({
  form,
  state,
  formValues: _formValues,
  currentFormStep,
  setCurrentFormStep,
}) {
  const formValues = form.getFieldsValue(true);
  const admission_seating =
    formValues?.admission_seating ?? _formValues?.admission_seating;

  return (
    <>
      <Row
        style={{
          paddingBottom: 10,
          borderBottom: "1px solid #EEEEEE",
          marginBottom: 14,
        }}
      >
        <Col span={1}>
          <Text>#</Text>
        </Col>
        <Col span={5}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Fee Group
          </Text>
        </Col>
        <Col span={6}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Subjects
          </Text>
        </Col>

        <Col span={6}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Batch Start Date
          </Text>
        </Col>
        <Col span={6}>
          <Text
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Batch End Date
          </Text>
        </Col>
      </Row>

      {/* <pre>{JSON.stringify(admission_seating, null, 3)}</pre> */}
      <Form.List name="admission_seating" initialValue={admission_seating}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={6}>
                <Col span={1}>
                  <Form.Item
                    name={[name, "is_checked"]}
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name={[name, "fee_group_id"]}
                    initialValue="IELTS (G)"
                  >
                    <Select>
                      <Select.Option
                        value={admission_seating[key]["fee_group_id"]}
                      >
                        {admission_seating[key]["fee_group"]}
                      </Select.Option>
                    </Select>
                    {/* <Input
                      disabled
                      // addonBefore={
                      //   <Form.Item
                      //     style={{
                      //       marginBottom: 0,
                      //       minHeight: "auto",
                      //     }}
                      //     name={[name, "grade_enabled"]}
                      //     valuePropName="checked"
                      //     initialValue={true}
                      //   >
                      //     <Checkbox />
                      //   </Form.Item>
                      // }
                    /> */}
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name={[name, "subject_id"]}>
                    <Select>
                      <Select.Option
                        value={admission_seating[key]["subject_id"]}
                      >
                        {admission_seating[key]["name"]}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name={[name, "batch_start_date"]}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select batch start date",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      disabledDate={(current) => {
                        return current && current < dayjs().endOf("day");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name={[name, "batch_end_date"]}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select batch end date",
                    //   },
                    // ]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            ))}

            {/* <Button onClick={() => add()}>Add</Button> */}
          </>
        )}
      </Form.List>

      {/* {state === "create" && ( */}
      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          disabled={currentFormStep === 6}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
      {/* )} */}
    </>
  );
}
