import axios from "axios";
import { BASE_URL } from "../../constants";

export function getPermission(params) {
  return axios.get(`${BASE_URL}/permissions`, {
    params,
  });
}

export function createPermission(values) {
  return axios.post(`${BASE_URL}/permissions`, values);
}

export function getPermissionById(id) {
  return axios.get(`${BASE_URL}/permissions/${id}`);
}

export function updatePermission(id, values) {
  return axios.put(`${BASE_URL}/permissions/${id}`, values);
}

export function deletePermission(id) {
  return axios.delete(`${BASE_URL}/permissions/${id}`);
}
