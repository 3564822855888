import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteAdmission, getAdmission } from "../../api/Admission";
import { MdDelete, MdEdit, MdOutlineVisibility } from "react-icons/md";
import { find, isNil, omitBy, replace, split, upperFirst } from "lodash";
import { getAdmissionStatus } from "../../api/AdmissionStatus";
import { getCounsellorsByBranchId } from "../../api/Counsellor";
import { getDivision } from "../../api/Division";
import { getMedium } from "../../api/Medium";
import ColTitle from "../../components/Table/ColTitle.component";
import useID from "../../hooks/useID.hook";
import ColValue from "../../components/Table/ColValue.component";
import useAuth from "../../hooks/useAuth.hook";
import dayjs from "dayjs";
import daysInMonth from "../../utils/getCurrentMonthDays";
import axios from "axios";

const { Text } = Typography;

export default function AdmissionPage() {
  const id = useID();
  const auth = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [admission, setAdmission] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loadingAdmission, setLoadingAdmission] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }

    const days = daysInMonth();

    const tooLate = dates[0] && current.diff(dates[0], "days") >= days;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= days;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [counsellors, setCounsellors] = useState([]);
  const [admissionStatus, setAdmissionStatus] = useState([]);
  const [division, setDivision] = useState([]);
  const [medium, setMedium] = useState([]);

  const loadAdmission = (params = {}) => {
    setLoadingAdmission(true);
    getAdmission({
      ...params,
      branch_id: id.branch_id,
      counselor_id: id.counsellor_id,
    })
      .then((res) => setAdmission(res.data))
      .catch(() => message.error("Failed to fetch admission"))
      .finally(() => setLoadingAdmission(false));
  };

  const handleDeleteAdmission = async (id) => {
    if (id) {
      try {
        await deleteAdmission(id);
        loadAdmission({
          ...Object.fromEntries([...searchParams]),
          pagination: true,
          page: page,
        });
        message.success({
          content: "Admission deleted successfully",
        });
      } catch (error) {
        message.error({
          content: "Something went wrong! Please try again later",
        });
        console.log("Something went wrong! Please try again later", error);
      }
    }
  };

  const handleExportCSV = async () => {
    const res = await axios.get(
      `https://erp-api.flyingfeathers.com/public/index.php/export_admissions?branch_id=${
        id.branch_id
      }&startdate=${dayjs(value[0]).format("YYYY-MM-DD")}&enddate=${dayjs(
        value[1]
      ).format("YYYY-MM-DD")}`
    );
  };

  // FILTER FUNCTIONS
  const loadEmployee = () => {
    getCounsellorsByBranchId({ pagination: false }, id.branch_id).then((res) =>
      setCounsellors(res.data.data)
    );
  };

  const loadAdmissionStatus = () => {
    getAdmissionStatus({ pagination: false }).then((res) =>
      setAdmissionStatus(res.data.data)
    );
  };

  const loadDivision = () => {
    getDivision({ pagination: false }).then((res) =>
      setDivision(res.data.data)
    );
  };

  const loadMedium = () => {
    getMedium({ pagination: false }).then((res) => setMedium(res.data.data));
  };

  function filterValue(key, value) {
    switch (key) {
      case "counselor_id":
        const counsellor = find(counsellors, { id: Number(value) });
        return counsellor?.name;
      case "admission_status":
        const _admissionStatus = find(admissionStatus, { id: Number(value) });
        return _admissionStatus?.name;
      case "division":
        const _division = find(division, { id: Number(value) });
        return _division?.name;
      case "medium":
        const _medium = find(medium, { id: Number(value) });
        return _medium?.name;
      case "admission_date":
        return dayjs(value).format("YYYY-MM-DD");
      default:
        return value;
    }
  }

  // END FILTER FUNCTIONS

  const columns = [
    {
      key: 1,
      dataIndex: "id",
      title: <ColTitle>Id</ColTitle>,
    },
    {
      key: 2,
      title: "Student",
      render: (_) => {
        return <ColValue>{_?.student_info?.name ?? "N/A"}</ColValue>;
      },
    },
    // {
    //   key: 3,
    //   title: "Medium",
    //   render: (_) => {
    //     return <span>{_?.mediums?.name ?? "N/A"}</span>;
    //   },
    // },
    // {
    //   key: 4,
    //   title: "Admission Status",
    //   render: (_) => {
    //     return <span>{_?.admission_statuss?.name ?? "N/A"}</span>;
    //   },
    // },
    // {
    //   key: 5,
    //   title: "Employee",
    //   render: (_) => {
    //     return <span>{_?.employees?.name ?? "N/A"}</span>;
    //   },
    // },
    {
      key: 6,
      title: <ColTitle>ID Card</ColTitle>,
      render: (_) => <ColValue>{_?.idcard}</ColValue>,
    },
    {
      key: 13,
      title: <ColTitle>Mobile</ColTitle>,
      render: (_) => <ColValue>{_?.student_info?.contact?.mobile}</ColValue>,
    },
    {
      key: 9,
      title: <ColTitle>Admission Date</ColTitle>,
      render: (_) => <ColValue>{_?.admissionDate}</ColValue>,
    },
    {
      key: 10,
      title: <ColTitle>Academic Year</ColTitle>,
      render: (_) => <ColValue>{_?.academic_year}</ColValue>,
    },
    {
      key: 14,
      title: <ColTitle>Status</ColTitle>,
      render: (_) => {
        return (
          <>
            <ColValue>{_?.admission_status?.name}</ColValue>
            {_?.admission_status?.id === 2 && (
              <div>
                <ColValue>{_?.hold_till_date}</ColValue>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 12,
      title: <ColTitle>Admission Taken By</ColTitle>,
      render: (_) => <ColValue>{_?.counselor?.name}</ColValue>,
    },
    {
      key: 15,
      title: <ColTitle>Total Fees</ColTitle>,
      render: (_) => <ColValue>₹{_?.total_amount}</ColValue>,
    },
    {
      key: 16,
      title: <ColTitle>Payment</ColTitle>,
      render: (_) => <ColValue>₹{_?.paid_amount}</ColValue>,
    },
    {
      key: 17,
      title: <ColTitle>Balance</ColTitle>,
      render: (_) => <ColValue>₹{_?.pending_amount}</ColValue>,
    },
    {
      key: 18,
      title: <ColTitle>Remarks</ColTitle>,
      render: (_) => <ColValue></ColValue>,
    },
    {
      key: "11",
      title: "Action",
      render: (_) => {
        return (
          <Space>
            <Tooltip title="View">
              <Button
                onClick={() => navigate(`/admission/${_.id}`)}
                icon={<MdOutlineVisibility />}
              />
            </Tooltip>

            <Tooltip>
              <Button
                onClick={() =>
                  navigate(`/admission/edit/${_.id}`, {
                    state: {
                      admission: _,
                    },
                  })
                }
                icon={<MdEdit />}
              />
            </Tooltip>

            {auth.user.user_type === "admin" && (
              <Tooltip title="Delete">
                <Popover
                  content={
                    <Space direction="vertical" align="end">
                      <Text>Are you sure to delete?</Text>

                      <Button
                        danger
                        onClick={() => handleDeleteAdmission(_.id)}
                      >
                        Delete
                      </Button>
                    </Space>
                  }
                  trigger="click"
                >
                  <Button danger icon={<MdDelete />} />
                </Popover>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    loadAdmission({
      ...Object.fromEntries([...searchParams]),
      pagination: true,
      page: 1,
    });
  }, [searchParams, id.branch_id, id.counsellor_id]);

  useEffect(() => {
    loadEmployee();
    loadAdmissionStatus();
    loadDivision();
    loadMedium();
  }, []);

  return (
    <LayoutContainer>
      <Row align={"middle"} justify={"space-between"}>
        <Typography.Title style={{ marginTop: 0 }}>Admission</Typography.Title>

        <Space>
          <Button onClick={showDrawer}>Export CSV</Button>
          <Button onClick={() => setIsFilterModalOpen(true)}>Filter</Button>
          <Button onClick={() => navigate("/create-admission")}>
            Add Admission
          </Button>
        </Space>
      </Row>

      <Row
        style={{
          marginBottom: 16,
        }}
      >
        {Object.keys(Object.fromEntries([...searchParams])).map(
          (value, index) => (
            <Tag
              closable
              key={value}
              style={{ padding: 8 }}
              onClose={() => {
                const params = Object.fromEntries([...searchParams]);
                const values = omitBy(params, (v, k) => {
                  form.setFieldValue(k, null);
                  return k === value;
                });
                loadAdmission(values);
                setSearchParams(values);
              }}
            >
              {replace(
                split(
                  upperFirst(
                    Object.keys(Object.fromEntries([...searchParams]))[index]
                  ),
                  "_"
                ).join(" "),
                "_",
                " "
              )}
              :{" "}
              {filterValue(
                Object.keys(Object.fromEntries([...searchParams]))[index],
                upperFirst(Object.fromEntries([...searchParams])[value])
              )}
            </Tag>
          )
        )}
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Form.Item>
          <Input.Search
            onSearch={(v) => {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                search: v,
              });
              loadAdmission({
                ...Object.fromEntries([...searchParams]),
                search: v,
              });
            }}
            size="large"
            placeholder="Search by Name or Mobile No"
            style={{ maxWidth: 300 }}
          />
        </Form.Item>
      </div>

      <Table
        columns={columns}
        loading={loadingAdmission}
        dataSource={admission?.data?.data}
        pagination={{
          position: ["bottomCenter"],
          pageSize: admission?.data?.per_page,
          current: admission?.data?.current_page,
          defaultCurrent: admission?.data?.current_page,
          total: admission?.data?.total,
          onChange: (page) => {
            setPage(page);
            loadAdmission({
              ...Object.fromEntries([...searchParams]),
              pagination: true,
              page,
            });
          },
        }}
        scroll={{
          x: true,
        }}
      />

      <Modal
        okText="Filter"
        open={isFilterModalOpen}
        onOk={() => form.submit()}
        onCancel={() => setIsFilterModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            loadAdmission(values);
            setSearchParams(omitBy(values, isNil));
            setIsFilterModalOpen(false);
          }}
        >
          <Form.Item name="counselor_id" label="Admission Taken By">
            <Select placeholder="Please select counsellor">
              {counsellors?.map((employee) => (
                <Select.Option value={employee.id}>
                  {employee.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="admission_status" label="Admission Status">
            <Select placeholder="Please select admission status">
              {admissionStatus.map((as) => (
                <Select.Option value={as.id}>{as.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="admission_date" label="Admission Date">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="division" label="Division">
            <Select placeholder="Please select division">
              {division.map((value) => (
                <Select.Option value={value.id}>{value.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="medium" label="Medium">
            <Select placeholder="Please select medium">
              {medium.map((value) => (
                <Select.Option value={value.id}>{value.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Drawer destroyOnClose title="Export CSV" onClose={onClose} open={open}>
        <DatePicker.RangePicker
          size="large"
          value={dates || value}
          disabledDate={disabledDate}
          onCalendarChange={(val) => {
            setDates(val);
          }}
          onChange={(val) => {
            setValue(val);
          }}
          onOpenChange={onOpenChange}
          changeOnBlur
        />

        <div style={{ marginTop: 24 }}>
          <a
            style={{
              color: "#fff",
              backgroundColor: "#990100",
              boxShadow: "0 2px 0 rgba(97, 22, 5, 0.24)",
              fontSize: 14,
              height: 32,
              padding: "8px 15px",
              borderRadius: 6,
              ...(!value && {
                pointerEvents: "none",
                cursor: "default",
                backgroundColor: "#a19d9d",
              }),
            }}
            href={`https://erp-api.flyingfeathers.com/public/index.php/export_admissions?branch_id=${
              id.branch_id
            }&startdate=${dayjs(value?.[0]).format(
              "YYYY-MM-DD"
            )}&enddate=${dayjs(value?.[1]).format("YYYY-MM-DD")}`}
          >
            <span>Export</span>
          </a>
        </div>
      </Drawer>
    </LayoutContainer>
  );
}
