import axios from "axios";
import { BASE_URL } from "../../constants";

export function getLeadStage(params) {
  return axios.get(`${BASE_URL}/lead_stage`, {
    params,
  });
}

export function createLeadStage(values) {
  return axios.post(`${BASE_URL}/lead_stage`, values);
}

export function getLeadStageById(id) {
  return axios.get(`${BASE_URL}/lead_stage/${id}`);
}

export function updateLeadStage(id, values) {
  return axios.put(`${BASE_URL}/lead_stage/${id}`, values);
}

export function deleteLeadStage(id) {
  return axios.delete(`${BASE_URL}/lead_stage/${id}`);
}
