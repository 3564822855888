import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { studentByAdmission } from "../../api/Admission";
import { useEffect, useState } from "react";
import { createInvoices, deleteInvoices, getInvoices } from "../../api/Invoice";
import ColTitle from "../../components/Table/ColTitle.component";
import ColValue from "../../components/Table/ColValue.component";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import useID from "../../hooks/useID.hook";
import { getCounsellorsByBranchId } from "../../api/Counsellor";
import { getFeeDetailsByGroup, getFeeGroup } from "../../api/FeeGroup";
import { getFee } from "../../api/Fee";
import { getAdmissionStatus } from "../../api/AdmissionStatus";
import { getBranches } from "../../api/Branch";
import useAuth from "../../hooks/useAuth.hook";

const { Title, Text } = Typography;

function CreateInvoice({ form, callbackURL }) {
  const [fee, setFee] = useState([]);
  const [feeGroup, setFeeGroup] = useState([]);
  const [admissionStatus, setAdmissionStatus] = useState([]);
  const formValues = Form.useWatch([], form);
  // const [feeGroup, setFeeGroup] = useState([]);

  const loadFee = () => {
    getFee({ pagination: false })
      .then((res) => setFee(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadFeeGroup = () => {
    getFeeGroup({ pagination: false }).then((res) =>
      setFeeGroup(res.data.data)
    );
  };

  const loadAdmissionStatus = () => {
    getAdmissionStatus({ pagination: false }).then((res) => {
      setAdmissionStatus(res.data.data);
    });
  };

  const loadFeeDetailsByGroup = (feeGroupId) => {
    getFeeDetailsByGroup({}, feeGroupId).then((res) => {
      form.setFieldValue("feeDetails", res.data.data);
    });
  };

  useEffect(() => {
    loadFee();
    loadFeeGroup();
    loadAdmissionStatus();
  }, []);

  return (
    <Card title="Add Invoice" style={{ margin: "16px 0" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          const _formValues = {
            ...formValues,
            admission_id: JSON.parse(formValues.admission_id).admission_id,
          };

          createInvoices(_formValues)
            .then((res) => {
              form.resetFields();
              callbackURL();
            })
            .catch((err) => console.log(err));
        }}
      >
        <Form.Item
          label="Fee Group"
          name="fee_group_id"
          rules={[
            {
              required: true,
              message: "Please select fee group",
            },
          ]}
        >
          <Select
            onChange={(value) => loadFeeDetailsByGroup(value)}
            placeholder="Please select fee group"
          >
            {feeGroup.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="admission_status"
          label="Admission Status"
          rules={[
            {
              required: true,
              message: "Please select admission status",
            },
          ]}
        >
          <Select placeholder="Please select admission status">
            {admissionStatus.map((status) => (
              <Select.Option value={status.id}>{status.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="invoice_due_date"
          label="Invoice Due Date"
          rules={[
            {
              required: true,
              message: "Please select invoice due date",
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="join_date"
          label="Join Date"
          rules={[
            {
              required: true,
              message: "Please select join date",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            // disabledDate={(current) => {
            //   return current && current < dayjs().endOf("day");
            // }}
          />
        </Form.Item>

        <Form.Item
          name="end_date"
          label="End Date"
          rules={[
            {
              required: true,
              message: "Please select end date",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="invoice_admission_remarks"
          label="Invoice Admission Remarks"
          rules={[
            {
              required: true,
              message: "Please enter invoice admission remarks",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.List name="feeDetails">
          {(fields, { add, remove }) => (
            <>
              <Text strong className="ant-form-item-label">
                Fees
              </Text>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  <Row gutter={6} style={{ marginTop: 8 }}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="Fee Type"
                        name={[name, "fee_id"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select fee type",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select fee type"
                          style={{ width: "100%" }}
                        >
                          {fee.map((item) => (
                            <Select.Option value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        label="Amount"
                        name={[name, "amount"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter amount",
                          },
                        ]}
                      >
                        <Input type="number" placeholder="Amount" />
                      </Form.Item>
                    </Col>

                    {/* <Col span={7}>
                      <Form.Item
                        {...restField}
                        label="Quantity"
                        name={[name, "quantity"]}
                        initialValue={1}
                        rules={[
                          {
                            required: true,
                            message: "Please enter quantity",
                          },
                        ]}
                      >
                        <Input
                          disabled
                          min={1}
                          type="number"
                          placeholder="Quantity"
                          defaultValue={1}
                        />
                      </Form.Item>
                    </Col> */}

                    <Col span={2}>
                      <DeleteOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                </>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add More
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Button htmlType="submit" type="primary">
          Add Invoice
        </Button>
      </Form>
    </Card>
  );
}

export default function InvoicesPage() {
  const id = useID();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [admission, setAdmission] = useState(null);
  const [admissionID, setAdmissionID] = useState(null);
  const [branches, setBranches] = useState([]);
  const [counsellors, setCounsellor] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const loadBranches = () => {
    getBranches({ pagination: false })
      .then((res) => setBranches(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadStudentByAdmission = (branchId, counselorId) => {
    studentByAdmission(
      {},
      branchId ?? id.branch_id,
      counselorId ?? formValues?.counselor_id
    )
      .then((res) => {
        setAdmission(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadCounselors = (branchId) => {
    getCounsellorsByBranchId(
      { pagination: false },
      branchId ?? id.branch_id
    ).then((res) => setCounsellor(res.data.data));
  };

  const handleGetInvoices = () => {
    getInvoices({ admission_id: admissionID })
      .then((res) => {
        setInvoices(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteInvoice = async (id) => {
    if (id) {
      try {
        await deleteInvoices(id);
        handleGetInvoices();
        message.success({
          content: "Invoice deleted successfully",
        });
      } catch (error) {
        message.error({
          content: "Something went wrong! Please try again later",
        });
        console.log("Something went wrong! Please try again later", error);
      }
    }

    throw new Error("Something went wrong");
  };

  useEffect(() => {
    if (id.branch_id !== "all") {
      form.setFieldValue("branch_id", id.branch_id);
      form.setFieldValue("counselor_id", null);
      form.setFieldValue("admission_id", null);
      setAdmissionID(null);
      setInvoices([]);
      loadCounselors(id.branch_id);
    } else {
      loadBranches();
      setAdmission([]);
      form.setFieldValue("branch_id", null);
      form.setFieldValue("counselor_id", null);
      form.setFieldValue("admission_id", null);
      setAdmissionID(null);
      setInvoices([]);
      setCounsellor([]);
    }
  }, [id.branch_id]);

  useEffect(() => {
    handleGetInvoices();
  }, [admissionID]);

  // useEffect(() => {
  //   loadStudentByAdmission(id.branch_id, formValues?.counselor_id);
  // }, [formValues?.counselor_id]);

  // useEffect(() => {
  //   loadStudentByAdmission();
  // }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Invoices</Title>
      </Row>

      <Card>
        <Form form={form} layout="vertical">
          {id.branch_id === "all" && (
            <Form.Item
              name="branch_id"
              label="Branch"
              rules={[
                {
                  required: true,
                  message: "Please select branch",
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  form.setFieldValue("branch_id", value);
                  form.setFieldValue("counselor_id", null);
                  loadCounselors(value);
                }}
                placeholder="Please select branch"
              >
                {branches.map((branch) => (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="counselor_id"
            label="Counselor"
            rules={[
              {
                required: true,
                message: "Please select counselor",
              },
            ]}
          >
            <Select
              onChange={(value) => {
                loadStudentByAdmission(form.getFieldValue("branch_id"), value);
                setAdmission(null);
                form.setFieldValue("admission_id", null);
                setAdmissionID(null);
                setInvoices([]);
              }}
              placeholder="Please select counselor"
            >
              {counsellors.map((counselor) => (
                <Select.Option value={counselor.id}>
                  {counselor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="admission_id"
            label="Student"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: "Please select student",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select student"
              onChange={(id) => {
                const admission = JSON.parse(id);
                console.log(admission);

                setAdmissionID(admission.admission_id);
                form.setFieldValue(
                  "admission_status",
                  admission.admission_status_id
                );
              }}
              style={{ marginBottom: 0 }}
            >
              {admission?.map((item) => (
                <Select.Option value={JSON.stringify(item)}>
                  {item.student} | {item.mobile}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Card>

      {admissionID && (
        <>
          <CreateInvoice
            form={form}
            admissionID={admissionID}
            callbackURL={handleGetInvoices}
          />

          <Card>
            <Table
              scroll={{
                x: true,
              }}
              columns={[
                {
                  key: "invoice_no",
                  title: <ColTitle>Invoice No</ColTitle>,
                  render: (_) => <ColValue>{_?.id}</ColValue>,
                },
                {
                  key: "invoice_number",
                  title: <ColTitle>Invoice Number</ColTitle>,
                  render: (_) => <ColValue>{_?.invoice_number}</ColValue>,
                },
                {
                  key: "invoice_due_date",
                  title: <ColTitle>Invoice Due Date</ColTitle>,
                  dataIndex: "due_date",
                },
                {
                  key: "total_amount",
                  title: "Total Amount",
                  dataIndex: "total_amount",
                },
                {
                  key: "received_amount",
                  title: "Received Amount",
                  dataIndex: "received_amount",
                },
                {
                  key: "receivable_amount",
                  title: "Due Amount",
                  dataIndex: "receivable_amount",
                },
                {
                  key: "admission_remarks",
                  title: <ColTitle>Admission Remarks</ColTitle>,
                  render: (_) => <ColValue>{_?.admission_remarks}</ColValue>,
                },
                {
                  key: "actions",
                  ...(user.user_type === "admin" && { title: "Actions" }),
                  render: (_) => {
                    return (
                      <>
                        {user.user_type === "admin" &&
                          _.received_amount <= 0 && (
                            <Space>
                              <Popconfirm
                                title="Delete Invoice?"
                                description="Are you sure to delete this invoice?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                  handleDeleteInvoice(_.id);
                                }}
                              >
                                <Button danger>Delete</Button>
                              </Popconfirm>
                            </Space>
                          )}
                      </>
                    );
                  },
                },
              ]}
              dataSource={invoices.data}
            />
          </Card>
        </>
      )}
    </LayoutContainer>
  );
}
