import axios from "axios";
import { BASE_URL } from "../../constants";

export function getFeeGroup(params) {
  return axios.get(`${BASE_URL}/fee_group`, {
    params,
  });
}

export function getFeeDetailsByGroup(params, feeGroupId, branchId) {
  return axios.get(`${BASE_URL}/fee_group/fee_details`, {
    params: {
      ...params,
      fee_group_id: feeGroupId,
      branch_id: branchId,
    },
  });
}

export function createFeeGroup(values) {
  return axios.post(`${BASE_URL}/fee_group`, values);
}

export function getFeeGroupById(id) {
  return axios.get(`${BASE_URL}/fee_group/${id}`);
}

export function updateFeeGroup(id, values) {
  return axios.put(`${BASE_URL}/fee_group/${id}`, values);
}

export function deleteFeeGroup(id) {
  return axios.delete(`${BASE_URL}/fee_group/${id}`);
}
