import axios from "axios";
import { BASE_URL } from "../../constants";

export function getLeads(params, branchId, counselorId) {
  return axios.get(`${BASE_URL}/studentEnquiry`, {
    params: {
      ...params,
      branch_id: branchId,
      counselor_id: counselorId,
      // ...(branchId === "all" ? {} : { branch_id: branchId }),
      // ...(counselorId === "all" ? {} : { counselor_id: counselorId }),
    },
  });
}

export function getNonAdmittedStudents(params, branchId, counselorId) {
  return axios.get(`${BASE_URL}/nonAdmittedStudents`, {
    params: {
      ...params,
      branch_id: branchId,
      counselor_id: counselorId,
    },
  });
}

export function createLead(values) {
  return axios.post(`${BASE_URL}/students`, values);
}

export function getLeadById(id) {
  return axios.get(`${BASE_URL}/students/${id}`);
}

export function updateLead(id, values) {
  return axios.put(`${BASE_URL}/students/${id}`, values);
}

export function deleteLead(id) {
  return axios.delete(`${BASE_URL}/students/${id}`);
}
