import { Col, Row, Typography } from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { MdSchool, MdSupervisorAccount } from "react-icons/md";
import MasterCard from "./components/Card";

export default function MastersPage() {
  const { Title } = Typography;

  const colSpan = 4;
  return (
    <LayoutContainer>
      <Title style={{ marginTop: 0 }}>Masters</Title>

      <Row gutter={[16, 16]}>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/parent-info"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Parent Info"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/education"
            icon={<MdSchool style={{ color: "#DEC3BD" }} size={40} />}
            title="Education"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/stream"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Stream"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/area"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Area"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/counsellors"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Counsellor"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/lead-source"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Lead Source"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/medium"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Medium"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/lead-stage"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Lead Stage"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/fees"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Fees"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/permissions"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Permissions"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/roles"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Roles"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/subject"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Subject"
          />
        </Col>
        <Col span={colSpan}>
          <MasterCard
            link="/masters/divisions"
            icon={
              <MdSupervisorAccount style={{ color: "#DEC3BD" }} size={40} />
            }
            title="Divisions"
          />
        </Col>
      </Row>
    </LayoutContainer>
  );
}
