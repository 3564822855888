import { Button, Form, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { getFeePackagesByGroup } from "../../../api/FeePackage";
import { getSubjectByFeeGroupId } from "../../../api/Subjects";
import { getFeeDetailsByGroup } from "../../../api/FeeGroup";

export default function StepTwo({
  form,
  state,
  formValues,
  currentFormStep,
  setCurrentFormStep,
}) {
  const [feePackages, setFeePackages] = useState([]);

  const loadGetFeePackagesByGroup = () => {
    // const arr = [];
    getFeePackagesByGroup({ pagination: false })
      .then((res) => {
        // for (const value of res.data.data) {
        //   arr.push({
        //     key: value.feeGroup,
        //     title: value.group_name.name,
        //     description: value.total,
        //   });
        // }
        // setFeePackages(arr);
        setFeePackages(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleLoadFeeDetails = (feeGroupId) => {
    getFeeDetailsByGroup({}, feeGroupId).then((res) => {
      form.setFieldValue("feeDetails", res.data.data);
    });
  };

  const handleLoadAdmissionSeatingAndFeeDetails = (feeGroupId) => {
    getSubjectByFeeGroupId(feeGroupId, {}).then((res) => {
      form.setFieldValue("admission_seating", res.data.data);

      // form.setFieldValue("feeDetails", res.data.data.fee_details);

      console.log(res);
    });
  };

  useEffect(() => {
    loadGetFeePackagesByGroup();
  }, []);

  // useEffect(() => {
  // if (targetKeys.length > 0) {
  // getFeePackagesByGroupId('[]').then((res) => {
  //   form.setFieldValue("admission_seating", res.data.data.admission_seating);

  //   form.setFieldValue("feeDetails", res.data.data.fee_details);

  //   console.log(res);
  // });
  // }
  // }, [targetKeys]);

  return (
    <>
      {/* <Transfer
        style={{ width: "100%", height: "100%", marginBottom: 16 }}
        onChange={onChange}
        targetKeys={targetKeys}
        dataSource={feePackages}
        selectedKeys={selectedKeys}
        onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
          setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        }}
        listStyle={{
          width: "100%",
          height: 300,
        }}
        render={(item) => (
          <Row justify={"space-between"}>
            <span>{item.title}</span>
            <span>
              {item.description.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })}
            </span>
          </Row>
        )}
      /> */}

      <Form.Item
        name="fee_package_id"
        label="Fee Package"
        initialValue={formValues?.admission_fee_package?.fee_group_id}
      >
        <Select
          onChange={(id) => {
            handleLoadAdmissionSeatingAndFeeDetails(id);
            handleLoadFeeDetails(id);
          }}
          placeholder="Please select fee package"
        >
          {feePackages?.map((feePackage) => (
            <Select.Option value={feePackage?.group_name?.id}>
              {feePackage?.group_name?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* {state === "create" && ( */}
      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          disabled={currentFormStep === 6}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
      {/* )} */}
    </>
  );
}
