import axios from "axios";
import { BASE_URL } from "../../constants";

export function getInvoices(params) {
  return axios.get(`${BASE_URL}/invoices`, {
    params: {
      ...params,
    },
  });
}

export function createInvoices(values) {
  return axios.post(`${BASE_URL}/invoices`, values);
}

export function getInvoicesById(id) {
  return axios.get(`${BASE_URL}/invoices/${id}`);
}

export function updateInvoices(id, values) {
  return axios.put(`${BASE_URL}/invoices/${id}`, values);
}

export function deleteInvoices(id) {
  return axios.delete(`${BASE_URL}/invoices/${id}`);
}
