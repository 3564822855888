import { useLoaderData, useNavigate } from "react-router-dom";
import LayoutContainer from "../../components/layout/LayoutContainer";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getParentInfo } from "../../api/ParentInfo";
import { useEffect, useState } from "react";
import { getEducation } from "../../api/Education";
import { getCountries } from "../../api/Countries";
import { getStatesByCountryId } from "../../api/States";
import { getCitiesByState } from "../../api/Cities";
import { getLeadSources } from "../../api/LeadSource";
import { getLeadStage } from "../../api/LeadStage";
import { getFeePackages } from "../../api/FeePackage";
import { getCounsellorsByBranchId } from "../../api/Counsellor";
import { updateLead } from "../../api/Leads";
import useID from "../../hooks/useID.hook";
import FormItem from "antd/es/form/FormItem";
import { ACADEMIC_YEAR } from "../../constants";
import useAuth from "../../hooks/useAuth.hook";

const { Title, Text } = Typography;

export default function EditLeadPage() {
  const id = useID();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loaderData = useLoaderData();
  const [parents, setParents] = useState([]);
  const [educations, setEducations] = useState([]);
  const [feePackages, setFeePackages] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadStage, setLeadStage] = useState([]);
  const [counsellors, setCounsellors] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const handleFinish = (values) => {
    updateLead(loaderData?.enquiry_id, values).then(() => navigate("/leads"));
  };

  const loadEducations = () => {
    getEducation({ pagination: false }).then((res) => {
      setEducations(res.data.data);
    });
  };

  const loadParents = () => {
    getParentInfo({ pagination: false }, id.branch_id, id.counsellor_id).then(
      (res) => {
        setParents(res.data.data);
      }
    );
  };

  const loadCounsellors = () => {
    getCounsellorsByBranchId({ pagination: false }, id.branch_id).then(
      (res) => {
        setCounsellors(res.data.data);
      }
    );
  };

  const loadLeadSources = () => {
    getLeadSources({ pagination: false }).then((res) => {
      setLeadSources(res.data.data);
    });
  };

  const loadLeadStage = () => {
    getLeadStage({ pagination: false }).then((res) => {
      setLeadStage(res.data.data);
    });
  };

  const loadFeePackages = () => {
    getFeePackages({ pagination: false }).then((res) => {
      setFeePackages(res.data.data);
    });
  };

  const loadCountries = () => {
    getCountries({ pagination: false }).then((res) =>
      setCountries(res.data.data)
    );
  };

  const loadStatesByCountry = (countryId) => {
    getStatesByCountryId({ pagination: false }, countryId).then((res) =>
      setStates(res.data.data.states)
    );
  };

  const loadCitiesByState = (stateId) => {
    getCitiesByState({ pagination: false }, stateId).then((res) =>
      setCities(res.data.data)
    );
  };

  useEffect(() => {
    loadCounsellors();
  }, [id.branch_id]);

  useEffect(() => {
    loadParents();
    loadFeePackages();
    loadEducations();
    loadLeadSources();
    loadLeadStage();

    loadCountries();
    loadStatesByCountry(loaderData?.country.id);
    loadCitiesByState(loaderData?.state.id);
  }, []);

  return (
    <LayoutContainer>
      <Title style={{ marginTop: 0 }}>Edit Lead</Title>

      <Card
        actions={[
          <Row justify="end" style={{ padding: "0 16px", gap: 10 }}>
            <Button onClick={() => navigate("/leads")}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </Row>,
        ]}
      >
        {/* <pre>{JSON.stringify(loaderData, null, 4)}</pre> */}

        <Form
          form={form}
          layout="vertical"
          // initialValues={loaderData}
          onFinish={handleFinish}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Card title="Student Details">
                  <Form.Item
                    name="name"
                    label="Student/Trainee name"
                    initialValue={loaderData?.name}
                  >
                    <Input placeholder="Please enter your name" />
                  </Form.Item>

                  <Form.Item
                    name="parent_id"
                    label="Parent Name"
                    initialValue={loaderData?.parent_id}
                  >
                    <Select placeholder="Please select parent name">
                      {parents.map((parent) => (
                        <Select.Option value={parent.id}>
                          {parent.fathersName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* defaultValue={dayjs('2015/01/01', dateFormat)} */}
                  {/* <pre>{JSON.stringify(loaderData.dob)}</pre> */}

                  <Form.Item
                    name="gender"
                    label="Gender"
                    initialValue={loaderData?.gender}
                  >
                    <Select placeholder="Please select Gender">
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="dob"
                    label="Date of Birth"
                    initialValue={dayjs(loaderData.dob, "YYYY-MM-DD")}
                  >
                    <DatePicker
                      defaultValue={dayjs(loaderData.dob, "YYYY-MM-DD")}
                      // format="YYYY/MM/DD"
                      placeholder="Select date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="marital_status"
                    label="Martial Status"
                    initialValue={loaderData?.marital_status}
                  >
                    <Select placeholder="Please select martial status">
                      <Select.Option value="married">Married</Select.Option>
                      <Select.Option value="unmarried">Unmarried</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="college"
                    label="College/School/Company"
                    initialValue={loaderData?.college}
                  >
                    <Input placeholder="Please enter college/school/company name" />
                  </Form.Item>
                </Card>

                <Card title="Student Bank Details">
                  <Form.Item
                    name="bank"
                    label="Bank Name"
                    initialValue={loaderData?.bank}
                  >
                    <Input placeholder="Please enter bank name" />
                  </Form.Item>

                  <Form.Item
                    name="bank_account"
                    label="Account Number"
                    initialValue={loaderData?.bank_account}
                  >
                    <InputNumber
                      controls={false}
                      placeholder="Please enter account number"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="bank_ifsc_code"
                    label="IFSC Code"
                    initialValue={loaderData?.bank_ifsc_code}
                  >
                    <Input placeholder="Please enter IFSC code" />
                  </Form.Item>
                </Card>
                <Card title="Education Details">
                  <Row
                    gutter={6}
                    style={{
                      borderBottom: "1px solid #EEEEEE",
                      paddingBottom: 10,
                      marginBottom: 14,
                    }}
                  >
                    <Col span={12}>
                      <Text
                        style={{
                          textTransform: "uppercase",
                          fontWeight: 600,
                          fontSize: 13,
                        }}
                      >
                        Education
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text
                        style={{
                          textTransform: "uppercase",
                          fontWeight: 600,
                          fontSize: 13,
                        }}
                      >
                        Academic Year
                      </Text>
                    </Col>
                  </Row>

                  <Form.List
                    name="education"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    initialValue={loaderData?.education}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Row gutter={[12, 0]}>
                            <Col span={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "education_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select education",
                                  },
                                ]}
                              >
                                <Select placeholder="Please select education">
                                  {educations.map((education) => (
                                    <Select.Option value={education.id}>
                                      {education.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "year"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select academic year",
                                  },
                                ]}
                              >
                                <Select placeholder="Please select academic year">
                                  {ACADEMIC_YEAR.map((year) => (
                                    <Select.Option value={year}>
                                      {year}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col>
                              <DeleteOutlined onClick={() => remove(name)} />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            block
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add More
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Card title="Student Contact Details">
                  <Form.Item
                    name="country_id"
                    label="Country"
                    initialValue={loaderData?.country.id}
                  >
                    <Select
                      placeholder="Please select country"
                      onChange={(countryId) => loadStatesByCountry(countryId)}
                    >
                      {countries.map((country) => (
                        <Select.Option value={country.id}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="state_id"
                    label="State"
                    initialValue={loaderData?.state.id}
                  >
                    <Select
                      placeholder="Please select state"
                      onChange={(stateId) => loadCitiesByState(stateId)}
                    >
                      {states.map((state) => (
                        <Select.Option value={state.id}>
                          {state.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="area"
                    label="Area"
                    initialValue={loaderData?.area.id}
                  >
                    <Select placeholder="Please select area">
                      {cities.map((city) => (
                        <Select.Option value={city.id}>
                          {city.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="address"
                    label="Address"
                    initialValue={loaderData?.address}
                  >
                    <Input.TextArea placeholder="Please enter address" />
                  </Form.Item>

                  <Form.Item
                    label="Zip Code"
                    name="zipcode"
                    initialValue={loaderData?.zipcode}
                  >
                    <InputNumber controls={false} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="mobile"
                    label="Mobile"
                    initialValue={loaderData?.mobile}
                  >
                    <Input
                      addonBefore={
                        <Form.Item noStyle>
                          <Select defaultValue="+91">
                            <Select.Option value="+91">+91</Select.Option>
                          </Select>
                        </Form.Item>
                      }
                      placeholder="Please enter mobile number"
                    />
                  </Form.Item>
                </Card>

                <Card title="Enquiry Details">
                  <Form.Item name="enquiry_date" label="Enquiry Date">
                    <DatePicker
                      // disabled={user.user_type === "admin" ? false : true}
                      defaultValue={dayjs(
                        loaderData?.enquiry?.enquiry_date,
                        "YYYY-MM-DD"
                      )}
                      style={{ width: "100%" }}
                      disabledDate={(current) => {
                        return current && current > dayjs().endOf("day");
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="counselor_id"
                    label="Enquiry Taken By"
                    initialValue={loaderData?.enquiry?.counsellor.id}
                  >
                    <Select placeholder="Please select counsellor">
                      {counsellors.map((counsellor) => (
                        <Select.Option value={counsellor.id}>
                          {counsellor.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="fee_package_id"
                    label="Fee Package"
                    initialValue={loaderData?.enquiry?.fee_group?.id}
                  >
                    <Select placeholder="Please select fee package">
                      {feePackages?.map((feePackage) => (
                        <Select.Option value={feePackage?.id}>
                          {feePackage?.group_name?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="lead_source"
                    label="Lead Source"
                    initialValue={loaderData?.enquiry?.lead_source?.id}
                  >
                    <Select placeholder="Please select lead source">
                      {leadSources.map((leadSource) => (
                        <Select.Option value={leadSource.id}>
                          {leadSource.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="lead_stage"
                    label="Lead Stage"
                    initialValue={loaderData?.enquiry?.lead_stage?.id}
                  >
                    <Select placeholder="Please select lead stage">
                      {leadStage.map((leadStage) => (
                        <Select.Option value={leadStage.id}>
                          {leadStage.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    initialValue={loaderData?.enquiry?.remarks}
                  >
                    <Input.TextArea placeholder="Please enter remarks" />
                  </Form.Item>
                </Card>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </LayoutContainer>
  );
}
