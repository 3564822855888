import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  message,
  Typography,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LayoutContainer from "../../components/layout/LayoutContainer";
import {
  getEnquiryFollowup,
  createEnquiryFollowup,
  getEnquiryFollowupById,
} from "../../api/EnquiryFollowup";
import { PRIMARY_COLOR } from "../../constants";
import { getFeePackagesByGroup } from "../../api/FeePackage";
import ColTitle from "../../components/Table/ColTitle.component";
import useID from "../../hooks/useID.hook";
import ColValue from "../../components/Table/ColValue.component";
import { getLeadStage } from "../../api/LeadStage";

const { Title, Text } = Typography;

function TodayEnquiryFollowUpTable({ columns }) {
  const id = useID();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const formValues = Form.useWatch([], form);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  // const [targetKeys, setTargetKeys] = useState([]);
  const [feePackages, setFeePackages] = useState([]);
  const [activeKey, setActiveKey] = useState("addFollowup");
  const [leadStages, setLeadStages] = useState([]);
  // const [selectedKeys, setSelectedKeys] = useState([]);
  const [followupHistory, setFollowupHistory] = useState([]);

  const loadGetFeePackagesByGroup = () => {
    // const arr = [];
    getFeePackagesByGroup({ pagination: false })
      .then((res) => {
        // for (const value of res.data.data) {
        //   arr.push({
        //     key: value.feeGroup,
        //     title: value.group_name.name,
        //     description: value.total,
        //   });
        // }
        // setFeePackages(arr);
        setFeePackages(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadTodayEnquiryFollowup = (params = {}) => {
    // Set loading state to true
    setLoading(true);

    // Call the API
    getEnquiryFollowup({
      ...params,
      type: "today",
      branch_id: id.branch_id,
      counsellor_id: id.counsellor_id,
    })
      .then((res) => {
        // If API call is successful, update data
        if (res.data && res.data.data) {
          setData(res.data.data);
        } else {
          // Otherwise, show error message
          message.error("Failed to load today enquiry followup");
        }
      })
      .catch(() => message.error("Failed to load today enquiry followup"))
      .finally(() => setLoading(false));
  };

  const loadLeadStages = () => {
    getLeadStage({ pagination: false }).then((res) => {
      setLeadStages(res.data.data);
    });
  };

  const handleAddFollowUp = (values) => {
    createEnquiryFollowup({
      ...values,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      student_id: drawerData.enquiry_id,
    })
      .then(() => {
        setDrawerOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const handleFollowUpHistory = (id) => {
    getEnquiryFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadGetFeePackagesByGroup();
    loadLeadStages();
  }, []);

  useEffect(() => {
    loadTodayEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.data}
        scroll={{
          x: true,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setDrawerData(record);
              setDrawerOpen(true);
              handleFollowUpHistory(record.enquiry_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page) => {
            loadTodayEnquiryFollowup({
              page,
            });
            setPage(page);
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.name}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_followup"
                label="Next Followup Required"
                initialValue="yes"
              >
                <Select placeholder="Please select next followup">
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>

              {formValues?.next_followup === "yes" && (
                <Form.Item
                  name="next_follow_up_date"
                  label="Next Followup Date"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    showTime
                    use12Hours
                    showSecond={false}
                    format="YYYY-MM-DD hh:mm A"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}

              {/* <Form.Item name="responseType" label="Response Type">
                <Select placeholder="Please select response type">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* <Form.Item name="followupRemarks" label="Followup Remark">
                <Input.TextArea placeholder="Please enter followup remark" />
              </Form.Item> */}

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              {/* <Form.Item name="feePackage" label="Fee Package">
                <Select placeholder="Please select fee package">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* Transfer */}

              {/* TODO: Add Form Item Name */}
              <Form.Item
                name="fee_package_id"
                label="Fee Package"
                initialValue={drawerData.feeGroup}
              >
                {/* <Transfer
                  style={{ width: "100%", height: "100%", marginBottom: 16 }}
                  dataSource={feePackages}
                  targetKeys={targetKeys}
                  selectedKeys={selectedKeys}
                  onChange={(nextTargetKeys, direction, moveKeys) => {
                    setTargetKeys(nextTargetKeys);
                  }}
                  onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                    setSelectedKeys([
                      ...sourceSelectedKeys,
                      ...targetSelectedKeys,
                    ]);
                  }}
                  listStyle={{
                    width: "100%",
                    height: 300,
                  }}
                  render={(item) => (
                    <Row justify={"space-between"}>
                      <span>{item.title}</span>
                      <span>
                        {item.description.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </span>
                    </Row>
                  )}
                /> */}
                <Select placeholder="Please select fee package">
                  {feePackages?.map((feePackage) => (
                    <Select.Option value={feePackage?.group_name?.id}>
                      {feePackage?.group_name?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="lead_stage"
                label="Lead Stage"
                initialValue={drawerData.lead_stage_id}
              >
                <Select placeholder="Please select lead stage">
                  {leadStages.map((stage) => (
                    <Select.Option value={stage.id}>{stage.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="remarks" label="Remarks">
                <Input.TextArea placeholder="" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  // dataIndex: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  // dataIndex: "followupDate",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  // dataIndex: "followupRemarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  // dataIndex: "followupBy",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function PendingEnquiryFollowupTable({ columns }) {
  const id = useID();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [activeKey, setActiveKey] = useState("addFollowup");
  const [feePackages, setFeePackages] = useState([]);
  const [leadStages, setLeadStages] = useState([]);
  const [followupHistory, setFollowupHistory] = useState([]);

  const loadPendingEnquiryFollowup = (params = {}) => {
    setLoading(true);
    getEnquiryFollowup({
      ...params,
      type: "past",
      branch_id: id.branch_id,
      counsellor_id: id.counsellor_id,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => message.error("Failed to load pending enquiry followup"))
      .finally(() => setLoading(false));
  };

  const loadGetFeePackagesByGroup = () => {
    // const arr = [];
    getFeePackagesByGroup({ pagination: false })
      .then((res) => {
        // for (const value of res.data.data) {
        //   arr.push({
        //     key: value.feeGroup,
        //     title: value.group_name.name,
        //     description: value.total,
        //   });
        // }
        // setFeePackages(arr);
        setFeePackages(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadLeadStages = () => {
    getLeadStage({ pagination: false }).then((res) => {
      setLeadStages(res.data.data);
    });
  };

  const handleAddFollowUp = (values) => {
    createEnquiryFollowup({
      ...values,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      student_id: drawerData.enquiry_id,
    })
      .then(() => {
        setDrawerOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const handleFollowUpHistory = (id) => {
    getEnquiryFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadGetFeePackagesByGroup();
    loadLeadStages();
  }, []);

  useEffect(() => {
    loadPendingEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.data}
        onRow={(record, dataIndex) => {
          return {
            onClick: () => {
              setDrawerOpen(true);
              setDrawerData(record);
              handleFollowUpHistory(record.enquiry_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page, pageSize) => {
            loadPendingEnquiryFollowup({
              page,
            });
            setPage(page);
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.name}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_followup"
                label="Next Followup Required"
                initialValue="yes"
              >
                <Select placeholder="Please select next followup">
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>

              {formValues?.next_followup === "yes" && (
                <Form.Item
                  name="next_follow_up_date"
                  label="Next Followup Date"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    showTime
                    use12Hours
                    showSecond={false}
                    format="YYYY-MM-DD hh:mm A"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}

              {/* <Form.Item name="responseType" label="Response Type">
                <Select placeholder="Please select response type">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* <Form.Item name="followupRemarks" label="Followup Remark">
                <Input.TextArea placeholder="Please enter followup remark" />
              </Form.Item> */}

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              {/* <Form.Item name="feePackage" label="Fee Package">
                <Select placeholder="Please select fee package">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* Transfer */}

              {/* TODO: Add Form Item Name */}
              <Form.Item
                name="fee_package_id"
                label="Fee Package"
                initialValue={drawerData.feeGroup}
              >
                <Select placeholder="Please select fee package">
                  {feePackages?.map((feePackage) => (
                    <Select.Option value={feePackage?.group_name?.id}>
                      {feePackage?.group_name?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="lead_stage"
                label="Lead Stage"
                initialValue={drawerData.lead_stage_id}
              >
                <Select placeholder="Please select lead stage">
                  {leadStages.map((stage) => (
                    <Select.Option value={stage.id}>{stage.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="remarks" label="Remarks">
                <Input.TextArea placeholder="" />
              </Form.Item>

              {/* <Form.Item name="leadType" label="Lead Type">
                <Select placeholder="Please select lead type">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* <Form.Item name="studentRemark" label="Student Remarks">
                <Input.TextArea placeholder="" disabled />
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  // dataIndex: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  // dataIndex: "followupDate",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  // dataIndex: "followupRemarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  // dataIndex: "followupBy",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function UpcomingEnquiryFollowUpTable({ columns }) {
  const id = useID();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [activeKey, setActiveKey] = useState("addFollowup");
  const [leadStages, setLeadStages] = useState([]);
  const [feePackages, setFeePackages] = useState([]);
  // const [targetKeys, setTargetKeys] = useState([]);
  // const [selectedKeys, setSelectedKeys] = useState([]);
  const [followupHistory, setFollowupHistory] = useState([]);

  const loadUpcomingEnquiryFollowup = (params = {}) => {
    setLoading(true);
    getEnquiryFollowup({
      ...params,
      type: "upcoming",
      branch_id: id.branch_id,
      counsellor_id: id.counsellor_id,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => message.error("Failed to load upcoming enquiry followup"))
      .finally(() => setLoading(false));
  };

  const loadGetFeePackagesByGroup = () => {
    // const arr = [];
    getFeePackagesByGroup({ pagination: false })
      .then((res) => {
        // for (const value of res.data.data) {
        //   arr.push({
        //     key: value.feeGroup,
        //     title: value.group_name.name,
        //     description: value.total,
        //   });
        // }
        // setFeePackages(arr);
        setFeePackages(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadLeadStages = () => {
    getLeadStage({ pagination: false }).then((res) => {
      setLeadStages(res.data.data);
    });
  };

  const handleAddFollowUp = (values) => {
    createEnquiryFollowup({
      ...values,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      student_id: drawerData.enquiry_id,
    })
      .then(() => {
        setDrawerOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const handleFollowUpHistory = (id) => {
    getEnquiryFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadGetFeePackagesByGroup();
    loadLeadStages();
  }, []);

  useEffect(() => {
    loadUpcomingEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.data}
        onRow={(record) => {
          return {
            onClick: () => {
              setDrawerData(record);
              setDrawerOpen(true);
              handleFollowUpHistory(record.enquiry_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page) => {
            loadUpcomingEnquiryFollowup({
              page,
            });
            setPage(page);
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.name}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_followup"
                label="Next Followup Required"
                initialValue="yes"
              >
                <Select placeholder="Please select next followup">
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>

              {formValues?.next_followup === "yes" && (
                <Form.Item
                  name="next_follow_up_date"
                  label="Next Followup Date"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    showTime
                    use12Hours
                    showSecond={false}
                    format="YYYY-MM-DD hh:mm A"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              {/* TODO: Add Form Item Name */}
              <Form.Item
                name="fee_package_id"
                label="Fee Package"
                initialValue={drawerData.feeGroup}
              >
                {/* <Transfer
                  style={{ width: "100%", height: "100%", marginBottom: 16 }}
                  dataSource={feePackages}
                  targetKeys={targetKeys}
                  selectedKeys={selectedKeys}
                  onChange={(nextTargetKeys, direction, moveKeys) => {
                    setTargetKeys(nextTargetKeys);
                  }}
                  onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                    setSelectedKeys([
                      ...sourceSelectedKeys,
                      ...targetSelectedKeys,
                    ]);
                  }}
                  listStyle={{
                    width: "100%",
                    height: 300,
                  }}
                  render={(item) => (
                    <Row justify={"space-between"}>
                      <span>{item.title}</span>
                      <span>
                        {item.description.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </span>
                    </Row>
                  )}
                /> */}

                <Select placeholder="Please select fee package">
                  {feePackages?.map((feePackage) => (
                    <Select.Option value={feePackage?.group_name?.id}>
                      {feePackage?.group_name?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="lead_stage"
                label="Lead Stage"
                initialValue={drawerData.lead_stage_id}
              >
                <Select placeholder="Please select lead stage">
                  {leadStages.map((stage) => (
                    <Select.Option value={stage.id}>{stage.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="remarks" label="Remarks">
                <Input.TextArea placeholder="" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  // dataIndex: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  // dataIndex: "followupDate",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  // dataIndex: "followupRemarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  // dataIndex: "followupBy",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function DoNotFollowup() {
  const id = useID();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const formValues = Form.useWatch([], form);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [activeKey, setActiveKey] = useState("addFollowup");
  const [feePackages, setFeePackages] = useState([]);
  // const [targetKeys, setTargetKeys] = useState([]);
  // const [selectedKeys, setSelectedKeys] = useState([]);
  const [leadStages, setLeadStages] = useState([]);
  const [followupHistory, setFollowupHistory] = useState([]);

  const loadDoNotEnquiryFollowup = (params = {}) => {
    setLoading(true);
    getEnquiryFollowup({
      ...params,
      type: "notfollowup",
      branch_id: id.branch_id,
      counsellor_id: id.counsellor_id,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => message.error("Failed to load enquiry followup"))
      .finally(() => setLoading(false));
  };

  const loadGetFeePackagesByGroup = () => {
    // const arr = [];
    getFeePackagesByGroup({ pagination: false })
      .then((res) => {
        // for (const value of res.data.data) {
        //   arr.push({
        //     key: value.feeGroup,
        //     title: value.group_name.name,
        //     description: value.total,
        //   });
        // }
        // setFeePackages(arr);
        setFeePackages(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const loadLeadStages = () => {
    getLeadStage({ pagination: false }).then((res) => {
      setLeadStages(res.data.data);
    });
  };

  const handleAddFollowUp = (values) => {
    createEnquiryFollowup({
      ...values,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      student_id: drawerData.enquiry_id,
    })
      .then(() => {
        setDrawerOpen(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const handleFollowUpHistory = (id) => {
    getEnquiryFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadGetFeePackagesByGroup();
    loadLeadStages();
  }, []);

  useEffect(() => {
    loadDoNotEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={[
          {
            key: "id",
            dataIndex: "enquiry_id",
            title: <ColTitle>ID</ColTitle>,
          },
          {
            key: "enquiry_date",
            title: <ColTitle>Enquiry Date</ColTitle>,
            render: (_) => <ColValue>{_?.enquiry_date}</ColValue>,
          },
          {
            key: "student",
            title: <ColTitle>Student</ColTitle>,
            render: (_) => <ColValue>{_?.name}</ColValue>,
          },
          {
            key: "mobile",
            title: <ColTitle>Mobile No</ColTitle>,
            render: (_) => <ColValue>{_?.mobile}</ColValue>,
          },
          {
            key: "area",
            title: <ColTitle>Area</ColTitle>,
            render: (_) => <ColValue>{_?.area_name}</ColValue>,
          },
          {
            key: "lead_stage",
            title: <ColTitle>Lead Stage</ColTitle>,
            render: (_) => <ColValue>{_?.lead_stage}</ColValue>,
          },
          {
            key: "status",
            title: <ColTitle>Status</ColTitle>,
            render: (_) => <ColValue>{_ ? "Active" : "Un-Active"}</ColValue>,
          },
        ]}
        dataSource={data.data}
        onRow={(record) => {
          return {
            onClick: () => {
              setDrawerData(record);
              setDrawerOpen(true);
              handleFollowUpHistory(record.enquiry_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page) => {
            loadDoNotEnquiryFollowup({
              page,
            });
            setPage(page);
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.name}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_followup"
                label="Next Followup Required"
                initialValue="yes"
              >
                <Select placeholder="Please select next followup">
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>

              {formValues?.next_followup === "yes" && (
                <Form.Item
                  name="next_follow_up_date"
                  label="Next Followup Date"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    showTime
                    use12Hours
                    showSecond={false}
                    format="YYYY-MM-DD hh:mm A"
                    style={{ width: "100%" }}
                    // disabledDate={(current) => {
                    //   return current && current < dayjs().endOf("day");
                    // }}
                  />
                </Form.Item>
              )}

              {/* <Form.Item name="responseType" label="Response Type">
                <Select placeholder="Please select response type">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* <Form.Item name="followupRemarks" label="Followup Remark">
                <Input.TextArea placeholder="Please enter followup remark" />
              </Form.Item> */}

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              {/* <Form.Item name="feePackage" label="Fee Package">
                <Select placeholder="Please select fee package">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              {/* Transfer */}

              <Form.Item
                name="fee_package_id"
                label="Fee Package"
                initialValue={drawerData.feeGroup}
              >
                {/* <Transfer
                  style={{ width: "100%", height: "100%", marginBottom: 16 }}
                  dataSource={feePackages}
                  targetKeys={targetKeys}
                  selectedKeys={selectedKeys}
                  onChange={(nextTargetKeys, direction, moveKeys) => {
                    setTargetKeys(nextTargetKeys);
                  }}
                  onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                    setSelectedKeys([
                      ...sourceSelectedKeys,
                      ...targetSelectedKeys,
                    ]);
                  }}
                  listStyle={{
                    width: "100%",
                    height: 300,
                  }}
                  render={(item) => (
                    <Row justify={"space-between"}>
                      <span>{item.title}</span>
                      <span>
                        {item.description.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </span>
                    </Row>
                  )}
                /> */}

                <Select placeholder="Please select fee package">
                  {feePackages?.map((feePackage) => (
                    <Select.Option value={feePackage?.group_name?.id}>
                      {feePackage?.group_name?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="lead_stage"
                label="Lead Stage"
                initialValue={drawerData.lead_stage_id}
              >
                <Select placeholder="Please select lead stage">
                  {leadStages.map((leadStage) => (
                    <Select.Option value={leadStage.id}>
                      {leadStage?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <Form.Item name="leadType" label="Lead Type">
                <Select placeholder="Please select lead type">
                  <Select.Option value="1">Static Value</Select.Option>
                </Select>
              </Form.Item> */}

              <Form.Item name="remarks" label="Remarks">
                <Input.TextArea placeholder="" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function RenderTable({ table, columns }) {
  switch (table) {
    case "today":
      return <TodayEnquiryFollowUpTable columns={columns} />;
    case "pending":
      return <PendingEnquiryFollowupTable columns={columns} />;
    case "upcoming":
      return <UpcomingEnquiryFollowUpTable columns={columns} />;
    case "doNotFollowup":
      return <DoNotFollowup columns={columns} />;
    default:
      return <TodayEnquiryFollowUpTable columns={columns} />;
  }
}

export default function EnquireFollowupPage() {
  const navigate = useNavigate();
  const [segmentValue, setSegmentValue] = useState("today");

  const columns = [
    {
      key: "id",
      dataIndex: "enquiry_id",
      title: <ColTitle>ID</ColTitle>,
    },
    {
      key: "due_date",
      title: <ColTitle>Due Date</ColTitle>,
      render: (_) => <ColValue>{_?.due_date}</ColValue>,
    },
    {
      key: "enquiry_date",
      title: <ColTitle>Enquiry Date</ColTitle>,
      render: (_) => <ColValue>{_?.enquiry_date}</ColValue>,
    },
    {
      key: "student",
      title: <ColTitle>Student</ColTitle>,
      render: (_) => <ColValue>{_?.name}</ColValue>,
    },
    {
      key: "mobile",
      title: <ColTitle>Mobile No</ColTitle>,
      render: (_) => <ColValue>{_?.mobile}</ColValue>,
    },
    {
      key: "area",
      title: <ColTitle>Area</ColTitle>,
      render: (_) => <ColValue>{_?.area_name}</ColValue>,
    },
    {
      key: "followup_by",
      title: <ColTitle>Followup By</ColTitle>,
      render: (_) => <ColValue>{_?.followup_taken_by}</ColValue>,
    },
    {
      key: "lead_stage",
      title: <ColTitle>Lead Stage</ColTitle>,
      render: (_) => <ColValue>{_?.lead_stage}</ColValue>,
    },
    {
      key: "remarks",
      title: <ColTitle>Remarks</ColTitle>,
      render: (_) => <span>{_?.student_info_remarks}</span>,
    },
    {
      key: "status",
      title: <ColTitle>Status</ColTitle>,
      render: (_) => <ColValue>{_ ? "Active" : "Un-Active"}</ColValue>,
    },
  ];

  return (
    <LayoutContainer>
      <Row justify="space-between">
        <Title style={{ marginTop: 0 }}>Enquiry Followup</Title>

        <Space>
          <Button
            onClick={() => {
              Modal.info({
                icon: null,
                title: "Advance Search",
                closable: true,
                maskClosable: true,
                content: (
                  <Form style={{ marginTop: 12 }} layout="vertical">
                    <Form.Item name="todaysDate" label="Todays Date">
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="feesPackage" label="Fees Package">
                      <Input />
                    </Form.Item>

                    <Form.Item name="student" label="Student">
                      <Input />
                    </Form.Item>

                    <Form.Item name="admissionStatus" label="Admission Status">
                      <Select mode="multiple">
                        <Select.Option value="active">Active</Select.Option>
                        <Select.Option value="pending">Pending</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="joiningFromDate" label="Joining From Date">
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="joiningTillDate" label="Joining Till Date">
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Form>
                ),
              });
            }}
          >
            Advance Search
          </Button>
          <Button onClick={() => navigate("/followup/fee-followup")}>
            Go to fees Followup
          </Button>
        </Space>
      </Row>

      <Segmented
        block
        size="large"
        options={[
          {
            label: <Text>Today's Due Followup(s)</Text>,
            value: "today",
          },
          {
            label: <Text>Pending Followup(s)</Text>,
            value: "pending",
          },
          {
            label: <Text>Upcoming Followup(s)</Text>,
            value: "upcoming",
          },
          {
            label: <Text>Do not Followup(s)</Text>,
            value: "doNotFollowup",
          },
        ]}
        onChange={(value) => setSegmentValue(value)}
        style={{ marginBottom: 20, backgroundColor: "#EBEBEB" }}
      />

      <RenderTable table={segmentValue} columns={columns} />
    </LayoutContainer>
  );
}
