export default function useAuth() {
  function isLoggedIn() {
    const l = localStorage.getItem("l");
    const u = localStorage.getItem("u");
    const t = localStorage.getItem("t");

    if (l && u && t) {
      return true;
    }
    return false;
  }

  function user() {
    const v = localStorage.getItem("u")
      ? JSON.parse(localStorage.getItem("u"))
      : {};
    return v;
  }

  function signout() {
    localStorage.clear();
    window.location.href = "/signin";
  }



  return {
    user: user(),
    isLoggedIn: isLoggedIn(),
    signout: signout,
  };
}
