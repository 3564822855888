import { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Row, Select } from "antd";
import { getCounsellorsByBranchId } from "../../../api/Counsellor";
import useID from "../../../hooks/useID.hook";
import dayjs from "dayjs";
import { ACADEMIC_YEAR } from "../../../constants";

export default function StepThree({
  form,
  state,
  formValues,
  currentFormStep,
  setCurrentFormStep,
}) {
  const id = useID();
  const [counsellers, setCounsellers] = useState([]);

  const loadCounsellers = () => {
    getCounsellorsByBranchId({ pagination: false }, id.branch_id)
      .then((res) => setCounsellers(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadCounsellers();
  }, [id.branch_id]);

  return (
    <>
      {/* <Form.Item
        name="employee"
        label="Admission Taken By"
        initialValue={formValues?.employee?.id}
        rules={[
          {
            required: true,
            message: "Please select admission taken by",
          },
        ]}
      >
        <Select placeholder="Please select admission taken by">
          {counsellers.map((counseller) => (
            <Select.Option key={counseller.id} value={counseller.id}>
              {counseller.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}

      {/* <Form.Item
        name="fee_package"
        rules={[
          {
            required: true,
            message: "Please select fee package",
          },
        ]}
      >
        <Select placeholder="Please select fee package">
          <Select.Option value="test">IELTS (G) - 8000</Select.Option>
        </Select>
      </Form.Item> */}

      <Form.Item
        name="idcard"
        label="ID Card/Roll No"
        initialValue={formValues?.idcard}
        rules={[
          {
            required: true,
            message: "Please enter id card/roll no",
          },
        ]}
      >
        <Input readOnly disabled placeholder="Please enter roll no" />
      </Form.Item>

      {/* <Form.Item
        name="registration_no"
        label="Registration No"
        initialValue={formValues?.registration_no}
        rules={[
          {
            required: true,
            message: "Please enter registration no",
          },
        ]}
      >
        <Input placeholder="Please enter roll no" />
      </Form.Item> */}

      {/* <Form.Item name="invoiceNo" label="Invoice No">
        <Input placeholder="Please enter invoice no" />
      </Form.Item> */}

      <Form.Item
        name="admission_date"
        label="Admission Date"
        initialValue={dayjs(formValues?.admission_date) ?? dayjs()}
        rules={[
          {
            required: true,
            message: "Please select admission date",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="academic_year"
        label="Academic Year"
        initialValue={formValues?.academic_year}
        rules={[
          {
            required: true,
            message: "Please select academic year",
          },
        ]}
      >
        <Select placeholder="Please select academic year">
          {ACADEMIC_YEAR.map((year) => (
            <Select.Option value={year}>{year}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="start_date"
        label="Start Date"
        initialValue={dayjs(formValues?.start_date) ?? dayjs()}
        rules={[
          {
            required: true,
            message: "Please select start date",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="expected_end_date"
        label="Expected End Date"
        initialValue={dayjs(formValues?.expected_end_date) ?? dayjs()}
        rules={[
          {
            required: true,
            message: "Please select expected end date",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      {/* {state === "create" && ( */}
      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          disabled={currentFormStep === 6}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>
      {/* )} */}
    </>
  );
}
