import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { useEffect, useId, useState } from "react";
import LayoutContainer from "../../components/layout/LayoutContainer";
import {
  getTodayFeeFollowup,
  getPendingFeeFollowup,
  getUpcomingFeeFollowup,
} from "../../api/EnquiryFollowup";
import { useNavigate } from "react-router-dom";
import ColTitle from "../../components/Table/ColTitle.component";
import dayjs from "dayjs";
import { PRIMARY_COLOR } from "../../constants";
import { createFeeFollowup, getFeeFollowupById } from "../../api/FeeFollowup";
import useID from "../../hooks/useID.hook";

const { Title, Text } = Typography;

function TodayEnquiryFollowUpTable({ columns }) {
  const id = useID();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [activeKey, setActiveKey] = useState("addFollowup");
  const [followupHistory, setFollowupHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadTodayEnquiryFollowup = (params) => {
    setLoading(true);
    getTodayFeeFollowup({
      ...params,
      type: "today",
      branch_id: id.branch_id,
      counsellor_id: id.counsellor_id,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoading(false));
  };

  const handleAddFollowUp = (values) => {
    createFeeFollowup({
      admission_id: drawerData.admission_id,
      student_id: drawerData.student_id,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      ...values,
    })
      .then((res) => {
        loadTodayEnquiryFollowup();
        setDrawerOpen(false);
        message.success("Followup added successfully");
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log(err);
      });
  };

  const handleFollowUpHistory = (id) => {
    getFeeFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadTodayEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.data}
        onRow={(record) => {
          return {
            onClick: () => {
              setDrawerData(record);
              setDrawerOpen(true);
              handleFollowUpHistory(record.admission_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page, pageSize) => {
            loadTodayEnquiryFollowup({
              page,
            });
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.student}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_follow_up_date"
                label="Next Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item name="followup_remarks" label="Followup Remarks">
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function PendingEnquiryFollowupTable({ columns }) {
  const id = useID();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [followupHistory, setFollowupHistory] = useState([]);
  const [activeKey, setActiveKey] = useState("addFollowup");
  const [loading, setLoading] = useState(false);

  const loadPendingEnquiryFollowup = (params = {}) => {
    setLoading(true);
    getPendingFeeFollowup({
      ...params,
      type: "past",
      branch_id: id.branch_id,
      counsellor_id: id.counsellor_id,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoading(false));
  };

  const handleAddFollowUp = (values) => {
    createFeeFollowup({
      admission_id: drawerData.admission_id,
      student_id: drawerData.student_id,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      ...values,
    })
      .then((res) => {
        loadPendingEnquiryFollowup();
        setDrawerOpen(false);
        message.success("Followup added successfully");
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log(err);
      });
  };

  const handleFollowUpHistory = (id) => {
    getFeeFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadPendingEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.data}
        onRow={(record) => {
          return {
            onClick: () => {
              setDrawerData(record);
              setDrawerOpen(true);
              handleFollowUpHistory(record.admission_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page) => {
            loadPendingEnquiryFollowup({
              page,
            });
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.student}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_follow_up_date"
                label="Next Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item name="followup_remarks" label="Followup Remarks">
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  // dataIndex: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  // dataIndex: "followupDate",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  // dataIndex: "followupRemarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  // dataIndex: "followupBy",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function UpcomingEnquiryFollowUpTable({ columns }) {
  const id = useID();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [followupHistory, setFollowupHistory] = useState([]);
  const [activeKey, setActiveKey] = useState("addFollowup");

  const handleAddFollowUp = (values) => {
    createFeeFollowup({
      admission_id: drawerData.admission_id,
      student_id: drawerData.student_id,
      branch_id: drawerData.branch_id,
      counselor_id: drawerData.counselor_id,
      ...values,
    })
      .then((res) => {
        loadUpcomingEnquiryFollowup();
        setDrawerOpen(false);
        message.success("Followup added successfully");
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log(err);
      });
  };

  const handleFollowUpHistory = (id) => {
    getFeeFollowupById(id)
      .then((res) => setFollowupHistory(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadUpcomingEnquiryFollowup = (params) => {
    setLoading(true);
    getUpcomingFeeFollowup({
      ...params,
      type: "upcoming",
    })
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadUpcomingEnquiryFollowup({ page: 1 });
  }, [id.branch_id]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.data}
        onRow={(record) => {
          return {
            onClick: () => {
              setDrawerData(record);
              setDrawerOpen(true);
              handleFollowUpHistory(record.admission_id);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          current: data.current_page,
          defaultCurrent: data.current_page,
          onChange: (page) => {
            loadUpcomingEnquiryFollowup({
              page,
            });
          },
        }}
      />

      <Drawer
        title={<div style={{ color: "white" }}>{drawerData.student}</div>}
        headerStyle={{
          backgroundColor: PRIMARY_COLOR,
        }}
        open={drawerOpen}
        size="large"
        onClose={() => setDrawerOpen(false)}
        bodyStyle={{ padding: "0px 14px" }}
      >
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane key="addFollowup" tab="Add Followup">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                handleAddFollowUp(values);
              }}
            >
              <Form.Item
                name="next_follow_up_date"
                label="Next Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item name="followup_remarks" label="Followup Remarks">
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                name="followup_date"
                label="Followup Date"
                initialValue={dayjs()}
              >
                <DatePicker
                  showTime
                  use12Hours
                  showSecond={false}
                  format="YYYY-MM-DD hh:mm A"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane key="followupHistory" tab="Followup History">
            <Table
              columns={[
                {
                  key: "type",
                  // dataIndex: "type",
                  title: "Type",
                  render: (_) => <Text>{_?.type ?? "N/A"}</Text>,
                },
                {
                  key: "next_follow_up_date",
                  // dataIndex: "followupDate",
                  title: "Followup Date",
                  render: (_) => <Text>{_?.next_follow_up_date ?? "N/A"}</Text>,
                },
                {
                  key: "followup_remarks",
                  // dataIndex: "followupRemarks",
                  title: "Followup Remarks",
                  render: (_) => <Text>{_?.followup_remarks ?? "N/A"}</Text>,
                },
                {
                  key: "followup_taken_by",
                  // dataIndex: "followupBy",
                  title: "Followup By",
                  render: (_) => <Text>{_?.followup_taken_by ?? "N/A"}</Text>,
                },
              ]}
              dataSource={followupHistory}
            />
          </Tabs.TabPane>
        </Tabs>
        {/* <pre>{JSON.stringify(drawerData, null, 4)}</pre> */}
      </Drawer>
    </>
  );
}

function RenderTable({ table, columns }) {
  switch (table) {
    case "today":
      return <TodayEnquiryFollowUpTable columns={columns} />;
    case "pending":
      return <PendingEnquiryFollowupTable columns={columns} />;
    case "upcoming":
      return <UpcomingEnquiryFollowUpTable columns={columns} />;
    default:
      return <TodayEnquiryFollowUpTable columns={columns} />;
  }
}

export default function FeeFollowupPage() {
  const navigate = useNavigate();
  const [segmentValue, setSegmentValue] = useState("today");

  const columns = [
    {
      title: <ColTitle>ID</ColTitle>,
      dataIndex: "id",
      key: "id",
    },
    {
      title: <ColTitle>Followup Due Date</ColTitle>,
      dataIndex: "followup_due_date",
      key: "followup_due_date",
    },
    {
      title: <ColTitle>Fees Due Date</ColTitle>,
      dataIndex: "due_date",
      key: "due_date",
    },
    {
      title: <ColTitle>Student</ColTitle>,
      dataIndex: "student",
      key: "student",
    },
    {
      title: <ColTitle>Student Mobile No</ColTitle>,
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: <ColTitle>ID Card</ColTitle>,
      dataIndex: "idcard",
      key: "idcard",
    },
    {
      title: <ColTitle>Due Amount</ColTitle>,
      dataIndex: "pending_amount",
      key: "pending_amount",
    },
    {
      title: <ColTitle>Followup By</ColTitle>,
      dataIndex: "followup_by",
      key: "followup_by",
    },
  ];

  return (
    <LayoutContainer>
      <Row justify="space-between">
        <Title style={{ marginTop: 0 }}>Fee Followup</Title>

        <Space>
          <Button onClick={() => navigate("/followup")}>
            Go to enquiry Followup
          </Button>
        </Space>
      </Row>

      <Segmented
        block
        size="large"
        options={[
          {
            label: <Text>Today's Due Followup(s)</Text>,
            value: "today",
          },
          {
            label: <Text>Pending Followup(s)</Text>,
            value: "pending",
          },
          {
            label: <Text>Upcoming Followup(s)</Text>,
            value: "upcoming",
          },
        ]}
        onChange={(value) => setSegmentValue(value)}
        style={{ marginBottom: 20, backgroundColor: "#EBEBEB" }}
      />

      <RenderTable table={segmentValue} columns={columns} />
    </LayoutContainer>
  );
}
