import { Button, Form, Input, Modal, Row, Table, Typography } from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import {
  getEducation,
  deleteEducation,
  createEducation,
} from "../../../api/Education";
import { useEffect, useState } from "react";

const { Title } = Typography;

export default function EductionPage() {
  const [data, setData] = useState([]);
  const [loadingEducation, setLoadingEducation] = useState(false);
  const [addEducationModalOpen, setAddEducationModalOpen] = useState(false);

  const loadEducation = (params = {}) => {
    setLoadingEducation(true);
    getEducation(params)
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoadingEducation(false));
  };

  const handleCreateEducation = (values) => {
    createEducation(values)
      .then((res) => {
        loadEducation();
        setAddEducationModalOpen(false);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString("en-GB"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Button
            danger
            onClick={() => {
              deleteEducation(_.id);
              loadEducation();
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    loadEducation();
  }, []);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}> Education</Title>

        <Button onClick={() => setAddEducationModalOpen(true)}>Add New</Button>
      </Row>

      <Table
        columns={columns}
        dataSource={data.data}
        loading={loadingEducation}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data.per_page,
          total: data.total,
          onChange: (page, pageSize) => {
            loadEducation({
              page,
            });
          },
        }}
      />

      <Modal
        open={addEducationModalOpen}
        onCancel={() => setAddEducationModalOpen(false)}
        footer={null}
        title="Add Education"
      >
        <Form layout="vertical" onFinish={handleCreateEducation}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter lead source name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
