import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { MdDelete, MdOutlineVisibility } from "react-icons/md";
import { useLoaderData } from "react-router-dom";
import { upperFirst } from "lodash";

const { Title } = Typography;

const OverviewTab = () => {
  const loaderData = useLoaderData();

  return (
    <>
      {/* <pre>{JSON.stringify(loaderData, null, 3)}</pre> */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            title="Student Details"
            headStyle={{
              padding: "0 16px",
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Student Name">
                {loaderData.name}
              </Descriptions.Item>
              <Descriptions.Item label="Birth Date">
                {new Date(loaderData.dob).toLocaleDateString() ?? "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Gender">
                {loaderData?.gender}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Gender">{loaderData}</Descriptions.Item> */}
              <Descriptions.Item label="Martial Status">
                {upperFirst(loaderData?.marital_status)}
              </Descriptions.Item>
              <Descriptions.Item label="Education">
                {
                  loaderData?.education[loaderData?.education.length - 1].name
                    .name
                }
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            title="Student Contact Details"
            headStyle={{
              padding: "0 16px",
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Country">
                {loaderData?.country?.name}
              </Descriptions.Item>
              <Descriptions.Item label="State">
                {loaderData?.state?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Area">
                {loaderData?.area?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Present Address">
                {loaderData?.address}
              </Descriptions.Item>
              <Descriptions.Item label="Zip Code">
                {loaderData?.zipcode}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile No.">
                {loaderData?.mobile}
              </Descriptions.Item>

              {/* <Descriptions.Item label="E-Mail"></Descriptions.Item> */}
              {/* <Descriptions.Item label="WhatsApp Number">
                9779015902
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="Student Login Password">
                D1TP7Z
              </Descriptions.Item> */}
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            title="Enquiry Details"
            headStyle={{
              padding: "0 16px",
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Descriptions bordered size="small" column={1}>
              {/* <Descriptions.Item label="Enquriy Date">
                {loaderData?.enquiry?.enquiry_date}
              </Descriptions.Item>
              <Descriptions.Item label="Enquiry Id">
                {loaderData?.enquiry?.id}
              </Descriptions.Item> */}
              <Descriptions.Item label="Enquiry Taken By">
                {loaderData?.enquiry?.counsellor.id}
              </Descriptions.Item>
              <Descriptions.Item label="Fees Package">STATIC</Descriptions.Item>
              <Descriptions.Item label="Standard">STATIC</Descriptions.Item>
              <Descriptions.Item label="Lead Source">
                {loaderData?.enquiry?.lead_source?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Lead Stage">
                {loaderData?.enquiry?.lead_stage?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Bank">
                {loaderData?.bank}
              </Descriptions.Item>
              <Descriptions.Item label="Bank Account Number">
                {loaderData?.bank_account}
              </Descriptions.Item>
              <Descriptions.Item label="IFSC Code">
                {loaderData?.bank_ifsc_code}
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
                {loaderData?.enquiry?.remarks}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            title="Parents Details"
            headStyle={{
              padding: "0 16px",
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Father Name">
                {loaderData?.parent_details?.father_name}
              </Descriptions.Item>
              <Descriptions.Item label="Mother Name">
                {loaderData?.parent_details?.mother_name}
              </Descriptions.Item>
              <Descriptions.Item label="Primary Mobile">
                {loaderData?.parent_details?.primary_mobile_no}
              </Descriptions.Item>
              <Descriptions.Item label="Secondary Mobile">
                {loaderData?.parent_details?.secondary_mobile_no}
              </Descriptions.Item>
              <Descriptions.Item label="Parent Email">
                {loaderData?.parent_details?.email}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            title="Record Details"
            headStyle={{
              padding: "0 16px",
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Created By">
                {loaderData?.created_by?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Created Date">
                {loaderData?.created_at}
              </Descriptions.Item>
              <Descriptions.Item label="Update Date">
                {loaderData?.updated_at}
              </Descriptions.Item>
              <Descriptions.Item label="Counsellor Name">
                {loaderData?.enquiry?.counsellor?.name}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        {/* <Col span={12}>
          <Card
            title="Enquiry Assigment"
            headStyle={{
              padding: "0 16px",
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <Descriptions bordered size="small" column={1}>
       
              <Descriptions.Item label="Assignment Date"></Descriptions.Item>
            </Descriptions>
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

const AdmissionTab = () => {
  return (
    <Table
      columns={[
        {
          key: "admission_date",
          dataIndex: "admission_date",
          title: "Admission Date",
        },
        {
          key: "student",
          dataIndex: "student",
          title: "Student",
        },
        {
          key: "mobile",
          dataIndex: "mobile",
          title: "Mobile",
        },
        {
          key: "academic_year",
          dataIndex: "academic_year",
          title: "Academic Year",
        },
        {
          key: "fee_package",
          dataIndex: "fee_package",
          title: "Fee Package",
        },
        {
          key: "total",
          dataIndex: "total",
          title: "Total",
        },
        {
          key: "action",
          title: "Action",
          render: (_, record) => {
            return (
              <Space>
                <Tooltip title="View">
                  <Button icon={<MdOutlineVisibility />} />
                </Tooltip>
              </Space>
            );
          },
        },
      ]}
      dataSource={[]}
    />
  );
};

const ReceiptTab = () => {
  return (
    <Table
      columns={[
        {
          key: "receipt_date",
          dataIndex: "receipt_date",
          title: "Receipt Date",
        },
        {
          key: "receipt_no",
          dataIndex: "receipt_no",
          title: "Receipt No",
        },
        {
          key: "student_name",
          dataIndex: "student_name",
          title: "Student Name",
        },
        {
          key: "payment_mode",
          dataIndex: "payment_mode",
          title: "Payment Mode",
        },
        {
          key: "amount",
          dataIndex: "amount",
          title: "Amount",
        },
        {
          key: "user_name",
          dataIndex: "user_name",
          title: "User Name",
        },
        {
          key: "action",
          title: "Action",
          render: (_, record) => {
            return (
              <Space>
                <Tooltip title="View">
                  <Button icon={<MdOutlineVisibility />} />
                </Tooltip>
              </Space>
            );
          },
        },
      ]}
      dataSource={[
        {
          receipt_date: "12-Apr-2023",
          receipt_no: "1185",
          student_name: "RAVLEEN KAUR",
          payment_mode: "Cash",
          amount: "2500",
          user_name: "aditya.grover",
        },
        {
          receipt_date: "12-Apr-2023",
          receipt_no: "1184",
          student_name: "RAVLEEN KAUR",
          payment_mode: "NEFT/RTGS/IMPS/NB",
          amount: "11500",
          user_name: "ff.barnala",
        },
      ]}
      summary={(pageData) => {
        let totalAmount = 0;

        pageData.forEach(({ amount }) => {
          totalAmount += Number(amount);
        });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3}>
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text strong>{totalAmount}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} />
              <Table.Summary.Cell index={6} />
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

const OtherInvoice = () => {
  return (
    <Table
      columns={[
        {
          key: "date",
          dataIndex: "date",
          title: "Date",
        },
        {
          key: "invoice",
          dataIndex: "invoice",
          title: "Invoice",
        },
        {
          key: "net_amount",
          dataIndex: "net_amount",
          title: "Net Amount",
        },
        {
          key: "grand_total",
          dataIndex: "grand_total",
          title: "Grand Total",
        },
        {
          key: "paid_amount",
          dataIndex: "paid_amount",
          title: "Paid Amount",
        },
        {
          key: "balance_amount",
          dataIndex: "balance_amount",
          title: "Balance Amount",
        },
        {
          key: "username",
          dataIndex: "username",
          title: "Username",
        },
        {
          key: "action",
          title: "Action",
          render: (_, record) => {
            return (
              <Space>
                <Tooltip title="View">
                  <Button icon={<MdOutlineVisibility />} />
                </Tooltip>
              </Space>
            );
          },
        },
      ]}
      dataSource={[]}
      summary={(pageData) => {
        let totalAmount = 0;

        pageData.forEach(({ amount }) => {
          totalAmount += Number(amount);
        });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1}>
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <Typography.Text strong>{totalAmount}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text strong>{totalAmount}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text strong>{totalAmount}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text strong>{totalAmount}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} />
              <Table.Summary.Cell index={7} />
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

const FollowUpTab = () => {
  return (
    <Table
      columns={[
        {
          key: "type",
          dataIndex: "type",
          title: "Type",
        },
        {
          key: "followup_date",
          dataIndex: "followup_date",
          title: "Followup Date",
        },
        {
          key: "followup_remarks",
          dataIndex: "followup_remarks",
          title: "Followup Remarks",
        },
        {
          key: "followup_by",
          dataIndex: "followup_by",
          title: "Followup By",
        },
        {
          key: "action",
          title: "Action",
          render: (_, record) => {
            return (
              <Space>
                <Tooltip title="View">
                  <Button icon={<MdOutlineVisibility />} />
                </Tooltip>
              </Space>
            );
          },
        },
      ]}
      dataSource={[]}
    />
  );
};

// ------
const SentSMSReportTab = () => {
  return (
    <Table
      columns={[
        {
          key: "sms_sent_date",
          dataIndex: "sms_sent_date",
          title: "SMS Sent Date",
        },
        {
          key: "mobile",
          dataIndex: "mobile",
          title: "Mobile",
        },
        {
          key: "message",
          dataIndex: "message",
          title: "Message",
        },
        {
          key: "sender_id",
          dataIndex: "sender_id",
          title: "Sender ID",
        },
        {
          key: "route",
          dataIndex: "route",
          title: "Route",
        },
        {
          key: "status",
          dataIndex: "status",
          title: "Status",
        },
        {
          key: "sms_sent_by",
          dataIndex: "sms_sent_by",
          title: "SMS Sent By",
        },
      ]}
      dataSource={[]}
    />
  );
};

const EmailSentReportTab = () => {
  return (
    <Table
      columns={[
        {
          key: "email_sent_date",
          dataIndex: "email_sent_date",
          title: "Email Sent Date",
        },
        {
          key: "email_id",
          dataIndex: "email_id",
          title: "E-mail ID",
        },
        {
          key: "sender_id",
          dataIndex: "sender_id",
          title: "Sender ID",
        },
        {
          key: "route",
          dataIndex: "route",
          title: "Route",
        },
        {
          key: "status",
          dataIndex: "status",
          title: "Status",
        },
        {
          key: "email_sent_by",
          dataIndex: "email_sent_by",
          title: "E-mail Sent By",
        },
        {
          key: "view",
          dataIndex: "view",
          title: "View",
        },
      ]}
      dataSource={[]}
    />
  );
};

const ProgressReportCardTab = () => {
  return (
    <Table
      scroll={{
        x: true,
      }}
      columns={[
        {
          key: "standard",
          dataIndex: "standard",
          title: "Standard",
        },
        {
          key: "exam_term",
          dataIndex: "exam_term",
          title: <p style={{ width: "max-content" }}>Exam Term</p>,
        },
        {
          key: "subject",
          dataIndex: "subject",
          title: <p style={{ width: "max-content" }}>Subject</p>,
        },
        {
          key: "exam_mode",
          dataIndex: "exam_mode",
          title: <p style={{ width: "max-content" }}>Exam Mode</p>,
        },
        {
          key: "exam_date",
          dataIndex: "exam_date",
          title: <p style={{ width: "max-content" }}>Exam Date</p>,
        },
        {
          key: "online_exam_total_marks",
          dataIndex: "online_exam_total_marks",
          title: (
            <p style={{ width: "max-content" }}>Online Exam Total Marks</p>
          ),
        },
        {
          key: "online_exam_passing_marks",
          dataIndex: "online_exam_passing_marks",
          title: (
            <p style={{ width: "max-content" }}>Online Exam Passing Marks</p>
          ),
        },
        {
          key: "online_exam_obtain_marks",
          dataIndex: "online_exam_obtain_marks",
          title: <p style={{ width: "max-content" }}>Online Exam Obt. Marks</p>,
        },
        {
          key: "practical_exam_total_marks",
          dataIndex: "practical_exam_total_marks",
          title: (
            <p style={{ width: "max-content" }}>Practical Exam Total Marks</p>
          ),
        },
        {
          key: "practical_exam_passing_marks",
          dataIndex: "practical_exam_passing_marks",
          title: (
            <p style={{ width: "max-content" }}>Practical Exam Passing Marks</p>
          ),
        },
        {
          key: "practical_exam_obtain_marks",
          dataIndex: "practical_exam_obtain_marks",
          title: (
            <p style={{ width: "max-content" }}>Practical Exam Obt. Marks</p>
          ),
        },
        {
          key: "total_obtain_marks",
          dataIndex: "total_obtain_marks",
          title: <p style={{ width: "max-content" }}>Total Obt. Marks</p>,
        },
        {
          key: "result_status",
          dataIndex: "result_status",
          title: <p style={{ width: "max-content" }}>Result Status</p>,
        },
      ]}
      dataSource={[]}
    />
  );
};

const StudentAttendanceTab = () => {
  return (
    <Table
      scroll={{
        x: true,
      }}
      columns={[
        {
          key: "attendance_date",
          dataIndex: "attendance_date",
          title: <p style={{ width: "max-content" }}>Attendance Date</p>,
        },
        {
          key: "time_slot",
          dataIndex: "time_slot",
          title: <p style={{ width: "max-content" }}>Time Slot</p>,
        },
        {
          key: "standard",
          dataIndex: "standard",
          title: <p style={{ width: "max-content" }}>Standard</p>,
        },
        {
          key: "subject",
          dataIndex: "subject",
          title: <p style={{ width: "max-content" }}>Subject</p>,
        },
        {
          key: "class_room",
          dataIndex: "class_room",
          title: <p style={{ width: "max-content" }}>Class Room</p>,
        },
        {
          key: "chapter",
          dataIndex: "chapter",
          title: <p style={{ width: "max-content" }}>Chapter</p>,
        },
        {
          key: "lecture_duration",
          dataIndex: "lecture_duration",
          title: <p style={{ width: "max-content" }}>Lecture Durationd(Min)</p>,
        },
        {
          key: "present",
          dataIndex: "present",
          title: <p style={{ width: "max-content" }}>Present</p>,
        },
        {
          key: "absent",
          dataIndex: "absent",
          title: <p style={{ width: "max-content" }}>Absent</p>,
        },
        {
          key: "leave",
          dataIndex: "leave",
          title: <p style={{ width: "max-content" }}>Leave</p>,
        },
      ]}
      dataSource={[]}
    />
  );
};

const AccountingJournalTab = () => {
  return (
    <Table
      columns={[
        {
          key: "account",
          dataIndex: "account",
          title: "Account",
        },
        {
          key: "debit",
          dataIndex: "debit",
          title: "Debit",
        },
        {
          key: "credit",
          dataIndex: "credit",
          title: "Credit",
        },
      ]}
      dataSource={[
        {
          account: "Sundry Debtors",
          debit: "28000",
          credit: "",
        },
        {
          account: "Sales",
          debit: "",
          credit: "28000",
        },
      ]}
      summary={(pageData) => {
        let totalDebit = 0;
        let totalCredit = 0;

        pageData.forEach(({ debit, credit }) => {
          totalDebit += Number(debit);
          totalCredit += Number(credit);
        });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={3}>
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text strong>{totalDebit}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text strong>{totalCredit}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

const RecentActivitiesTab = () => {
  return (
    <Table
      columns={[
        {
          key: "date",
          dataIndex: "date",
          title: "Date",
        },
        {
          key: "type",
          dataIndex: "type",
          title: "Type",
        },
        {
          key: "audit_value",
          dataIndex: "audit_value",
          title: "Audit Value",
        },
        {
          key: "employee",
          dataIndex: "employee",
          title: "Employee",
        },
        {
          key: "message",
          dataIndex: "message",
          title: "Message",
        },
        {
          key: "ip_address",
          dataIndex: "ip_address",
          title: "IP Address",
        },
      ]}
      dataSource={[
        {
          date: "12-Apr-2023 4:34 PM",
          type: "Created",
          audit_value: "807-RAVLEEN KAUR for 1381467",
          employee: "ff.barnala",
          message: "",
          ip_address: "203.134.200.129",
        },
      ]}
    />
  );
};

const OtherCertificatesTab = () => {
  return (
    <Table
      columns={[
        {
          key: "created_date",
          dataIndex: "created_date",
          title: "Created Date",
        },
        {
          key: "certificate_type",
          dataIndex: "certificate_type",
          title: "Certificate Type",
        },
        {
          key: "action",
          title: "Action",
          render: (_, record) => {
            return (
              <Space>
                <Button icon={<MdDelete />} />
              </Space>
            );
          },
        },
      ]}
      dataSource={[]}
    />
  );
};

export default function LeadsByIDPage({ id }) {
  const loaderData = useLoaderData();
  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Lead</Title>
      </Row>

      <Card title={loaderData?.name}>
        <Tabs
          items={[
            {
              key: "overview",
              label: "Overview",
              children: <OverviewTab />,
            },
            {
              key: "admission",
              label: "Admission",
              children: <AdmissionTab />,
            },
            // {
            //   key: "receipt",
            //   label: "Receipt",
            //   children: <ReceiptTab />,
            // },
            // {
            //   key: "other_invoice",
            //   label: "Other Invoice",
            //   children: <OtherInvoice />,
            // },
            // {
            //   key: "time_table",
            //   label: "Time Table",
            //   disabled: true,
            // },
            // {
            //   key: "followups",
            //   label: "Followups",
            //   children: <FollowUpTab />,
            // },
            // {
            //   key: "attendance",
            //   label: "Attendance",
            //   children: <StudentAttendanceTab />,
            // },
            // {
            //   key: "progress_report_card",
            //   label: "Progress Report Card",
            //   children: <ProgressReportCardTab />,
            // },
            // {
            //   key: "sms_sent_report",
            //   label: "SMS Sent Report",
            //   children: <SentSMSReportTab />,
            // },
            // {
            //   key: "email_sent_report",
            //   label: "Email Sent Report",
            //   children: <EmailSentReportTab />,
            // },
            // {
            //   key: "recent_activities",
            //   label: "Recent Activites",
            //   children: <RecentActivitiesTab />,
            // },
          ]}
        />
      </Card>
    </LayoutContainer>
  );
}
