import axios from "axios";
import { BASE_URL } from "../../constants";

export function getFeePackages(params) {
  return axios.get(`${BASE_URL}/fee_package`, {
    params,
  });
}

export function getFeePackagesByGroup(params) {
  return axios.get(`${BASE_URL}/fee_package/fee_by_group`, {
    params,
  });
}

export function getFeePackagesByGroupId(id, params) {
  return axios.get(`${BASE_URL}/fee_package/group/${id}`, {
    params,
  });
}

export function createFeePackage(values) {
  return axios.post(`${BASE_URL}/fee_package`, values);
}

export function getFeePackageById(id) {
  return axios.get(`${BASE_URL}/fee_package/${id}`);
}

export function updateFeePackage(id, values) {
  return axios.put(`${BASE_URL}/fee_package/${id}`, values);
}

export function deleteFeePackage(id) {
  return axios.delete(`${BASE_URL}/fee_package/${id}`);
}
