import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Form, Input, message } from "antd";
import signIn from "../../api/Authentication/signin.api";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { PRIMARY_COLOR } from "../../constants";

export default function SignInPage() {
  const navigate = useNavigate();

  const handleSignIn = async (values) => {
    try {
      const res = await signIn(values);
      const { authorisation, user } = res?.data;

      localStorage.setItem("l", true);
      localStorage.setItem("u", JSON.stringify(user));
      localStorage.setItem("t", authorisation.token);

      message.success({
        content: "Login successfully",
      });

      navigate("/");
    } catch (error) {
      localStorage.setItem("l", false);
      localStorage.setItem("u", "");
      localStorage.setItem("t", "");

      message.error({
        content: error.response.data.message,
      });
    }
  };

  return (
    <LayoutContainer>
      <div style={{ display: "block", height: "100%" }}>
        <div
          style={{
            height: "inherit",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card style={{ width: "100%", maxWidth: "640px" }}>
            {/* <div style={{ marginBottom: 20 }}>
              <Alert type="warning" message="You have been logged out" />
            </div> */}

            <Form onFinish={handleSignIn} layout="vertical">
              <Form.Item
                required
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email address is required",
                  },
                  // {
                  //   type: "email",
                  //   message: "Please enter valid email address",
                  // },
                ]}
              >
                <Input placeholder="Please enter your email address" />
              </Form.Item>

              <Form.Item
                required
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password placeholder="Please enter your password" />
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 20,
                }}
              >
                <Link style={{ color: PRIMARY_COLOR }}>Forgot Password?</Link>
              </div>

              <Button type="primary" htmlType="submit">
                Sign In
              </Button>
            </Form>
          </Card>
        </div>
      </div>
    </LayoutContainer>
  );
}
