import axios from "axios";
import { BASE_URL } from "../../constants";

export function getAdmission(params, branchId, counselorId) {
  return axios.get(`${BASE_URL}/admission`, {
    params,
  });
}

export function studentByAdmission(params, branchId, counselorId) {
  return axios.get(`${BASE_URL}/invoices/studentsByAdmissions`, {
    params: {
      ...params,
      branch_id: branchId,
      counselor_id: counselorId,
    },
  });
}

export function createAdmission(values) {
  return axios.post(`${BASE_URL}/admission`, values);
}

export function getIdCardNumber(branchId) {
  return axios.get(`${BASE_URL}/get_idcard_number`, {
    params: {
      branch_id: branchId,
    },
  });
}

export function getAdmissionById(id) {
  return axios.get(`${BASE_URL}/admission/${id}`);
}

export function updateAdmission(id, values) {
  return axios.put(`${BASE_URL}/admission/${id}`, values);
}

export function deleteAdmission(id) {
  return axios.delete(`${BASE_URL}/admission/${id}/counselor/${id}`);
}
