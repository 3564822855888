import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { BASE_URL } from "../../../constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { createParentInfo, getParentInfo } from "../../../api/ParentInfo";
import dayjs from "dayjs";
import { useBranches } from "../../../hooks/useBranches.hook";
import { getCounsellorsByBranchId } from "../../../api/Counsellor";
import useID from "../../../hooks/useID.hook";
import useAuth from "../../../hooks/useAuth.hook";

export default function StepOne({ form, currentFormStep, setCurrentFormStep }) {
  const id = useID();
  const auth = useAuth();
  const [createParentForm] = Form.useForm();
  const [parent, setParent] = useState();
  const [fileList, setFileList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState();
  const [counsellers, setCounsellers] = useState([]);
  const [creatingParentInfo, setCreatingParentInfo] = useState(false);
  const { branches, selectedBranch, loadingBranches } = useBranches(
    (state) => state
  );

  const loadParent = () => {
    getParentInfo({ pagination: false }, id.branch_id, "all").then((res) =>
      setParent(res.data.data)
    );
  };

  const handleParentInfo = (values) => {
    setCreatingParentInfo(true);
    createParentInfo({
      ...values,
      branch_id: values.branch_id ?? id.branch_id,
    })
      .then((res) => {
        // setData([...data, res.data]);
        createParentForm.resetFields([]);
        message.success("Parent created successfully");
        setDrawerOpen(false);
      })
      .catch((err) => message.error("Something went wrong"))
      .finally(() => {
        setCreatingParentInfo(false);
        loadParent();
      });
  };

  const loadCounsellers = (counsellor_id) => {
    getCounsellorsByBranchId({ pagination: false }, counsellor_id)
      .then((res) => setCounsellers(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadParent();

    if (id?.branch_id !== "all") {
      loadCounsellers(id.branch_id);
    }

    createParentForm.setFieldValue("counselor_id", null);
  }, [id.branch_id]);

  useEffect(() => {
    if (auth.user.user_type === "counselor") {
      createParentForm.setFieldValue("counselor_id", auth.user.id);
    }
  }, []);

  return (
    <>
      <Form.Item
        name="image"
        label="Profile Photo"
        // rules={[
        //   {
        //     required: true,
        //     message: "Profile Photo is required",
        //   },
        // ]}
      >
        <Upload
          name="image"
          maxCount={1}
          multiple={false}
          accept="image/*"
          // showUploadList={false}
          fileList={fileList}
          listType="picture-card"
          className="avatar-uploader"
          customRequest={async (options) => {
            const formData = new FormData();
            formData.append("image", options.file);

            const res = await axios.post(
              `${BASE_URL}/students_upload_profile_pic`,
              formData
            );

            await form.setFieldValue("image", res.data.data[0].image_url);

            setFileList([
              {
                uid: "58062d84-a211-43a7-bd36-68000102a33e",
                name: res?.data?.data?.[0]?.image_original_name,
                percent: "100",
                status: "success",
                thumbUrl: res?.data?.data?.[0]?.image_full_url,
                url: res?.data?.data?.[0]?.image_full_url,
              },
            ]);
          }}
        >
          {/* {values.image ? (
            <Image
              width={100}
              height={100}
              preview={false}
              style={{ borderRadius: 8 }}
              src={`https://erp-api.flyingfeathers.com/public/${values.image}`}
            />
          ) : ( */}
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
          {/* )} */}
        </Upload>
      </Form.Item>

      <Form.Item
        name="name"
        label="Student/Trainee Name"
        rules={[
          {
            required: true,
            message: "Student/Trainee name is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="parent_id"
        label="Parent Name"
        rules={[
          {
            required: true,
            message: "Parent name is required",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Select
          showSearch
          optionFilterProp="children"
          style={{ width: "100%" }}
        >
          {parent?.map((v) => (
            <Select.Option key={v.id} value={v.id}>
              {v.fathersName} | {v.primary_phone}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Row justify="end" style={{ marginBottom: 14 }}>
        <Button icon={<PlusOutlined />} onClick={() => setDrawerOpen(true)}>
          Add Parent
        </Button>
      </Row>

      <Form.Item
        name="gender"
        label="Gender"
        rules={[
          {
            required: true,
            message: "Select Gender is Required ",
          },
        ]}
      >
        <Select placeholder="Please select Gender">
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="dob"
        label="Birth Date"
        rules={[
          {
            required: true,
            message: "Date of birth is required",
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%" }}
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
        />
      </Form.Item>

      {/* <Form.Item name="gender" label="Gender">
        <Select>
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
        </Select>
      </Form.Item> */}

      <Form.Item
        name="marital_status"
        label="Martial Status"
        rules={[
          {
            required: true,
            message: "Martial status is required",
          },
        ]}
      >
        <Select>
          <Select.Option value="married">Married</Select.Option>
          <Select.Option value="unmarried">Un-Married</Select.Option>
        </Select>
      </Form.Item>

      {/* <Form.Item name="" label="Blood Group">
        <Select>
          <Select.Option value="b+">B+</Select.Option>
        </Select>
      </Form.Item> */}

      {/* <Form.Item name="" label="Religion">
        <Select>
          <Select.Option value="Sikh">Sikh</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="" label="Caste">
        <Select>
          <Select.Option value="general">General</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="" label="Sub Caste">
        <Select>
          <Select.Option value=""></Select.Option>
        </Select>
      </Form.Item> */}

      {/* <Form.Item name="" label="Medium">
        <Select>
          <Select.Option value=""></Select.Option>
        </Select>
      </Form.Item> */}

      {/* <Form.Item
        name="education_id"
        label="Education"
        rules={[
          {
            required: true,
            message: "Education is required",
          },
        ]}
      >
        <Select>
          {education?.map((v) => (
            <Select.Option key={v.id} value={v.id}>
              {v.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}

      <Form.Item
        name="college"
        label="College/School/Company"
        rules={[
          {
            required: true,
            message: "College/School/Company name is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Row align="end" style={{ gap: 10 }}>
        <Button
          type="dashed"
          disabled={currentFormStep === 0}
          onClick={() => setCurrentFormStep(currentFormStep - 1)}
        >
          Prev
        </Button>

        <Button
          type="primary"
          htmlType="button"
          disabled={currentFormStep === 4}
          onClick={async () => {
            await form.validateFields();
            setCurrentFormStep(currentFormStep + 1);
          }}
        >
          Next
        </Button>
      </Row>

      <Drawer
        title="Parent Info"
        placement="right"
        onClose={() => {
          setDrawerOpen(false);
          // form.resetFields();
        }}
        open={drawerOpen}
        footer={
          <Row justify={"end"}>
            <Button
              loading={creatingParentInfo}
              type="primary"
              onClick={() => createParentForm.submit()}
            >
              Add
            </Button>
          </Row>
        }
      >
        <Form
          scrollToFirstError
          layout="vertical"
          form={createParentForm}
          onFinish={(values) => handleParentInfo(values)}
        >
          <Form.Item
            name="fathersName"
            label="Father Name"
            rules={[
              {
                required: true,
                message: "Please enter father's name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="mothersName"
            label="Mother Name"
            rules={[
              {
                required: false,
                message: "Please enter mother's name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="primary_phone"
            label="Primary Mobile No"
            rules={[
              {
                required: true,
                message: "Please enter primary mobile number",
              },
            ]}
          >
            <Input addonBefore="+91" />
          </Form.Item>

          <Form.Item name="secondary_phone" label="Secondary Mobile No">
            <Input addonBefore="+91" />
          </Form.Item>

          <Form.Item
            name="occupation"
            label="Occupation"
            rules={[
              {
                required: false,
                message: "Please enter occupation",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: false,
                // message: "Email address is required",
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {id.branch_id === "all" && (
            <Form.Item
              label="Branch"
              name="branch_id"
              initialValue={id.branch_id === "all" ? null : id.branch_id}
              rules={[
                {
                  required: true,
                  message: "Please select branch",
                },
              ]}
            >
              <Select
                showSearch
                loading={loadingBranches}
                optionFilterProp="children"
                placeholder="Please select branch"
                style={{ width: "100%" }}
                onChange={(v) => loadCounsellers(v)}
              >
                {branches.map((branch) => (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {auth.user.user_type !== "counselor" && (
            <Form.Item
              name="counselor_id"
              label="Counsellor"
              rules={[
                {
                  required: true,
                  message: "Please select counsellor",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Please select counsellor"
              >
                {counsellers.map((counsellor) => (
                  <Select.Option value={counsellor.id}>
                    {counsellor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </>
  );
}
