import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import LayoutContainer from "../../../components/layout/LayoutContainer";
import { useEffect, useState } from "react";
import useID from "../../../hooks/useID.hook";
import { getBranches } from "../../../api/Branch";
import { createDivision, getDivision } from "../../../api/Division";
import { MdDelete, MdEdit, MdRemoveRedEye } from "react-icons/md";
import ColTitle from "../../../components/Table/ColTitle.component";
import ColValue from "../../../components/Table/ColValue.component";

const { Title, Text } = Typography;

export default function InvoicesPage() {
  const id = useID();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [loadingDivisions, setLoadingDivisions] = useState(false);
  const [divisions, setDivisions] = useState([]);

  const loadBranches = () => {
    getBranches({ pagination: false })
      .then((res) => setBranches(res.data.data))
      .catch((err) => console.log(err));
  };

  const loadDivisions = (branchId) => {
    setLoadingDivisions(true);
    getDivision({ pagination: false, branch_id: branchId ?? id.branch_id })
      .then((res) => setDivisions(res.data.data))
      .catch((err) => console.log(err))
      .finally(() => setLoadingDivisions(false));
  };

  const handleAddDivision = (values) => {
    createDivision(values)
      .then((res) => {
        loadDivisions();
        setOpen(false);
        form.resetFields();
      })
      .catch((err) => message.error("Failed to add division"));
  };

  const columns = [
    {
      key: "id",
      title: <ColTitle>ID</ColTitle>,
      render: (_) => {
        return <ColValue>{_?.id}</ColValue>;
      },
    },
    {
      key: "name",
      title: <ColTitle>Name</ColTitle>,
      render: (_) => {
        return <ColValue>{_?.name}</ColValue>;
      },
    },
    // {
    //   key: "action",
    //   title: <ColTitle>Action</ColTitle>,
    //   render: (_, record) => {
    //     return (
    //       <Space size="small">
    //         <Tooltip title="View">
    //           <Button
    //             // onClick={() => navigate(`/leads/${_?.enquiry_id}`)}
    //             icon={<MdRemoveRedEye />}
    //           />
    //         </Tooltip>

    //         <Tooltip title="Edit">
    //           <Button
    //             // onClick={() => navigate(`/leads/edit/${_?.enquiry_id}`)}
    //             icon={<MdEdit />}
    //           />
    //         </Tooltip>
    //       </Space>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    if (id.branch_id !== "all") {
      form.setFieldValue("branch_id", id.branch_id);
    } else {
      loadBranches();
      form.setFieldValue("branch_id", null);
    }
  }, [id.branch_id]);

  useEffect(() => {
    loadDivisions(id.branch_id);
  }, [id.branch_id]);

  return (
    <LayoutContainer>
      <Row align="middle" justify="space-between" style={{ marginBottom: 19 }}>
        <Title style={{ marginTop: 0, marginBottom: 0 }}>Divisions</Title>

        <Button type="primary" onClick={() => setOpen(true)}>
          Add Division
        </Button>
      </Row>

      {id.branch_id === "all" && (
        <Card>
          <Form form={form} layout="vertical">
            <Form.Item
              name="branch_id"
              label="Branch"
              rules={[
                {
                  required: true,
                  message: "Please select branch",
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  form.setFieldValue("branch_id", value);
                  loadDivisions(value);
                }}
                placeholder="Please select branch"
              >
                {branches.map((branch) => (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Card>
      )}

      <Table
        loading={loadingDivisions}
        columns={columns}
        dataSource={divisions}
        scroll={{ x: true }}
        // pagination={{
        //   position: ["bottomCenter"],
        //   pageSize: data?.pagination?.per_page,
        //   current: data?.pagination?.current_page,
        //   defaultCurrent: data?.pagination?.current_page,
        //   total: data?.pagination?.total,
        //   onChange: (page, pageSize) => {
        //     setPage(page);
        //     loadLeads({
        //       ...Object.fromEntries([...searchParams]),
        //       pagination: true,
        //       page,
        //     });
        //   },
        // }}
      />
      {/* <Card></Card> */}

      <Modal
        open={open}
        okText="Save"
        title="Add Division"
        onOk={() => form.submit()}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddDivision}>
          <Form.Item
            label="Branch"
            name="branch_id"
            initialValue={id.branch_id === "all" ? null : id.branch_id}
            rules={[
              {
                required: true,
                message: "Please select branch",
              },
            ]}
          >
            <Select placeholder="Please select branch">
              {branches.map((branch) => (
                <Select.Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Please enter your name" />
          </Form.Item>
        </Form>
      </Modal>
    </LayoutContainer>
  );
}
